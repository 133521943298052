<template>
    <div class="one-FooterHeight" :style="{backgroundColor: bgColor }">
        <div v-if="item">
            <div class="md-layout">
                <div class="md-layout-item md-layout md-alignment-center-left">
                    <img @click="navigateToHome" class="one-FooterHeight logo" v-if="item && item.base64LogoHeader"
                    :src="item.base64LogoHeader" />
                    <div class="md-layout md-mt-4">
                        <div class="md-layout-item md-layout md-size-100"><span @click="navigateToHome" class="md-title link">{{item.name}}&nbsp;{{item.phone | phone}}</span></div>
                        <div class="md-layout-item md-layout md-size-100 "><span class="md-caption">Abstracxion Copyright {{version}} © Tous droits réservés</span></div>
                    </div>
                </div>
                <div class="md-layout-item md-layout md-alignment-center-right">
                    <span class="link md-mr-5" @click="navigateToHome">Accueil</span>
                    <span v-if="!isPolicy" class="link md-mr-5" @click="navigateToPolicy">Politique de confidentialité</span>
                    <span v-if="!isPolicy" class="link md-mr-5" @click="navigateToCookie">Mes témoins</span> <!-- class="txt-r" -->
                </div>
            </div>
        </div>
    </div>
</template>
<style>
</style>
<style scoped>

    .one-FooterHeight { height: 80px; }
    .one-FooterHeight img.logo { padding: 10px; cursor: pointer; }
    .link { cursor: pointer; }
    .link:hover { text-decoration: underline;}
</style>
<script>

    export default {
        name: 'onePager-footer',
        mixins: [],
        props: {
            item: Object
        },
        data: () => ({
            version : '2024.03.21', 
        }),
        computed: { 
            isPolicy: function() { 
                let p = this.$router.history.current.name == 'onePagerPolicy';
                return p;
            },
            bgColor: function () {
                let color = '#ffffff';
                try {
                    if(this.item && this.item.onePager && this.item.onePager.bgColor !== '') {
                        color = this.item.onePager.bgColor;
                    }
                } catch (error) { color = '#ffffff'; }
                
                return color;
            }
        },        
        mounted: async function () {

        },
        methods: {
            navigateToHome: function () {
                if (this.$router.history.current.name != 'home') {
                    this.$router.push({ name: 'home' });
                }
            },
            navigateToPolicy: function () {
                this.$emit('navigateToPolicy');
            },
            navigateToCookie: function () {
                this.$emit('navigateToCookie');
            }
        }
    }    
</script>