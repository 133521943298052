<template>
    <md-content>
      <cHeader />
      <div class="default-pad">
          <div class="md-layout mt-30">
              <div class="md-layout-item md-layout">

                  <div class="md-layout-item md-size-100">
                      <h1>{{ $t("shipping.title") }}</h1>
                  </div>
                  <div class="md-layout-item md-layout md-size-100">
                      <h2 class="md-pl-3 md-pt-2">{{ $t("shipping.slogan") }}</h2>
                  </div>

                  <div v-if="order" class="md-layout-item md-layout md-size-75 md-small-size-100 md-pt-5">

                      <!--Adress Billing col-->
                      <div class="md-layout-item md-layout md-size-50 md-small-size-100 pr-20">
                          <div class="md-layout-item md-layout md-size-100 md-alignment-top-left">
                              <h2 class="gridTitleStyle">{{ $t("shipping.address") }}</h2>
                          </div>

                          <!--Buttons Invoice / Shipping / Pickup-->
                          <div class="md-layout-item md-layout md-size-100" v-if="order.shippingAddress">
                              <div class="md-layout-item md-layout md-size-100 md-alignment-center-right" style="margin-top: 10px;" >
                                  <md-button @click="setAddress('Invoice')" :class="{ 'v-primary-light-4': order.shippingAddress.typeId == 1 }" class="md-layout-item btnShipping md-small-size-100">
                                      <span class="btnShippingText"><md-icon class="f-primary3">home</md-icon>{{ $t("deliveryBtn.sameAdress") }}</span>
                                  </md-button>
                                  <md-button @click="setAddress('Shipping')" :class="{ 'v-primary-light-4': order.shippingAddress.typeId == 2 }" class="md-layout-item btnShipping md-small-size-100">
                                      <span class="btnShippingText"><md-icon class="f-primary3">place</md-icon>{{ $t("deliveryBtn.otherAdress") }}</span>
                                  </md-button>
                                  <!--
                                      <md-button @click="setAddress('Pickup')" :class="{'v-primary-light-4': order.shippingAddress.typeId == 3}" class="md-layout-item btnShipping md-small-size-100"><span class="btnShippingText"><md-icon class="f-primary3">domain</md-icon>{{ $t("deliveryBtn.pickUp") }}</span></md-button>
                                  -->
                              </div>

                              <div class="md-layout-item md-layout md-size-100 md-alignment-center-right">
                                  <span class="errorText" v-if="error.shippingDistance == true" style="margin-top:5px; margin-bottom:10px;">{{ $t("shippingDistanceError") }}</span>
                              </div>
                          </div>
                          <div class="md-layout-item md-layout md-size-100" v-if="order.shippingAddress && order.shippingAddress.typeId == 3">
                              <md-field style="background-color: rgba(var(--primary),0.1);">
                                  <label for="Destination">Destination</label>
                                  <md-select v-model="homeAddress" name="Destination" id="Destination">
                                      <md-option value="Drummondville">Drummondville</md-option>
                                  </md-select>
                              </md-field>
                          </div>

                          <!--Invoice Btn-->
                          <div v-if="order.shippingAddress" class="md-layout-item md-layout md-size-100">
                              <div class="md-layout-item md-layout md-size-100" :class="{ 'gray': !(order.shippingAddress.typeId == 2) }" style="margin-top: 10px;">
                                  <md-field style="background-color: rgba(var(--primary),0.1);" :class="{ 'bggray': !(order.shippingAddress.typeId == 2) }">
                                      <label :class="{ 'error': error.street == true }">{{ $t("shipping.street")}}</label>
                                      <md-input :class="{ 'gray': !(order.shippingAddress.typeId == 2) }" @change="changeAddress('street')" v-model="order.shippingAddress.street" :readonly="!(order.shippingAddress.typeId == 2)" required></md-input>
                                  </md-field>
                                  <span class="error" style="margin-top: -20px !important;" v-if="this.error.street == true && order.shippingAddress.typeId == 2">{{ $t("shipping.streetWarning") }}</span>
                              </div>
                              <div class="md-layout-item md-layout md-size-100" :class="{ 'gray': !(order.shippingAddress.typeId == 2) }">
                                  <md-field style="background-color: rgba(var(--primary),0.1);" :class="{ 'bggray': !(order.shippingAddress.typeId == 2) }">
                                      <label :class="{ 'error': error.city == true }">{{ $t("shipping.city") }}</label>
                                      <md-input :class="{ 'gray': !(order.shippingAddress.typeId == 2) }" @change="error.city = false" v-model="order.shippingAddress.city.name" :readonly="!(order.shippingAddress.typeId == 2)" required></md-input>
                                  </md-field>
                                  <span class="error" style="margin-top: -20px !important;" v-if="this.error.city == true && order.shippingAddress.typeId == 2">{{ $t("shipping.cityWarning") }}</span>
                              </div>
                              <div class="md-layout-item md-layout md-size-100" :class="{ 'gray': !(order.shippingAddress.typeId == 2) }">
                                  <field-state ax-id='orderShippingState' v-if="order.shippingAddress.city" title="Province *" v-bind:stateId.sync="order.shippingAddress.city.stateId" @md-changed="onStateChanged" :class="{ 'gray': !(order.shippingAddress.typeId == 2) }" :readonly="!(order.shippingAddress.typeId == 2)" />
                                  <!--  :readonly="false" @update etc...-->
                              </div>
                              <div class="md-layout-item md-layout md-size-100" :class="{ 'gray': !(order.shippingAddress.typeId == 2) }">
                                  <md-field style="background-color: rgba(var(--primary),0.1);" :class="{ 'bggray': !(order.shippingAddress.typeId == 2) }">
                                      <label :class="{ 'error': error.zipCode == true }">{{ $t("shipping.zipCode")}}</label>
                                      <md-input :class="{ 'gray': !(order.shippingAddress.typeId == 2) }" @change="error.zipCode = false" v-model="order.shippingAddress.zipCode" :readonly="!(order.shippingAddress.typeId == 2)" required></md-input>
                                  </md-field>
                                  <span class="error" style="margin-top: -20px !important;" v-if="this.error.zipCode == true && order.shippingAddress.typeId == 2">{{ $t("shipping.zipCodeWarning") }}</span>
                              </div>
                              <div class="md-layout-item md-layout md-size-100"
                                  v-if="this.order.shippingAddress.typeId != 3" style="height:76px;">
                              </div>
                          </div>

                      </div>

                  </div>

                  <!--Summary Step-->
                  <div v-if="order" class="md-layout-item md-layout">
                      <Summary step="orderShipping" v-bind:item.sync="order" v-bind:dirty.sync="isDirty" @eCommerceNext="Updated" @eCommerceBack="onBack" />
                  </div>

              </div>
          </div>
      </div>
      <cFooter />
    </md-content>
</template>

<i18n src="./i18n/order.json"></i18n>
<i18n>
    {
      "en": {
        "shippingDistanceError": "The requested distance is too great, we do not exceed 55 kilometers **"
      },
      "fr": {
        "shippingDistanceError": "La distance demandées est trop grande, nous ne dépassons pas 55 kilomètre **"
      }
    }
</i18n>

<style src="../../../../assets/styles/eCommerce-root.css"></style>
<style scoped src="../../../../assets/styles/eCommerce-main.css"></style>
<style scoped src="../../../../assets/styles/eCommerce-theme.css"></style>
<style>
    div[ax-id='orderShippingState'] .md-field { background-color: rgba(var(--primary),0.1); }
    div[ax-id='orderShippingState'].gray .md-field {background-color: rgba(128,128,128,0.1);;}
</style>
<style scoped>
    .btnShipping {width:100% ;padding: 4px !important; margin: 5 !important; border: 1px solid rgba(var(--secondary),0.1); border-radius: 4px; box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.2);}
    .btnShipping:hover {box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.2);}
    .btnShippingText {font-weight: lighter; color: rgb(var(--primary3)); padding: 7px 20px 7px 20px; text-transform: uppercase;}
    .bggray {background-color: rgba(var(--secondary),0.1) !important;}
    .gray {color: gray !important; -webkit-text-fill-color: gray !important;}
    .ItemsImg {height:100%; border-radius: 50%;}
    .ItemsImgBox {height: 110px;}
    .ItemsBox {position:relative; color: rgba(var(--primary));  height: auto; background-color: rgb(var(--tierce)); box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25); margin: 10px 20px 15px 0px !important; padding: 10px !important;}
    .orderBtn{ border-radius: 4px; box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.1);}
    .default-pad {margin: 0px 0px 0px 20px !important; min-height:650px !important;}

    .gridTitleStyle {text-align:left; margin-top:8px !important;}
  
    @media handheld, only screen and (max-width: 600px) {}
    @media handheld, only screen and (max-width: 960px) {}
    @media handheld, only screen and (max-width: 1264px) {}
    @media handheld, only screen and (max-width: 1350px) {
        .btnShippingText {font-size: 0.75em;}
      }
</style>

<script>
import { navigateRouteExtension } from '@/mixins/navigateRouteExtension.js';
import { apiECommerceExtension } from '@/mixins/apiECommerceExtension';

import cHeader from '../components/content-header.vue';
import cFooter from '../components/content-footer.vue';

import Summary from '../components/infoPayment.vue'
import field_state from '../components/fields/field-state.vue';

export default {
    name: 'Order_shipping', 
    mixins: [navigateRouteExtension, apiECommerceExtension],
  props: {
    //summary: Object,
  },
  data: () => ({
    tenant: null,
    order:null,
    homeAddress: 'Drummondville',
    otherShippingAdresse: false,
    livraison: 'Invoice', //Invoice, Shipping, Pickup
    DefaultAddress: {},
    validationObject:[],
    ShippingDate:{min:""},
    disabledDates: date => {
      const day = date.getDay()

      return day === 1 || day === 0
    },
    //image: 'https://abstracxionstorage.blob.core.windows.net/wx3/products/NoImage-AX.jpg',
    isDirty: false,
    error:{
      name: false,
      phone: false,
      deleveryOn: false,
      deleveryMinDate: false,
      shippingDistance: false,
      street: false,
      city: false,
      zipCode: false,
      comment: false,
    },
  }),
  watch: {
    livraison: function() {
    // Fonction appelée lorsqu'une modification est détectée
    if(this.isDirty == false){this.isDirty = true;}
    },
    homeAddress: function(){
      if(this.order && this.order.shippingAddress && this.order.shippingAddress.typeId == 3) {
          if(this.homeAddress == 'Drummondville'){
            this.order.shippingAddress.street = '250 rue St-Louis';
            this.order.shippingAddress.city.name = 'Drummondville';
            this.order.shippingAddress.city.state.name = 'Québec';
            this.order.shippingAddress.city.state.country.name = 'Canada';
            this.order.shippingAddress.zipCode = 'J2B 5W8';
            this.order.shippingAddress.typeId = 3;
            this.livraison == 'Pickup';
          }
        }
    },
  },
  mounted: async function () {

    //if(!$App.onlineStore) { this.$router.replace('/'); }

    this.tenant = this.$route.params.tenant;
    let token = await $App.getAnonymousToken(this.tenant);

    await this.dataRead();

  },
  methods:{
    dataRead: async function () {

      this.order = await this.GetOrder();

      let DA = await this.GetDefaultAddress();
      this.DefaultAddress = JSON.parse(JSON.stringify(DA));

      if (this.order && this.order.shippingAddress != null) {

        if (this.order.shippingAddress.typeId == 2) { this.setAddress('Shipping'); }
        else if (this.order.shippingAddress.typeId == 3) { this.setAddress('Pickup'); }
        else { this.setAddress('Invoice'); }

      } else { this.setAddress('Invoice'); }

      this.setDate();
      this.validation();

    },    
    validation: async function(){
      let valid = true;

      this.order.deliveryOn = new Date(); // Force delivery today
      let order = await this.GetOrder();
      let validation = order.validations;

      if(this.order.shippingAddress.city.stateId == 0){ valid = false; }

      let errorFlag = {name: false, phone: false, email: false, street: false, city: false, zipCode: false, comment: false, shippingDistance: false, };

      if(validation){
        
        validation.forEach(e => {
          if(e.severity == 1){
            if(e.field == "DeliveryOn") {
              if(e.code == "Valid"){
                let myDate = new Date(e.message);
                let offsetMinutes = myDate.getTimezoneOffset();
                this.ShippingDate.min = new Date(myDate.setMinutes(myDate.getMinutes() + offsetMinutes ));
              }
            }
          }
          if(e.severity == 3){
            if(e.field == "Recipient.Name") {

            // RL need recipient to be validate...
            if(this.order && this.order.account) {
                this.order.recipient.name = this.order.account.name;
            } else { valid = false; errorFlag.name = true; }
               
            }
            if(e.field == "Recipient.Phone.Number") { 
            
              // RL need recipient to be validate...
            if(this.order && this.order.account) {
                this.order.recipient.phone.number = this.order.account.phone.number;
            } else { valid = false; errorFlag.phone = true; }
            
            }
            if(e.field == "DeliveryOn") {
              valid = false;
              errorFlag.deleveryOn = true;
              if(e.code == "Underflow"){
                errorFlag.deleveryMinDate = true;
                let myDate = new Date(e.message);
                let offsetMinutes = myDate.getTimezoneOffset();
                this.ShippingDate.min = new Date(myDate.setMinutes(myDate.getMinutes() + offsetMinutes ));
              }
            }
            if(e.field == "Address.Street") { valid = false; errorFlag.street = true; }
            if(e.field == "Address.ZipCode") { valid = false; errorFlag.zipCode = true; }
            if(e.field == "Address.City.Name") { valid = false; errorFlag.city = true; }

          }

        });
      }

      if(valid == false){this.error = errorFlag;}

      return valid;
    },
    Updated: async function() {

      if(this.order.deliveryOn){this.order.deliveryOn = new Date(this.order.deliveryOn);}
      let valid = await this.validation();
      if(valid == true){this.onNext();}
      
    },
    onNext: function () {
        this.navigateTo({ 'name': 'ecommerceOrderSummary', 'params': {'tenant': this.tenant } })
    },
    setAddress: function(s) {
      if (this.order) {
        if (!this.order.shippingAddress) { this.order.shippingAddress = JSON.parse(JSON.stringify(this.DefaultAddress)); }
        if (s == 'Invoice') {
          if (this.order.shippingAddress.typeId != 1) { this.isDirty = true; }
          this.order.shippingAddress = JSON.parse(JSON.stringify(this.order.account.address));
          if (this.order.shippingAddress.street) { this.error.street = false; }
          if (this.order.shippingAddress.city.name) { this.error.city = false; }
          if (this.order.shippingAddress.zipCode) { this.error.zipCode = false; }
          this.livraison == 'Invoice'
        }
        else if (s == 'Pickup') {
          if (this.order.shippingAddress.typeId != 3) { this.isDirty = true; }
          this.order.shippingAddress = JSON.parse(JSON.stringify(this.DefaultAddress));
          this.order.shippingAddress.street = '250 rue St-Louis';
          this.order.shippingAddress.city.name = 'Drummondville';
          // this.order.shippingAddress.city.state = this.DefaultAddress.state;
          // this.order.shippingAddress.city.stateId = this.DefaultAddress.stateId;
          this.order.shippingAddress.zipCode = 'J2B 5W8';
          this.order.shippingAddress.typeId = 3;
          this.livraison == 'Pickup';
          this.error.street = false;
          this.error.city = false;
          this.error.zipCode = false;
        }
        else {
          if (this.order.shippingAddress) {

            if (this.order.shippingAddress.typeId == 1) {
              this.isDirty = true;
              this.order.shippingAddress = JSON.parse(JSON.stringify(this.order.account.address));
            } else if (this.order.shippingAddress.typeId == 3) {
              this.isDirty = true;
              this.order.shippingAddress = JSON.parse(JSON.stringify(this.DefaultAddress));
              // this.order.shippingAddress.street = '';
              // this.order.shippingAddress.city.name = '';
              // // this.order.shippingAddress.city.state.name = 'Québec';
              // // this.order.shippingAddress.city.state.country.name = 'Canada';
              // this.order.shippingAddress.zipCode = '';
              this.error.street = true;
              this.error.city = true;
              this.error.zipCode = true;
            }
          } else {
            this.isDirty = true;
            this.order.shippingAddress = JSON.parse(JSON.stringify(this.DefaultAddress));
            // this.order.shippingAddress.street = '';
            // this.order.shippingAddress.city.name = '';
            // // this.order.shippingAddress.city.state.name = 'Québec';
            // // this.order.shippingAddress.city.state.country.name = 'Canada';
            // this.order.shippingAddress.zipCode = '';
            this.error.street = true;
            this.error.city = true;
            this.error.zipCode = true;
          }
          this.order.shippingAddress.typeId = 2;
          this.livraison == 'Shipping'

        }
      }
    },
    setDate: function(){
      let myDate = new Date();
      let offsetMinutes = myDate.getTimezoneOffset(); 
      if(this.order.deliveryOn) { 
        let orderDate = new Date(this.order.deliveryOn);
        let myDateHours = myDate.getHours() + 1;
        this.order.deliveryOn = new Date(orderDate.setHours(myDateHours)); 
      } 
      else{ 
        this.order.deliveryOn = new Date(myDate.setDate(myDate.getDate()));
        this.order.deliveryOn = this.order.deliveryOn | YYYYMMDD;
      
      };
      this.ShippingDate.min = new Date(myDate.setDate(myDate.getDate()));
    },
    dateSelect: function() {
      for (var i = 0; i < day.getDate(); i--) {

      }
    },

    onBack: function(){
        this.navigateTo({ 'name': 'ecommerceOrderBilling', 'params': {'tenant': this.tenant } })
    },

    changeAddress: function(sId){
      this.dirty = true;
      switch (sId) {
        case 'street':
            this.error.street = false;
          break;
        case 'city':
          this.error.city = false;
          break;
        case 'zipCode':
          this.error.zipCode = false;
          break;
      
        default:
          break;
      }
    },
    onStateChanged: function (state) {
      if (this.order && this.order.shippingAddress && this.order.shippingAddress.city) {
        this.order.shippingAddress.city.state = state;
        if (state) {
          this.order.shippingAddress.city.stateId = state.id;
        }
        else { this.order.shippingAddress.city.stateId = 0; }
      }
      this.isDirty = true;
    },
  
  },
  components: {
    "cHeader": cHeader,
    "cFooter": cFooter,
    'Summary': Summary,
    'field-state': field_state,

  }
}

</script>