<template>
    <div>
        <md-autocomplete v-model="autocompleteSelected" :md-options="autocompleteStatesDataSource" :disabled="isReadOnly"  @md-changed="onChanged" @md-selected="onSelected">
            <label>{{title}}</label>
            <!-- :md-open-on-focus="false" -->
        </md-autocomplete>
    </div>
</template>
<style scoped>
</style>
<script>
    import { apiExtension } from '@/mixins/apiExtension.js';
    import { autocompleteExtension } from '@/mixins/autocompleteExtension.js'

    export default {
        name: 'field-state',
        mixins: [apiExtension, autocompleteExtension],
        props: {
            title: String,
            stateId: Number,
            readonly: Boolean            
        },
        data: () => ({
            tenant: null,
            dataSource: null,
            autocompleteStatesDataSource: [],
            autocompleteSelected: null
        }),
        watch: {
            stateId: {
                handler(e) {
                    //this.acSetValue();
                }
            }
        },
        computed: {
            isReadOnly: function () {
                return this.readonly;
            }
        },        
        mounted: async function () {

            this.tenant = this.$route.params.tenant;

            await this.dataSourceRead();

            // Set autocomplete
            // Attention ne gere pas les accents (les enleve)
            if (this.autocompleteStatesDataSource) {

                this.acSetValue();
            }
        },
        methods: {
            dataSourceRead: async function () {
                //let api = $App.url.api + "States" + "?$Expand=country";
                let api = "States" + "?$Expand=country";

                let data = await this.httpApiTenantGet(this.tenant, api);
                if (data) {
                    this.dataSource = data;
                    this.autocompleteStatesDataSource = this.autocompleteDataSource(data, function (ref) { return ref.name + ', ' + ref.country.abbreviation; });
                }
            },
            acSetValue: function() {
                try {
                    if(this.autocompleteStatesDataSource && this.stateId != null ) {
                    let a = this.autocompleteStatesDataSource.find(e => e.ref.id == this.stateId);
                    if (a != null) { 
                        this.autocompleteSelected = a; 
                    }
                }
                } catch { }
            },
            onChanged: function(e){
                if(e ==="") {
                    //console.log("onChanged");
                    this.$emit('update:stateId', 0);
                    this.$emit('md-changed', null);
                }
            },
            onSelected: function (item) {
                //console.log("onSelected");
                let state = item.ref;
                this.$emit('update:stateId', state.id);
                this.$emit('md-changed', state);
            },
        }
    }
</script>