<template>
    <!-- Header-->
    <header class="header md-layout grid-pad">
      <div class="md-layout-item md-layout md-alignment-center-left">
        <div class="md-layout-item md-layout md-size-100">
          <a class="md-layout-item md-size-100" href="/"><img src="resources/images/LogoAbstracxion.png" class="menuLogo"></a>
          <div class="md-layout-item md-layout"  style="max-width: 105px;"><a class="md-layout-item phoneMenu" style="" href="tel:8198175384">819 818-7625</a></div> 
        </div>
      </div>
      <div class="md-layout-item md-layout  md-alignment-center Title">
        <h3 class="menuTitle"><span class="f-secondary">A</span>bstrac<span class="f-primary">x</span>ion</h3>
      </div>
      <div class="md-layout-item md-layout md-alignment-center-right">
        <div id="app"><md-button @click="sendForm" class="f-primary menuLink" >Commencer</md-button></div>
      </div>
    </header>
</template>
<style>
 
</style>
<style scoped>
    .grid-pad {padding: 0px 40px 0px 40px;}
    .header {overflow: hidden; font-family: serif; position:fixed; top: 0px; height: 100px;  width: 100%; box-shadow: 0px 0px 22px 9px rgb(var(--primary)); z-index: 1000; text-transform: uppercase; background-color: white;}
    .menuLogo {height: 75px !important; max-width: 200px;}
    .menuTitle {font-size: clamp(2.5em, 2vw, 3em); font-weight: 700;}
    .menuLink {font-size: clamp(1.5em, 2vw, 2em);}
    .phoneMenu { text-align: center; font-size: clamp(1em,2vw,1.25em); color:rgb(var(--primary)) !important; }
    .phoneMenu:hover{ color:rgb(var(--secondary))!important; text-decoration: none; } 
    @media handheld, only screen and (max-width: 600px) {
      .menuLogo {height: 50px !important; }
      .phoneMenu { text-align: left; }
    }
    @media handheld, only screen and (max-width: 960px) {
      .Title { display: none;}
    }
    @media handheld, only screen and (max-width: 1279px) {}
    @media handheld, only screen and (max-width: 1378px) {}


</style>
<script>


    export default {
        name: 'website-header',
        mixins: [],
        props: {},
        data: () => ({}),
        mounted: async function () {},
        computed: {},        
        methods: {
          sendForm() { this.$emit('sendFormHeader'); }
        }
    }    
</script>