<template>
    <div>
        <router-view />
    </div>
</template>
<style scoped>

</style>
<script>
  
    export default {
        name: 'www',
        mixins: [],
        data: () => ({
            name:"Bienvenue www"
        }),
        mounted: async function () {

            //await this.dataRead();
  
        },
        methods: {
            dataRead: async function () {
            }
        },
        components: {
        }
    }
  </script>