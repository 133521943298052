//
import Vue from 'vue';

export default {
    registerAll: function () {
        Vue.directive('format',
            {
                twoWay: true,
                bind: function (el, binding, vnode) {
                    //console.log('v-format_bind:');
                    if(binding.modifiers.phone || binding.modifiers.date ){

                        var handler = function (e) {
                            //console.log('v-format_input:');
    
                            let val = binding.def.getValue(binding, e.target.value);
                            let contextVal = binding.def.getContextValue(binding, vnode);

                            if (!binding.def.areEqual(val,contextVal)) {
                                binding.def.setContextValue(binding, vnode, val);
                            }
    
                            let input = binding.def.formatValue(binding, e.target.value);

                            if (!binding.def.areEqual(e.target.value, input)) {
                                e.target.value = input;
                                binding.def.getInput(el).dispatchEvent(new Event("input", { bubbles: true }));
                            }
                            
                        };
                        binding.input_handler = handler;
                        binding.def.getInput(el).addEventListener('input', binding.input_handler);
    
                     } 

                },
                update: function (el, binding, vnode) {
                    //console.log('v-format_update:');

                    if (binding.value != binding.oldValue) {

                        el = binding.def.getInput(el);
                        let input = binding.def.formatValue(binding, binding.value);
                       
                        if (!binding.def.areEqual(el.value, input)) {
                            el.value = input;
                            el.dispatchEvent(new Event("input", { bubbles: true }));
                        } else { 

                            let val = binding.def.getValue(binding, binding.value);
                            let contextVal = binding.def.getContextValue(binding, vnode);

                            if (!binding.def.areEqual(val,contextVal)) {
                                binding.def.setContextValue(binding, vnode, val);
                            }                            
                        }
                    }

                },
                unbind: function (el, binding, vnode) {
                    //console.log('v-format_update_unbind:');
                    el = binding.def.getInput(el);
                    el.removeEventListener('input', binding.input_handler)
                },
                formatValue: function (binding, value) {
                    let result = null;
                    if (binding.modifiers) {
                        if (binding.modifiers.phone) {
                            result = binding.def.formatPhone(value);
                        } else if (binding.modifiers.date) { 
                            result = binding.def.formatDate(value);
                        }
                        else {
                            if (value) {
                                result = value.toString();
                            }
                        }
                    }
                    return result;
                },
                formatPhone: function (value) {
                    let input = '';
                    if (value) {

                        input = value.toString().replace(/[^0-9]+/g, '');
                        if (input.length > 0) {

                            if (input.length > 10) {
                                input = input.substr(0, 10);
                            }

                            if (input.length > 9) {
                                input = input.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');

                            } else if (input.length > 6) {
                                input = input.replace(/(\d{3})(\d{3})/, '$1-$2-');

                            } else if (input.length > 3) {
                                input = input.replace(/(\d{3})/, '$1-');
                            }
                        } else { input = ''; }
                    }

                    return input;
                },
                formatDate: function (value) {
                    let input = "";
                    if (value) {
                        try {
                            input = new Date(value).toISOString().slice(0, 10);
                        } catch (error) {
                            console.log(error);
                            input = value;
                        }
                    }
                    return input;
                },
                getValue: function (binding, value) {
                    
                    let result = null;
                    if (binding.modifiers) {
                        if (binding.modifiers.phone) {
                            result = binding.def.getPhone(value);
                        } else if (binding.modifiers.date) { 
                            result = binding.def.getDate(value);
                        }
                        else {
                            if (value) {
                                result = value.toString();
                            }
                        }
                    }
                    return result;
                },
                getPhone: function (value) {
                    let result = 0;
                    if (value) {

                        let input = value.toString().replace(/[^0-9]+/g, '');
                        if (input.length > 0) {

                            if (input.length > 10) {
                                input = input.substr(0, 10);
                            }

                            try {
                                result = parseInt(input);
                            } catch (error) {
                                console.log(error);
                                result = 0;
                            }
                        } else { result = 0; }
                    } else { result = 0; }

                    return result;
                },
                getDate: function (value) {
                    let result = null;
                    if (value) {
                        try {
                            
                            if(value instanceof Date){ 
                                result = value.toJSON();
                            } else {
                                let d = new Date(value.toString().slice(0, 10) + 'T12:00:00.00Z');
                                result = d.toJSON();
                            }
                        } catch (error) {
                            console.log(error);
                            result = null;
                        }
                    }
                    return result;
                },
                getContextValue: function (binding, vnode) {
                    if (vnode) {
                        if (binding.expression) {
                            let parts = binding.expression.split('.');
                            if (parts.length > 0) {

                                let vm = vnode.context.$data;
                                if (vm) {
                                    parts.forEach(part => {
                                        vm = vm[part];
                                    });
                                    
                                    return vm;
                                }
                            }
                        }
                    }
                },
                setContextValue: function (binding, vnode, value) {
                    //console.log('v-format_setContext:');

                    if (vnode) {
                        if (binding.expression) {
                            let parts = binding.expression.split('.');
                            if (parts.length > 0) {
                                
                                let vm = vnode.context.$data;
                                if(vm) {
                                    for (let i = 0; i < parts.length - 1; i++) {
                                        vm = vm[parts[i]];
                                    }
                                    vm[parts[parts.length - 1]] = value;
                                }
                            }
                        }
                    }
                },
                areEqual: function(val1, val2){
                    if(val1){
                        if(val2){
                            if(typeof val1 == "number"){
                                return val1 == val2;
                            } else if(val1 instanceof Date ) {
                                return val1.valueOf() == val2.valueOf();
                            } else {
                                return val1.toString() == val2.toString();
                            }
                        }
                        else { return false; }
                    } else { 
                        if(val2){ return false; }
                        else {return true; }
                    }
                },
                getInput: function(e){
                    let el = e;
                    if(e){
                        if(e.nodeName.toLowerCase() != 'input'){
                            el = e.children[1];
                        }
                    }
                    return el;
                }
            });

    }
};



