import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import home from '../views/Home.vue'

import ecommerceProductList from '../views/ecommerce/product/List.vue'
import ecommerceProductItem from '../views/ecommerce/product/Item.vue'

import ecommerceOrder from '../views/ecommerce/order/order.vue'
import ecommerceOrderBilling from '../views/ecommerce/order/order-billing.vue'
import ecommerceOrderShipping from '../views/ecommerce/order/order-shipping.vue'
import ecommerceOrderSummary from '../views/ecommerce/order/order-summary.vue'

import ecommerceInvoice from '../views/ecommerce/invoice.vue'

import onePager from '../views/OnePager.vue'
import onePagerPolicy from '../views/OnePagerPolicy.vue'
import offerStartingBusiness from '../views/OfferstartingBusiness.vue'

const wwwRouter = new VueRouter({
    routes: [
      {
        path: '*',
        name: 'Home',
        component: home
      },
      {
        path: '/:tenant/ecommerce/products',
        name: 'ecommerceProductList',
        component: ecommerceProductList
      },
      {
        path: '/:tenant/ecommerce/products/:id',
        name: 'ecommerceProductItem',
        component: ecommerceProductItem
      },

      {
        path: '/:tenant/ecommerce/order',
        name: 'ecommerceOrder',
        component: ecommerceOrder
      },
      {
        path: '/:tenant/ecommerce/order/billing',
        name: 'ecommerceOrderBilling',
        component: ecommerceOrderBilling
      },
      {
        path: '/:tenant/ecommerce/order/shipping',
        name: 'ecommerceOrderShipping',
        component: ecommerceOrderShipping
      },
      {
        path: '/:tenant/ecommerce/order/summary',
        name: 'ecommerceOrderSummary',
        component: ecommerceOrderSummary
      },


      {
        path: '/:tenant/ecommerce/invoice',
        name: 'ecommerceInvoice',
        component: ecommerceInvoice
      },
      {
        path: '/:tenant/ecommerce/invoices/:id',
        name: 'ecommerceInvoice_ID',
        component: ecommerceInvoice
      },



      {
      path: '/OffreDemarrageEntreprise',
      name: 'offerStartingBusiness',
      component: offerStartingBusiness
    },
    {
      path: '/onePager',
      name: 'onePagerDefault',
      component: onePager
    },
    {
      path: '/:tenant/onePager',
      name: 'onePager',
      component: onePager
    },
    {
      path: '/:tenant',
      name: 'home',
      component: onePager
    },
    {
      path: '/:tenant/Policy',
      name: 'onePagerPolicy',
      component: onePagerPolicy
    },
  ]
  })
  wwwRouter.beforeResolve(async (to, from, next) => {
    // // Check if the user is authenticated.
    // let isUserAuthenticated = await apiRequestCustomFunction();
  
    // // Redirect user to the login page if not authenticated.
    // if (!isUserAuthenticated) {
    //   location.replace("https://example.com/signin");
    //   return false;
    // }
    
    // When next() is called, the router will load the component corresponding
    // to the URL path.
    next();
  });

export default wwwRouter

