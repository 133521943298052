<template>
    <md-content>
        <cHeader />
        <div class="container " style="overflow-x: hidden; min-height:  500px;">

            <div>
              <h1 class="mt-20">{{ $t("title") }}</h1>
              <div class="md-layout md-gutter mt-10">
                <div class="md-layout-item md-layout">
                  <div v-if="this.CategoryBox" class="rel BoxCat md-layout-item md-layout">
                      <div class="closeCategory"  @click="toggleCategoryBox()">X</div>
                      <div>
                        <div class="TitleCatOpen"  v-for="(item, i) in categories" :key="i">
                          <div class="categorie">                  
                                  <h2 class="" style="display: inline-block; " @click="selectCategory(item)">{{item.title}}</h2>
                              </div>
                        </div>
                      </div>
                  </div>
                  <img  style="width: 15px; height: 15px; margin: 2px 5px 0 0 !important;" src="/Resources/Icons/browse.png"/>
                  <h5 @click="toggleCategoryBox()" style="cursor: pointer;">{{ $t("section.categories") }}</h5>
                  <h3 v-if ="categoryId != 0" class="TitleCat" style="margin: 1px 0 0 15px !important;" @click="selectCategory()">x</h3>

                </div>
                <div class="md-layout-item md-size-25 md-layout">
                  <md-field class="md-layout-item">
                      <label>{{ $t("section.search") }}</label>
                      <md-input style="color: rgb(var(--primary)); font-weight: 700; " id="onlineStore_SearchInput" name="onlineStore_SearchInput" type="text" @click:append-inner="onSearch" @keydown.enter="onSearch" v-model="searchItem" clearable></md-input>
                  </md-field>
                </div>
              </div>
            </div>
            
            <div class="md-layout md-alignment-center-center"><span class="CategoryName" v-if="CategoryName">{{ this.CategoryName }}</span></div>
      
            <div class="md-layout md-gutter mt-20 productContainer custom-scrollbar" v-if="Products && Products.length > 0" >
              <div class="md-layout-item md-layout md-alignment-center-center" v-for="(item, i) in Products" :key="i">
                <div class="md-layout-item mb-100 produit">
                  <a class="md-layout md-layout-item" style="position:relative ;height: 100%; max-width: 300px; display: block; margin: auto auto;" @click="navigateTo({ 'name': 'ecommerceProductItem', 'params': {'tenant': tenant, 'id': item.id } })">
                    <div class="overlay">
                      <div class="md-size-100" >
                        <h6>{{item.title}}</h6>
                        <!--
                          <span v-if="item.qty > 0"><h5>${{item.price}} <span v-if="item.discount > 0" class="md-discount"> - {{ $t("discount") }} ${{item.discount}}</span></h5></span>
                          <span v-else><h5>{{ $t("section.unavailable") }}</h5></span> 
                        -->
                        <span><h5>${{item.price}} <span v-if="item.discount > 0" class="md-discount"> - {{ $t("discount") }} ${{item.discount}}</span></h5></span>
                      </div>
                    </div>
                    <img v-if="item.image.url" class=" md-size-100 md-alignment-center-center productImg md-mt-3" :src="item.image.url" :truesrc="item.image.url"/>
                    <img v-else class=" md-size-100 md-alignment-center-center productImg md-mt-3" src="https://abstracxionstorage.blob.core.windows.net/wx3/products/NoImage-AX.jpg" />
                  </a>
                </div>
              </div>
            </div>

            <div v-if="Products && Products.length == 0 && loading == false">
              <div class="md-layout md-gutter mt-20">
                <h5 class="" style="padding-left: 50px;">{{ $t("section.noProduct") }}</h5>
              </div>
            </div>
    
            <div v-if="loading == true" >
              <div style="width: 75%; display: block; margin-left: auto !important; margin-right: auto !important;">
                <h2 class="soustitre">{{ $t("section.processing") }}</h2>
                <md-progress-bar md-mode="indeterminate"></md-progress-bar>
              </div>
            </div>

        </div>
        <cFooter />
    </md-content>
</template>
<i18n>
{
  "fr": {
    "title": "Boutique en ligne",
    "processing" : "Chargement...",
    "discount": "rabais",
    "section": {
      "categories": "Catégories",
      "text": "une porte incomparable à un prix exceptionnel !",
      "demo":"Démonstrateur",
      "noProduct":"Auncun produit n'a été trouvé.",
      "processing":"Les produits sont en chargement...",
      "price":"Prix:",
      "search": "Recherche",
      "unavailable" : "non disponible"
    }
  },
  "en": {
    "title": "Online Store",
    "processing" : "Loading...",
    "discount": "discount",
    "section": {
      "categories": "Categories",
      "text": "an incomparable door at an exceptional price!",
      "demo":"Demonstrator",
      "noProduct":"No products were found.",
      "processing":"The products are loading...",
      "price":"Price:",
      "search": "Search",
      "unavailable" : "not available"
    }
  }
}  
</i18n>

<style src="../../../../assets/styles/eCommerce-root.css"></style>
<style scoped src="../../../../assets/styles/eCommerce-main.css"></style>
<style scoped src="../../../../assets/styles/eCommerce-theme.css"></style>
<style scoped>
      .overlay { position:absolute; bottom: 0;width: 100%;height: 30%; }
      .produit .overlay div { position:absolute; bottom: 0;width: 100% }
      .produit:hover .overlay {background-color: rgba(var(--primary), 0.6); }
      /* .produit:hover h6 {color: #FFFFFF;}  */

      .md-discount { color: rgb(212, 44, 44);}
    
        /*internet explorer doesnt take well sticky ; it doesnt fit well with diapo too*/
        div.sticky {
          position: -webkit-sticky; /*Safari*/
          position: sticky;
          top: 0;
        }
      /*Modif soustitre*/
      .soustitre { line-height: 40px; margin-bottom:5px;}
      .produit { height: 350px; width: 300px; position: relative; display: block; margin: auto auto !important; cursor: pointer;}
      a {text-decoration:none;}
      .produit a:hover {text-decoration:none; }

      /* a img:hover {background: linear-gradient(to bottom left, #fff 0%, #fff 50%, #2fa0c2 50%, #2fa0c2 100%); } */
      .produit_available {color: rgb(212, 44, 44);font-weight:bold;text-align:left;font-style:italic;}
      /* .center_container {display:block ; padding-left:auto; padding-right: auto; width:90%;} */
      .marginSpacingModif {margin-left:20px;}
      /* .container {display:block; margin-right: auto !important; margin-left: auto !important; margin-top: 50px !important;} */
      .container {display:block; margin: 50px !important; }
      .productImg {height: 80%; width: auto; margin: auto auto; background-color: white;}
      .itemProduct {position: relative;} 
    
      .productContainer {max-height: 750px; overflow-y: scroll;}
      .productContainer::-webkit-scrollbar { width: 1px !important; color: rgb(var(--primary));}
      /*CSS pour scrollBar*/
          /* Styles pour la barre de défilement - Chrome, Edge et Safari */
      .custom-scrollbar { max-height: auto; overflow-y: scroll; scrollbar-width: thin; scrollbar-color: rgb(var(--primary)) rgba(var(--secondary),0.1); }
      /*category*/
      .TitleCat{color: rgb(var(--primary)); font-weight: 700; font-size: 18px; display: inline-block; cursor: pointer;}
      .TitleCatOpen{color: rgb(var(--primary)); font-weight: 100; font-size: 24px; display: inline-block; cursor: pointer;}
      .TitleCatOpen:hover{color: rgb(var(--secondary));}
      .TitleCat:hover {color: rgb(var(--secondary));}
      .BoxCat{height: auto; width: auto; min-width: 300px; max-width: 70%; position: absolute  ; z-index: 100; background-color: rgba(var(--secondary),0.8); padding: 25px !important;}
      .categorie { min-width: 250px; overflow: hidden; height: 50px; padding: 10px; }
      .categorie h2 {  font-size: 18px; font-weight: 100;}
      .categorie:hover { cursor: pointer; color: white; }
      .CategoryName { color: rgb(var(--primary)); font-family: Ephesis; font-size: 72px; }
      .closeCategory {float: right; color: rgb(var(--primary)); position: absolute; right: 10px; top: 5px; cursor: pointer;}
      .closeCategory:hover {color: #FFFFFF;}
    
      /* Styles pour la barre de défilement - Firefox */
      @supports (scrollbar-color: auto) { .custom-scrollbar { scrollbar-color: rgb(var(--primary))  rgba(var(--secondary),0.1); scrollbar-width: thin; } }
        /* Styles pour la barre de défilement - Edge (Chromium) */
      @supports (-ms-overflow-style: none) { .custom-scrollbar { scrollbar-color: rgb(var(--primary)) rgba(var(--secondary),0.1); scrollbar-width: thin; } }
    
      @media handheld, only screen and (max-width: 600px) {
      }
      @media handheld, only screen and (max-width: 958px) {
        .soustitre {font-size: 24px !important;}
      }
      @media handheld, only screen and (max-width: 1264px) {
        .soustitre {font-size: 32px;}
      }

</style>

<script>

import { navigateRouteExtension } from '@/mixins/navigateRouteExtension.js';
import { apiECommerceExtension } from '@/mixins/apiECommerceExtension.js'

import cHeader from '../components/content-header.vue';
import cFooter from '../components/content-footer.vue';

export default {
  name: 'ecommerceProductList',
  mixins: [navigateRouteExtension, apiECommerceExtension],
  data: () => ({
    tenant: null,
    categoryId: 0,
    categories: null,
    CategoryName: null,
    CategoryBox: false,
    searchItem: "",
    loading: false,
    Products: null
  }),
  mounted: async function () {

    // if(!$App.onlineStore) { this.$router.replace('/'); }

    this.tenant = this.$route.params.tenant;
    let token = await $App.getAnonymousToken(this.tenant);
    //console.log($App.tenant);

    if (this.$route.query.categoryId) {
      this.categoryId = this.$route.query.categoryId;
    };

    await this.dataRead();

  },
  methods: {
    dataRead: async function () {

      if (!this.categories) {
        this.categories = await this.EcommerceCategoriesList();
      }

      this.loading = true;
      let filter = {};
      if (this.categoryId != 0) {
        filter.categoryId = this.categoryId;
      }
      if(this.searchItem) {
        filter.keywords = this.searchItem;
      }

      this.Products = await this.EcommerceProductsListWithFilter(filter, this.range, this.callback);
      this.loading = false;

    },
    selectCategory: async function (param) {

      if (param) {
        this.categoryId = param.id;
        this.CategoryName = param.title;
        this.CategoryBox = false;
      }
      else {
        // clear category
        this.categoryId = 0;
        this.loading = false;
        this.CategoryName = null;
        this.CategoryBox = false;
      }

      await this.dataRead();

    },
    toggleCategoryBox: function () {
      if (!this.CategoryBox) {
        this.CategoryBox = true;
      }
      else {
        this.CategoryBox = false;
      }
    },
    onSearch: async function () {

      await this.dataRead();

    },
    callback: function (response) {
      this.response = response;
      this.range = $App.getRangeHeader(response);
    }

  },
  components: {
    "cHeader": cHeader,
    "cFooter": cFooter,
  }
}
</script>
