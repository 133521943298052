function vue_md_input_Init() {
    $(".md-input").focusin(function () { vue_md_input_setfocused(this) });
    $(".md-input").focusout(function () { vue_md_input_setfocusedout(this) });
    $(".md-input").each(function () { vue_md_input_set_has_value(this) });
}
function vue_md_input_setfocused(element) {
    $(element).closest("div").addClass("md-focused");
}
function vue_md_input_setfocusedout(element) {
    $(element).closest("div").removeClass("md-focused");
    vue_md_input_set_has_value(element);
}
function vue_md_input_set_has_value(element) {
    if ($(element).val()) { $(element).closest("div").addClass("md-has-value"); }
    else { $(element).closest("div").removeClass("md-has-value"); }
}
function isBrowserIE() {
    let ie = false;
    let ua = window.navigator.userAgent;
    let msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older => return version number
        ie = true;
    } else {
        let trident = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number see rv:
            ie = true;
        }
    }
    return ie;
}
function PictureFileOptimizeToDataURL(fileSelected, callback){            
    const fileReader = new FileReader();
    fileReader.readAsDataURL(fileSelected);
    fileReader.onload = File => {  
        var imgOriginal = new Image();
        imgOriginal.onload = function() {
            
            var mime = 'image/jpeg';
            if(imgOriginal && imgOriginal.src && imgOriginal.src.startsWith('data:') ) {
                let i = imgOriginal.src.indexOf(';')
                if(i > 5 ) { mime = imgOriginal.src.substring(5, i ); }
            }

            var canvaCrop = document.createElement('canvas');
            var Ctx2D;
            var deltaX = 0, deltaY = 0;
            // set canva square with minimun size...
            canvaCrop.width = canvaCrop.height = Math.min(imgOriginal.naturalWidth, imgOriginal.naturalHeight);
            // set delta X & Y to center image
            if(imgOriginal.naturalWidth > imgOriginal.naturalHeight) {
                deltaX = -( (imgOriginal.naturalWidth - imgOriginal.naturalHeight) / 2 );
            } else {
                deltaY = -( (imgOriginal.naturalHeight - imgOriginal.naturalWidth) / 2 );
            }
            // position image to Crop...
            Ctx2D = canvaCrop.getContext('2d');
            Ctx2D.drawImage(imgOriginal, deltaX, deltaY);
            var imgDataCrop = canvaCrop.toDataURL(mime,1);
            var imgCrop = new Image();
            imgCrop.onload = function(){
                var canvaScale = document.createElement('canvas');
                // set scale size to minimum between 800 & original size
                canvaScale.width = canvaScale.height = Math.min(800,canvaCrop.width) ;
                // set scale base on diference between croped & scale canvas...
                let scale = canvaScale.width/canvaCrop.width;
                Ctx2D = canvaScale.getContext('2d');
                Ctx2D.scale(scale,scale);
                Ctx2D.drawImage(imgCrop, 0, 0);
                // set picture property of view model object
                let dataURL = canvaScale.toDataURL(mime, 0.2);
                callback(dataURL);
            }
            imgCrop.src = imgDataCrop;
        }
        imgOriginal.src = File.target.result;
    };
}
function ImageFileOptimizeToDataURL(fileSelected, callback){            
    const fileReader = new FileReader();
    fileReader.readAsDataURL(fileSelected);
    fileReader.onload = File => {  
        var imgOriginal = new Image();
        imgOriginal.onload = function() {
            
            var mime = 'image/jpeg';
            if(imgOriginal && imgOriginal.src && imgOriginal.src.startsWith('data:') ) {
                let i = imgOriginal.src.indexOf(';')
                if(i > 5 ) { mime = imgOriginal.src.substring(5, i ); }
            }

            var canvaScale = document.createElement('canvas');

            let scale = 1;
            if(imgOriginal.naturalWidth > 1920) {
                scale = 1920 / imgOriginal.naturalWidth;
            }
            canvaScale.width = imgOriginal.naturalWidth * scale;
            canvaScale.height = imgOriginal.naturalHeight * scale;

            Ctx2D = canvaScale.getContext('2d');
            Ctx2D.scale(scale,scale);
            Ctx2D.drawImage(imgOriginal, 0, 0);
            // set picture property of view model object
            let dataURL = canvaScale.toDataURL(mime, 0.8);
            callback(dataURL);
        }
        imgOriginal.src = File.target.result;
    };
}
window.$App = {
    name: "Abstracxion!",
    tenant: "WX3",
    url: {
        root: "https:./app.html",
        login: "https:./login.html",
        api: "https://abstracxion.wx3data.com/",
        //  root: "http:./app.html",
        //  login: "http:./login.html",
        // api: "https://abstracxion_tst.wx3data.com/",
        //api: "http://localhost:17548/",
    },
    event: null,
    getToken: function () {
        let token = sessionStorage.getItem('user-token');

        var d = this.getTokenExpiration(token);

        if (d < new Date()) {
            sessionStorage.removeItem('user-token');
            sessionStorage.removeItem('autorized');
            window.location = $App.url.login;
        }

        if (token == null || token == '') {
            window.location = $App.url.login;
        } else {

            let tokenTenant = this.getTokenTenant(token);
            if(tokenTenant) { $App.tenant = tokenTenant; }
            else {
                window.location = $App.url.login;
            }

            return token;
        }
    },
    getAnonymousToken: async function (tenant) {

        let token = sessionStorage.getItem('anonymous-token');

        var d = this.getTokenExpiration(token);
        if (d < new Date()) {
            sessionStorage.removeItem('anonymous-token');
            sessionStorage.removeItem('autorized');
        }

        if (token == null || token == '') {
            if (tenant) {
                //$('.loading-overlay').show();

                const api = this.url.api + 'security/login/' + tenant;
                //$App.tenant = this.input.Tenant;

                let response = await fetch(api, {
                    method: 'PUT', // *GET, POST, PUT, DELETE, etc.
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json'
                        // 'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrerPolicy: 'no-referrer', // no-referrer, *client
                    body: JSON.stringify({ LoginName: '', password: '' })
                });

                if (await response.ok) {
                    if (await response.status !== 204) {
                        token = await response.text();;
                    }
                } else {
                    token = null;
                    let msg = await response.text();
                    console.log(msg);
                };

                if (token != null && token != '') {

                    this.tenant = tenant;
                    sessionStorage.setItem('anonymous-token', token);
                    sessionStorage.setItem('autorized', '');

                } else {
                    sessionStorage.removeItem('anonymous-token');
                    sessionStorage.removeItem('autorized');

                    $App.event.$emit("app_error", "Erreur lors de la communication avec le système. Veuillez réessayer ou contacter l'administrateur du système. <br /> Error communicating with the system. Please try again or contact the system administrator. ");
                }
                //$('.loading-overlay').hide();


            }
            else {
                token = null;
            }
        }

        return token;
    },
    isAutorized: function(key){
        try {
                let autorized = sessionStorage.getItem('autorized');
                if (autorized) {
                    var keys = autorized.split(',')
                    var s = keys.find(e => e == key);
                    if (s) { return true; }
                    else { return false; }
                }
                else { return false; }
            } 
            catch (error) {
            console.error(error);
            return false;
          }
    },
    getTokenExpiration: function (token) {
        try {
            decodedToken = this.parseToken(token);
            return new Date(decodedToken.exp * 1000);
        } catch (e) {
            return null;
        }
    },
    getTokenTenant: function (token) {
        try {
            decodedToken = this.parseToken(token);
            return decodedToken.Tenant;
        } catch (e) {
            return null;
        }
    },
    parseToken: function (token) {
        try {
            return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
            return null;
        }
    },

    getRangeHeader: function (response) {
        // see: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Range
        //Content-Range: <unit> <range-start>-<range-end>/<size>
        //Content-Range: <unit> <range-start>-<range-end>/*
        //Range: <unit>=<range-start>-
        //Range: <unit>=<range-start>-<range-end>

        if (response.status != 206) { return null; }

        let range = 'items=0-';
        if (response) {
            let contentRange = response.headers.get('Content-Range');
            if (contentRange) {
                let r = contentRange.split(' ');
                if (r.length > 0) {
                    let r2 = r[1].split('/');
                    if (r2.length > 0) {
                        let r3 = r2[0].split('-');
                        if (r3.length > 0) {
                            let first = parseInt(r3[1]) + 1;
                            let total = parseInt(r2[1]);
                            if (first != total) {
                                range = 'items=' + first + '-';
                            }
                        }
                    }
                }
            }
        }

        return range;
    }
}; 
