import Vue from 'vue'
import App from './www.vue'

import wwwRouter from './router/index.js'

import VueMaterial from 'vue-material'
import Vuelidate from 'vuelidate'
import VueI18n from 'vue-i18n'

import application from '../../public/resources/scripts/app.js'

import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'

import '../../public/resources/css/vue-material-extension.css'
import '../../public/resources/css/app.css'

import directives from '../directives.js'
directives.registerAll();

import filters from '../filters.js'
filters.registerAll();

import $event from '../event.js'

Vue.config.productionTip = false

Vue.use(VueMaterial)
Vue.use(Vuelidate);
Vue.use(VueI18n)


if(!$App.event){
  $App.event = $event;
}

// Ready translated locale messages
const messages = {
  en: {
    message: {
      hello: 'hello world',
      welcome: 'Welcome to Your Vue.js App',
      about: 'This is an about page'
    }
  },
  fr: {
    message: {
      hello: 'Bonjour le monde',
      welcome: 'Bienvenue dans votre application Vue.js',
      about: 'Voici la page a propos'
    }
  }
}

// Create VueI18n instance with options
var culture = localStorage.getItem("Culture");
if(!culture){ culture='fr'; }

const i18n = new VueI18n({
  locale: culture, // set locale
  fallbackLocale : 'fr',
  allowComposition: true,
  messages, // set locale messages
})



const www = new Vue({
  router: wwwRouter,
  i18n,
  application,
  render: function (h) { return h(App) }
});
www.$mount('#app')

window.addEventListener('keydown', function(e) {
  
  if(e.key == "Backspace" && e.ctrlKey && !e.shiftKey) {
    e.preventDefault();
    $App.event.$emit("app_hotkey_ctrlBackspace", e)
  } else if(e.key == "Enter" && e.ctrlKey && !e.shiftKey) {
    e.preventDefault();
    $App.event.$emit("app_hotkey_ctrlEnter", e)
  } else if((e.key == "s" || e.key == "S") && e.ctrlKey && !e.shiftKey) {
    $App.event.$emit("app_hotkey_ctrlS", e)
    e.preventDefault();
  } else if((e.key == "n" || e.key == "N") && e.ctrlKey && e.shiftKey) {
    $App.event.$emit("app_hotkey_ctrlshiftN", e)
    e.preventDefault();
  }
  
})

Date.prototype.toLocaleDaylight = function() {
  
  let daylightOffset = 0;
  if(!Date.prototype.toLocaleDaylight.cache.hasOwnProperty('daylightOffset')) {

    // offset in minutes convert to milliseconds 
    daylightOffset = ( (new Date().getTimezoneOffset() - this.getTimezoneOffset()) * 60000)
    Date.prototype.toLocaleDaylight.cache['daylightOffset'] = daylightOffset;

  } else {

    daylightOffset = Date.prototype.toLocaleDaylight.cache['daylightOffset'];

  }

  if(daylightOffset != 0){
    return new Date(this.getTime() - daylightOffset );
  }
  return this;
}
Date.prototype.toLocaleDaylight.cache = {} ;

