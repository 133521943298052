import { render, staticRenderFns } from "./order-shipping.vue?vue&type=template&id=2b2c10c4&scoped=true"
import script from "./order-shipping.vue?vue&type=script&lang=js"
export * from "./order-shipping.vue?vue&type=script&lang=js"
import style0 from "../../../../assets/styles/eCommerce-root.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "../../../../assets/styles/eCommerce-main.css?vue&type=style&index=1&id=2b2c10c4&prod&scoped=true&lang=css&external"
import style2 from "../../../../assets/styles/eCommerce-theme.css?vue&type=style&index=2&id=2b2c10c4&prod&scoped=true&lang=css&external"
import style3 from "./order-shipping.vue?vue&type=style&index=3&id=2b2c10c4&prod&lang=css"
import style4 from "./order-shipping.vue?vue&type=style&index=4&id=2b2c10c4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b2c10c4",
  null
  
)

/* custom blocks */
import block0 from "./i18n/order.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5CDevelopment%5CVSTS.Git%5CAbstracxion%5CSpec_2.0%5CSolutions%5CUI%5CAbstracxion.Web%5Csrc%5Cwww%5Cviews%5Cecommerce%5Corder%5Corder-shipping.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "./order-shipping.vue?vue&type=custom&index=1&blockType=i18n"
if (typeof block1 === 'function') block1(component)

export default component.exports