<template>
    <div v-if="item" class="md-content">
        <authCookies ref="authCookies" />

        <cHeader v-bind:item.sync="item" />

        <section class="one-Title">
            <span v-if="item" class="md-display-2">{{item.name}}</span>
        </section>
        <div class="one-Picture">
            <img v-if="item.onePager && item.onePager.picture" class=""  :src="item.onePager.picture" />
        </div>
        <div ax-id='onePagerDescription' class="one-Description">
            <p v-if="item.onePager && item.onePager.description" class="md-body-2" v-html="$options.filters.textWithBR(item.onePager.description)"></p>
        </div>
        <div class="one-Adress">
            <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-mb-5">
                    <span class="md-display-1">Addresse</span><br /><br />
                    <div v-if="item">
                        <span v-if="item.address && item.address.street" class="md-body-2">{{item.address.street}}</span><br />
                        <span v-if="item.address && item.address.city && item.address.city.name " class="md-body-2">{{item.address.city.name}}</span><br />
                        <span v-if="item.address && item.address.city && item.address.city.state && item.address.city.state.name " class="md-body-2">{{item.address.city.state.name}}</span>, &nbsp;
                        <span v-if="item.address && item.address.city && item.address.city.state && item.address.city.state.country && item.address.city.state.country.name  " class="md-body-2">{{item.address.city.state.country.name}}</span><br />
                        <span v-if="item.address && item.address.zipCode" class="md-body-2">{{item.address.zipCode}}</span><br /><br />
                        <span v-if="item && item.phone" class="md-body-2">Tel: {{ item.phone | phone }}</span>
                    </div>
                    
                </div>
                <div class="md-layout-item md-small-size-100 md-mb-5">
                    <span class="md-display-1">Heure d'ouverture</span><br /><br />
                    <span v-if="item.onePager && item.onePager.openingTime" class="md-body-2" v-html="$options.filters.textWithBR(item.onePager.openingTime)"></span>
                </div>
            </div>
        </div>
        <div class="one-Map">
            <iframe v-if="item.onePager && item.onePager.map" class="md-image" :src="item.onePager.map" frameborder="0" style="border:0" height="100%" width="100%"></iframe>
        </div>

        <cFooter v-bind:item.sync="item" @navigateToCookie="navigateToCookie" @navigateToPolicy="navigateToPolicy" />

    </div>    
</template>
<style>
      div[ax-id='onePagerDescription'] a {
        color: var(--md-theme-default-text-accent-on-background-variant, rgba(0,0,0,0.6)) !important;
        }
</style>
<style scoped>

    /* section {  margin: 120px 0 auto; text-align: center; } */

    .one-Title {  margin: 120px 0 auto; text-align: center; text-transform: uppercase }
    .one-Picture { margin-top: 100px; margin-left: auto; margin-right: auto; text-align: center; max-width: 80% }
    .one-Picture img { object-fit: contain; }

    .one-Description { margin: 3% 8% 0 8%; 
    }
    .one-Description .md-body-2 {
        color: var(--md-theme-default-text-accent-on-background-variant, rgba(0,0,0,0.6)); line-height: normal;
    }

    .one-Adress { margin: 5% 8% 0 8%; }
    .one-Adress .md-body-2 {
        color: var(--md-theme-default-text-accent-on-background-variant, rgba(0,0,0,0.6));
    }
    .one-Map {margin-top: 50px; margin-bottom: 50px; margin-left: auto; margin-right: auto;  max-width: 80%; height: 400px;
    }

</style>

<script>
    import { apiSystemSettingsExtension } from '@/mixins/apiSystemSettingsExtension.js';
  
    import authorizationCookies from '../components/authorizationCookies.vue'

    import cHeader from '@/www/components/onePager-header.vue'
    import cFooter from '@/www/components/onePager-footer.vue'

    export default {
        name: 'onepager',
        mixins: [apiSystemSettingsExtension],
        data: () => ({
            name:"OnePager",
            item: null,
        }),
        computed: { },
        mounted: async function () {

            await this.dataRead();

            if(this.item && !this.item.onePager) { window.location = "./"; }
  
        },
        methods: {
            dataRead: async function () {

                let tenant = this.$route.params.tenant; 
 
                let data = await this.SystemSettingsAnonymousGetTenant(tenant);
                if ( data ) {
                  this.item = data;
                }
            },
            navigateToPolicy : function() {

                this.$refs.authCookies.navigateToPolicy();
            },
            navigateToCookie : function() {

                this.$refs.authCookies.navigateToCookie();
            }

        },
        components: {
            "authCookies" : authorizationCookies,
            "cHeader": cHeader,
            "cFooter": cFooter,
        }
    }
  </script>
  