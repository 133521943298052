<template>
    <md-content>
        <div class="md-layout">
            <div class="md-layout-item md-layout md-alignment-top-left">
                <img :class="isNav" @click="onLogoClick" class="logo" v-if="item && item.base64LogoHeader" :src="item.base64LogoHeader" />
            </div>
            <div class="md-layout-item md-layout md-alignment-center-center">
                <h1 v-if="item && item.name" class="mt-20">{{ item.name }}</h1>
            </div>
            <div class="md-layout-item md-layout md-alignment-center-right">
                <span v-if="item && item.phone" class="phone mr-20">Tel: {{ item.phone | phone }}</span>
            </div>
        </div>
        
    </md-content>
</template>
<i18n>
{
    "fr": {
        "Thank": "Votre commande a été transmis, nous vous remercions vivement."
    },
    "en": {
        "Thank": "Your order has been transmitted, we thank you very much."
    }
}    
</i18n>
<style src="../../../../assets/styles/eCommerce-root.css"></style>
<style scoped src="../../../../assets/styles/eCommerce-main.css"></style>
<style scoped src="../../../../assets/styles/eCommerce-theme.css"></style>
<style scoped>
.logo { width: 150px;}
.phone{ font-size: 20px; color: var(--md-theme-default-text-accent-on-background-variant, rgba(0,0,0,0.6)); }
.nav:hover {cursor: pointer;}

</style>
<script>

    import { navigateRouteExtension } from '@/mixins/navigateRouteExtension.js';
    import { apiSystemSettingsExtension } from '@/mixins/apiSystemSettingsExtension.js';
    //import { apiECommerceExtension } from '@/mixins/apiECommerceExtension';
    
    
    export default {
      name: 'content-header', 
      mixins: [navigateRouteExtension, apiSystemSettingsExtension],
      props: {
        //summary: Object,
      },
      data: () => ({
        tenant: null,
        item: null,
        }),
      computed: {
        isNav: function () {
          if (this.item && this.item.webSite) { return 'nav'; }
          else { return ''; }
        }
      },
      mounted: async function () {
    
        this.tenant = this.$route.params.tenant;
        let token = await $App.getAnonymousToken(this.tenant);
    
        await this.dataRead();
    
      },
      methods:{
        dataRead: async function(param) {
            
              let data = await this.SystemSettingsAnonymousGetTenant(this.tenant);
              if (data) {
                this.item = data;
              }
        },
        onLogoClick: function() {
          if(this.item.webSite) {
            this.navigateUrl(this.item.webSite);
          }
        }
      },
      
      components: {
        // Summary: Summary
      },
    }
    
    </script>
