import Vue from 'vue'

export default {
    registerAll: function () {

        Vue.filter('NA', function (value) {
            if (value) {
                return value;
            } else {
                return 'N/A';
            }
        });

        Vue.filter('$', function (value, nullValue ) {
            if(value != null) { 
                if (value != 0) {
                    value = value.toFixed(2);          
                    return value;
                } else {
                    return '0.00';
                }
            } else if( nullValue != null) {
                if (nullValue != 0) {
                    nullValue = '[' + nullValue.toFixed(2) + ']';
                    return nullValue;
                } else {
                    return '[0.00]';
                }
            }
        });

        Vue.filter('NULL', function (value, nullValue ) {
            if(value != null) { 
                return value;
            } else if( nullValue != null) {
                return '[' + nullValue + ']';
            } else {
                return ""
            }
        });
                
        Vue.filter('isClosed', function (value) {
            if (value != false) {
                return "radio_button_unchecked";
            } else {
                return "check_circle_outline";
            }
        });

        Vue.filter('YYYYMMDD', function (value) {
            if (value && value != '0001-01-01T00:00:00+00:00' && value != '0001-01-01T00:00:00Z' ) {
                var d = new Date(value).toLocaleDaylight(),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;

                return [year, month, day].join('-');

            } else {
                return '';
            }
        });

        Vue.filter('YYYYMMDDhhmm', function (value) {
            if (value && value != '0001-01-01T00:00:00+00:00' && value != '0001-01-01T00:00:00Z') {
                var d = new Date(value).toLocaleDaylight(),
                    min = '' + d.getMinutes(),
                    hour = '' + d.getHours(),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;

                if (min.length < 2)
                    min = '0' + min;

                var time = [hour, min].join(':');
                var other = [year, month, day].join('-');
                return other + ' ' + time;

            } else {
                return '';
            }
        });

        Vue.filter('phone', function (value) {
            if (value) {
                var phone = value.toString();

                var c = phone.substring(6, 10);
                var b = phone.substring(3, 6);
                var a = phone.substring(0, 3);

                return a + '-' + b + '-' + c;
            }
        });

        Vue.filter('card', function (value) {
            if (value) {
                var card = value.toString();
                var length = card.length;
                return '*'.repeat(length - 4) + card.substring(length - 4, length);
            }
        });

        Vue.filter('card-exp', function (value) {
            if (value) {
                var card = value.number.toString();
                var length = card.length;
                return '*'.repeat(length - 4).padStart(12,"*") + card.substring(length - 4, length) +' '+ value.expMonth.toString() + '/' + value.expYear.toString() ;
            }
        });

        Vue.filter('textWithBR', function (value) {
            if (value) {
                //value = value.replace(/<script[^>]*>(?:(?!<\/script>)[^])*<\/script>/g, "?Script!?")
                value = value.replace(new RegExp('\r?\n', 'g'), '<br />');
                return value
            }
        });

        Vue.filter('validations', function (value) {
            if (value) {
                if (value.$invalid) {
                    return 'md-invalid';
                }
            }
            return '';
        });

        Vue.filter('contentRangeText', function (value) {
            // see: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Range
            //Content-Range: <unit> <range-start>-<range-end>/<size>
            //Content-Range: <unit> <range-start>-<range-end>/*

            let contentRange = '';
            if (value) {
                let r = value.split(' ');
                if (r.length > 0) {
                    let r2 = r[1].split('/');

                    if (r2.length > 0) {
                        let r3 = r2[0].split('-');
                        if (r3.length > 0) {
                            let first = parseInt(r3[0]) + 1;
                            let last = parseInt(r3[1]) + 1;
                            let total = r2[1];
                            contentRange = first + "-" + last + " / " + total;
                        }
                    }
                }
            }

            return contentRange;
        });

        Vue.filter('arrayToString', function (value) {
            let result = '';

            if (value) {
                value.forEach(function (item) {
                    result = result + item.name + ' - ';
                });
            }

            return result.substring(0, result.length - 3);
        });
    }
};
