var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"cookie"}},[_c('div',{staticClass:"overlayCookie"},[_c('div',{staticClass:"cookieBox cookiePad"},[_c('div',{staticClass:"cookieTextPad"},[_c('h2',{staticClass:"txt-c"},[_vm._v("Nous utilisons des témoins")]),_c('div',{staticClass:"cookieText"},[_c('p',[_vm._v("Votre vie privée est une priorité pour nous. Nous utilisons des témoins (cookies) et d'autres technologies similaires pour personnaliser nos contenus et améliorer votre expérience en ligne. Certains de ces témoins (cookies) peuvent être collectés avec votre consentement préalable.")]),_c('div',{staticClass:"cookieLink"},[_c('p',{staticClass:"txt-r",on:{"click":_vm.navigateToPolicy}},[_vm._v("Politique de confidentialité")])])]),_vm._m(0)])]),_c('div',{staticClass:"cookiePersonalise"},[_c('div',{},[_c('div',{},[_vm._m(1),_c('div',{staticClass:"cookiePersonaliseInfo"},[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"cookieLink"},[_c('p',{staticClass:"txt-r",on:{"click":_vm.navigateToPolicy}},[_vm._v("Politique de confidentialité")])])])]),_vm._m(6)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-5"},[_c('button',{staticClass:"btnCookie flt-r",attrs:{"onclick":"$authorizationCookies.cookieChoice(1);"}},[_vm._v("Tout Accepter")]),_c('button',{staticClass:"btnCookie flt-r",attrs:{"onclick":"$authorizationCookies.cookieChoice(3);"}},[_vm._v("Personalisé vos témoins")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h2',{staticClass:"txt-c"},[_vm._v("Personalisé vos témoins")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('h2',{staticClass:"cookiePersionalisationTitle"},[_vm._v("Témoins nécessaires")])]),_c('div',[_c('p',{staticClass:"cookiePersionalisationTexte"},[_vm._v("Ces témoins vous permettent d’apprécier toutes les fonctionnalités de notre site et d’y naviguer de manière optimale et sécuritaire. Ils nous servent aussi à vous demander votre opinion et à évaluer votre satisfaction.")])]),_c('div',[_c('p',{staticClass:"cookiePersionalisationTexte fw-700 txt-r"},[_vm._v("Ces témoins sont obligatoires.")])]),_c('div',{},[_c('hr')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('h2',{staticClass:"cookiePersionalisationTitle"},[_vm._v("Témoins de performances")])]),_c('div',[_c('p',{staticClass:"cookiePersionalisationTexte"},[_vm._v("Grâce à l'emploi de ces témoins (cookies), nous avons la possibilité d'examiner la manière dont nos sites sont utilisés en vue d'améliorer leur performance et leur fonctionnement.")])]),_c('div',{staticClass:"switchContainer"},[_c('div',{staticClass:"switch off",attrs:{"id":"switchPerformance","onclick":"$authorizationCookies.toggleSwitch('Performance')"}},[_c('div',{staticClass:"slider"})])]),_c('div',[_c('hr')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('h2',{staticClass:"cookiePersionalisationTitle"},[_vm._v("Témoins de personnalisation")])]),_c('div',[_c('p',{staticClass:"cookiePersionalisationTexte"},[_vm._v("Grâce à ces témoins (cookies), nous sommes en mesure de retenir vos préférences et d'ajuster les contenus proposés en fonction de votre historique de navigation et de vos choix précédents.")])]),_c('div',{staticClass:"switchContainer"},[_c('div',{staticClass:"switch off",attrs:{"id":"switchPersonalization","onclick":"$authorizationCookies.toggleSwitch('Personalization')"}},[_c('div',{staticClass:"slider"})])]),_c('div',[_c('hr')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('h2',{staticClass:"cookiePersionalisationTitle"},[_vm._v("Témoins de publicité ciblée")])]),_c('div',[_c('p',{staticClass:"cookiePersionalisationTexte"},[_vm._v("Ces témoins (cookies) contribuent à restreindre la fréquence d'affichage des publicités, à adapter nos offres et services à vos centres d'intérêt, à évaluer l'efficacité de campagnes publicitaires, entre autres usages. Il est possible que nous partagions ces données avec nos partenaires.")])]),_c('div',{staticClass:"switchContainer"},[_c('div',{staticClass:"switch off",attrs:{"id":"switchAdvertising","onclick":"$authorizationCookies.toggleSwitch('Advertising')"}},[_c('div',{staticClass:"slider"})])]),_c('div',[_c('hr')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-5"},[_c('button',{staticClass:"btnCookie flt-r",attrs:{"onclick":"$authorizationCookies.cookieChoice(1);"}},[_vm._v("Tout Accepter")]),_c('button',{staticClass:"btnCookie flt-r",attrs:{"onclick":"$authorizationCookies.cookieChoice(2);"}},[_vm._v("Accepter")])])
}]

export { render, staticRenderFns }