export const autocompleteExtension = {
    methods: {
        autocompleteDataSource: function (enumerable, func) {
            let result = [];
            let f = function (src, func) {
                this.ref = src;
                this.title = func(src);
                this.getTitle = func;
                this.toString = function () { return this.title; }
                this.toLowerCase = function () { return this.title.toLowerCase(); }
            };
            for (let i = 0; i < enumerable.length; i++) {
                let item = new f(enumerable[i], func);
                result.push(item);
            }
            return result;
        }
    }
}
