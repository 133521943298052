<template>
    <md-content>
        <div v-if="item" class="footer md-layout">
          <div class="md-layout-item md-layout md-alignment-center-left md-ml-3">
            <div class="md-layout md-alignment-center-left">

              <div class="md-layout-item md-size-15">
                <img class="logo" v-if="item && item.base64LogoHeader" :src="item.base64LogoHeader" />
              </div>
              <div class="md-layout-item">
                <div>
                  <span v-if="item.name">{{item.name}}</span>
                  <span class="md-ml-5" v-if="item.address && item.address.street ">{{item.address.street}}</span>
                  <span class="" v-if="item.address && item.address.city && item.address.city.name ">, {{item.address.city.name}}</span>
                  <span class="md-ml-2" v-if="item.address && item.address.zipCode ">{{item.address.zipCode}}</span>
                </div>
                <div>
                  <span v-if="item.phone" class="phone mr-20"> {{ item.phone | phone }}</span>
                  <span v-if="item.email" class="phone mr-20"> {{ item.email }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="md-layout-item md-layout md-alignment-center-right md-mr-3"><span class="md-caption">Abstracxion Copyright {{version}} © Tous droits réservés</span></div>
        </div>
    </md-content>
</template>
<i18n>
{
    "fr": {
        "Thank": "Votre commande a été transmis, nous vous remercions vivement."
    },
    "en": {
        "Thank": "Your order has been transmitted, we thank you very much."
    }
}    
</i18n>
<style src="../../../../assets/styles/eCommerce-root.css"></style>
<style scoped src="../../../../assets/styles/eCommerce-main.css"></style>
<style scoped src="../../../../assets/styles/eCommerce-theme.css"></style>
<style scoped>
  .logo { height: 80px;}
  .footer { height: 90px; background-color: rgba(var(--primary),1) !important; line-height: unset; }
  .footer span { color: white; }
</style>
<script>

    import { navigateRouteExtension } from '@/mixins/navigateRouteExtension.js';
    import { apiSystemSettingsExtension } from '@/mixins/apiSystemSettingsExtension.js';
    //import { apiECommerceExtension } from '@/mixins/apiECommerceExtension';
    
    
    export default {
      name: 'content-footer', 
      mixins: [navigateRouteExtension, apiSystemSettingsExtension],
      props: {
        //summary: Object,
      },
      data: () => ({
        tenant: null,
        version : '2024.10.28.1',
        item : null
        
        }),
      mounted: async function () {
    
        this.tenant = this.$route.params.tenant;
        let token = await $App.getAnonymousToken(this.tenant);
    
        await this.dataRead();
    
      },
      methods:{
        dataRead: async function(param) {
            
            let data = await this.SystemSettingsAnonymousGetTenant(this.tenant);
            if (data) {
              this.item = data;
            }
  
      }
      },
      
      components: {
        // Summary: Summary
      },
    }
    
    </script>
