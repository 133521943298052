<template>
    <md-content>
        <cHeader />
        <div class="container">
            <h1 class="titleModif">{{ Item.title }}</h1>

            <div class="md-layout mt-10">
                <div class="md-layout-item md-layout md-size-25 md-small-size-100 md-alignment-top-center">
                    <div id="image" class="md-layout-item" style="max-height:500px; ">
                        <div v-if="this.image" class="imageBG"><img class="productImg" :src="this.image" /></div>
                    </div>
                </div>
                <div class="md-layout-item md-layout md-size-75 md-small-size-100 md-alignment-center-center"
                    style="position:relative;">
                    <div class="borderLeft"></div>
                    <div class="widthText">
                        <h2 class="secondaryTitle">{{ Item.title }}</h2>
                        <p class="ProductDescription" v-html="$options.filters.textWithBR(Item.description)"></p>
                        <div class="md-layout" v-if="Videos">
                            <div class="md-layout-item md-size-100"><h2 class="secondaryVideoTitle">Vidéo</h2></div>
                            <iframe class="md-layout-item videoContainer" v-for="(item, i) in Videos" :key="i" frameborder="0" :src="item" style="width:auto; height:350px;"></iframe>
                        </div>
                        <div class="md-layout">
                            <div class="md-layout-item md-small-size-100 md-mt-5">
                                <span class="md-display-1 md-primary"><strong>{{ $t("availability") }}</strong></span>

                                <div v-if="!isDiscontinued">
                                    <div ax-id='chpAvailibility' class="md-mt-2">
                                        <md-chip v-bind:class="[{ 'md-active': selectedSizeIndex == index }]" v-for="(item, index) in sizes" :key="index" md-clickable @click="onAvailabilitySelect(index)">{{ item }}</md-chip>
                                    </div>
                                    <div v-if="isDeliveryDelay">{{ $t("deliveryDelay") }}</div>
                                </div>
                                <div v-else>{{ $t("discontinued") }}</div>

                            </div>
                            <div class="md-layout-item md-small-size-100 md-mt-5">
                                <span class="md-display-1 md-primary"><strong>{{ $t("price") }}</strong></span>

                                <p class="price md-mt-2">
                                    <span class="md-price"> {{ Item.price }}$</span><span v-if="Item.discount > 0" class="md-discount"> - {{ $t("discount") }} ${{ Item.discount }}</span>
                                </p>
                            </div>
                        </div>
                        <div class="md-layout md-alignment-center-left">
                            <span class="md-primary md-cursor-pointer" @click.stop="onBack">{{ $t("back") }}</span>
                        </div>

                    </div>
                </div>
            </div>

        </div>


        <div class="Banner waterMarkFB mt-50">
            <div class="">
                <div class="textProductBanner">
                    <div class="md-layout default-pad">
                        <div class="md-layout-item  md-size-60  md-small-size-50 md-xsmall-hide">
                            <div class="whiteLine"></div>
                        </div>
                        <div class=" md-layout-item  md-size-40 md-small-size-50 md-xsmall-size-100">
                            <h2 @click="AddItemInOrder()" class="addItemText">{{ $t("addCart") }}</h2>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <cFooter />

    </md-content>
</template>
<i18n>
    {
      "en": {
        "back": "Back",
        "addCart":"Add to Cart",
        "availability": "Availability",
        "discontinued": "Not available",
        "price" : "Price",
        "deliveryDelay": "** Delivery delay",
        "discount": "discount"
      },
      "fr": {
        "back": "Retour",
        "addCart":"Ajouter au panier",
        "availability": "Disponibilité",
        "discontinued": "Non Disponible",
        "price" : "Prix",
        "deliveryDelay": "** Délais de Livraison",
        "discount": "rabais"
      }
    }
</i18n>

<style src="../../../../assets/styles/eCommerce-root.css"></style>
<style scoped src="../../../../assets/styles/eCommerce-main.css"></style>
<style scoped src="../../../../assets/styles/eCommerce-theme.css"></style>
<style scoped>
    
    .md-chip.md-active { background-color: rgb(var(--primary));}

    .md-primary { color:rgb(var(--primary3)) !important; background-color: transparent !important; }

    .price { font-weight: 700 !important; font-size: 40px;  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);}
    /* margin-top: 100px !important; */
    .md-price { color:#2fa0c2;}
    .md-discount { color: rgb(212, 44, 44);}

    .titleModif {margin:60px 0px 30px 0px!important; font-size: 80px; line-height: normal;}
    #image{ width: 100%; height: 100%; max-height: 800px;  background-image: url('https://abstracxionstorage.blob.core.windows.net/wx3/products/NoImage-AX.jpg'); background-position: top center; background-repeat: no-repeat; background-size: contain; }
    .imageBG { height: 100%; width: 100%; background-color: #FAFAFA; }
    .productImg { width: 100%; height: 100%; max-width: 700px; max-height: 800px; object-fit: scale-down !important; object-position: top center;}
    /*internet explorer doesnt take well sticky ; it doesnt fit well with diapo too*/
    div.sticky { position: -webkit-sticky; /*Safari*/ position: sticky; top: 0; }

    .produit {text-align:left; min-width: 220px; max-width:270px;}
    a {text-decoration:none;}
    a img:hover {background: linear-gradient(to bottom left, #fff 0%, #fff 50%, #2fa0c2 50%, #2fa0c2 100%);}
    .produit_available {color: rgb(212, 44, 44);font-weight:bold;text-align:left;font-style:italic;}
    .container {display:block; margin: 50px !important;}
    /* .center_container {display:block ; padding-left:auto; padding-right: auto; width:90%;} */
    .marginSpacingModif {margin-left:20px;}
    .borderLeft { position: absolute; border-left: 1px solid #2fa0c2; height: 250px; left:80px; top:50px;}
    .secondaryTitle {color:rgb(var(--primary3));  margin-top: 100px !important; font-size: 36px; line-height: 40px;}
    .secondaryVideoTitle {color:rgb(var(--primary3));  margin-top: 10px !important; font-size: 36px; line-height: 40px;}
    .videoContainer { min-width: 45%; min-width: 400px; margin: 10px;}
    .whiteText { font-family: Ephesis; color:white; text-align: right; font-size: 50px; margin-top: 25px;}
    .addItemText { color:white; text-align: right; font-size: 50px; margin-top: 25px; cursor: pointer; line-height: 40px;}
    .addItemText:hover {color: rgb(var(--primary));}
    .whiteLine { border-top: 1px solid white; margin-top: 40px !important; }
    .back{ color: rgb(var(--secondary)); cursor: pointer; margin-top: 20px !important;}
    .ProductDescription {font-weight: lighter !important; font-size: 18px; text-align: justify; margin: 30px 0 30px 0  !important;}
    .Banner{height: 200px; width: 100%;  background-color: #01669C; z-index:0; overflow-x: hidden;}
    .waterMarkFB{ background-size: contain; background-image: url("../../../../../public/resources/images/LogoAbstracxion.png"); background-position-x: 6%; background-repeat: no-repeat;}
    .textProductBanner{height: 50px; margin: 70px 0px 0px 0px !important;}
    .widthText{ display: block; margin-left: 160px; margin-right: 80px; text-align: center;}

    @media handheld, only screen and (max-width: 600px) {
        .addItemText { text-align: center !important; font-size: 50px; margin-top: 25px; line-height: 40px;}
        .videoContainer { min-width: 300px; }
    }
    @media handheld, only screen and (max-width: 960px) {
        .borderLeft { display: none;}
        .secondaryTitle {color: rgba(var(--primary3));  margin-top: 10px !important; font-size: 24px; line-height: 25px; }
        .addItemText { color:white; text-align: right; font-size: 30px !important; margin-top: 25px; cursor: pointer; line-height: 40px;}
        .widthText{ display: block; margin-left: 0px !important; text-align: center;}
    
    }
    @media handheld, only screen and (max-width: 1264px) {
        h1 {font-size: 48px !important; height: auto;line-height: 40px;}
        .ProductDescription {font-weight: lighter !important; font-size: 16px; text-align: justify; margin-top: 100px !important;}
        .addItemText { color:white; text-align: right; font-size: 40px; margin-top: 20px; cursor: pointer; line-height: 40px;}
        .borderLeft { left:40px;}
        .widthText{ display: block; margin-left: 80px; margin-right: auto; text-align: center;}
    }

</style>

<script>
import { navigateRouteExtension } from '@/mixins/navigateRouteExtension.js';
import { apiSystemSettingsExtension } from '@/mixins/apiSystemSettingsExtension.js';
import { apiECommerceExtension } from '@/mixins/apiECommerceExtension.js'

import cHeader from '../components/content-header.vue';
import cFooter from '../components/content-footer.vue';


export default {
  name: 'ecommerceProductItem',
  mixins: [navigateRouteExtension, apiSystemSettingsExtension, apiECommerceExtension],
  data: () => ({
      tenant: null,
      id: null,
      name: "",
      Item: { title: "product" },
      Videos: null,
      image: 'https://abstracxionstorage.blob.core.windows.net/wx3/products/NoImage-AX.jpg',
      sizes: [],
      selectedSizeIndex: null
  }),
  computed: {
        isDiscontinued: function () {
            let r = false;
            if (this.Item && this.Item.qty < 1 && this.Item.discount > 0) {
                r = true;
            }
            return r;
        },
        isDeliveryDelay: function () {
            let r = false;
            // compare sizes vs availability
            if (this.Item && this.sizes.length > 0) {
                if(this.Item.availability) {
                    let available = ';'+this.Item.availability.toUpperCase().replace(',',';')+';'
                    if (this.selectedSizeIndex != null) {
                        if (!available.includes(';'+this.sizes[this.selectedSizeIndex].toUpperCase()+';')) {
                            r = true;
                        }
                    }
                }
                else {r = true;}
            }
            return r;
        },
    },
  mounted: async function () {

    this.id = this.$route.params.id;
    this.tenant = this.$route.params.tenant;

    let token = await $App.getAnonymousToken(this.tenant);
    //console.log($App.tenant);

    await this.dataRead();

  },
  methods: {
      dataRead: async function () {

          let data = await this.EcommerceProductGetById(this.id);
          if (data) {
              this.Item = data;
              if (data.size) {
                  this.sizes = data.size.toUpperCase().replace(',', ';').split(';');
              }

              this.image = data.image.url;

              if (data.optionURL) {
                  let s = data.optionURL;
                  if (s.includes(';')) { this.videos = s.split(';'); }
                  else {
                      // this.Videos = [s];
                  }
              }

          }

          //   let data = await this.SystemSettingsAnonymousGetTenant($App.tenant);
          //   if (data) {
          //     this.name = data.name;
          //   }

      },
      onAvailabilitySelect: function (e) {
          if (e != null) {
              this.selectedSizeIndex = e;
          }
      },
      AddItemInOrder: async function () {
          if (!this.isDiscontinued) {

              let options = null;
              if (this.selectedSizeIndex != null) {
                  options = this.sizes[this.selectedSizeIndex];
                  if (this.isDeliveryDelay) { options += " **"; }
              }

              let added = await this.AddItem(this.Item, options);
              if (added == true) {
                  this.navigateTo({ name: 'ecommerceOrder', params: { tenant: this.tenant } })
              }
          } else {
              $App.event.$emit("app_notify", { message: this.$t("discontinued") });
          }
      },
      onBack: function () {
        this.navigateBack({ 'name': 'ecommerceProductList', 'params': { 'tenant': this.tenant } })
      }


  },
  components: {
    "cHeader": cHeader,
    "cFooter": cFooter,
  }
}
</script>
