import { render, staticRenderFns } from "./Item.vue?vue&type=template&id=9fe66a2e&scoped=true"
import script from "./Item.vue?vue&type=script&lang=js"
export * from "./Item.vue?vue&type=script&lang=js"
import style0 from "../../../../assets/styles/eCommerce-root.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "../../../../assets/styles/eCommerce-main.css?vue&type=style&index=1&id=9fe66a2e&prod&scoped=true&lang=css&external"
import style2 from "../../../../assets/styles/eCommerce-theme.css?vue&type=style&index=2&id=9fe66a2e&prod&scoped=true&lang=css&external"
import style3 from "./Item.vue?vue&type=style&index=3&id=9fe66a2e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9fe66a2e",
  null
  
)

/* custom blocks */
import block0 from "./Item.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports