<template>
    <md-content>
      <cHeader />
        <div class="default-pad">
            <div v-if="order" class="md-layout mt-30">
                <div class="md-layout-item md-layout md-size-75">

                    <div class="md-layout-item md-size-100 md-mt-3"><h1>{{ $t("general.title") }}</h1></div>
                    <div v-if="order.items && order.items.length > 0" class="md-layout-item md-layout md-size-100 md-mt-5"><h2>{{ $t("general.items") }}</h2></div>
                    <div v-else class="md-layout-item md-layout md-size-100 md-mt-5"><h2>{{ $t("general.noItems") }}</h2></div>

                    <div class="md-layout-item md-size-100 md-pr-5">
                        <div v-if="order.items && order.items.length > 0" class="md-layout-item md-layout md-xsmall-hide">
                        <div class="md-layout-item md-layout md-size-20 md-xsmall-size-100 md-alignment-center"><h2></h2></div>
                        <div class="md-layout-item md-layout md-size-30 md-small-size-30 md-xsmall-size-100"><h2 class="mobileText md-layout md-alignment-center-left">{{ $t("general.name") }}</h2></div>
                        <div class="md-layout-item md-layout md-size-10 md-small-size-10 md-xsmall-size-20 md-alignment-center"><h2 class="mobileText">{{ $t("general.price") }}</h2></div>
                        <div class="md-layout-item md-layout md-size-10 md-small-size-10 md-xsmall-size-20 md-alignment-center"><h2 class="mobileText">{{ $t("general.discount") }}</h2></div>
                        <div class="md-layout-item md-layout md-size-10 md-small-size-10 md-xsmall-size-20 md-alignment-center"><h2 class="mobileText">{{ $t("general.qty") }}</h2></div>
                        <div class="md-layout-item md-layout md-size-10 md-small-size-10 md-xsmall-size-20 md-alignment-center"><h2 class="mobileText">{{ $t("general.total") }}</h2></div>
                        <div class="md-layout-item md-layout md-size-10 md-small-size-10 md-xsmall-size-20 md-alignment-center"><h2 class="mobileText">{{ $t("general.undo") }}</h2></div>
                        </div>
                        <div v-if="order.items && order.items.length > 0" class="md-layout-item md-layout md-size-100">
                        <div v-for="(item, i) in order.items" :key="i" class="md-layout-item md-layout md-size-100 md-my-3">
                            <div class="ItemsBox md-layout md-size-100">
                            <div class="md-layout-item md-layout md-size-20 md-xsmall-size-100 md-alignment-center">
                                <div class="ItemsImgBox"><img v-if="item.url"  class="ItemsImg" :src="item.url" :truesrc="item.url"/> <img v-else class="ItemsImg" :src="image" :truesrc="image"/></div>
                            </div>
                            <div class="md-layout-item md-layout md-size-30 md-small-size-30 md-xsmall-size-100 md-alignment-center-left"><h2 class="mobileText">{{item.description}}<br />{{item.options}}</h2></div>
                            <div class="md-layout-item md-layout md-size-10 md-small-size-10 md-xsmall-size-20 md-alignment-center"><h2 class="mobileText">{{item.price}}$</h2></div>
                            <div class="md-layout-item md-layout md-size-10 md-small-size-10 md-xsmall-size-20 md-alignment-center md-discount"><h2 class="mobileText">{{item.discount}}$</h2></div>
                            <div class="md-layout-item md-layout md-size-10 md-small-size-10 md-xsmall-size-20 md-alignment-center"><h2 class="mobileText"><span class="hoverItem" @click="changeQty(i,2)">-</span> {{item.qty}} <span class="hoverItem" @click="changeQty(i,1)">+</span></h2></div>
                            <div class="md-layout-item md-layout md-size-10 md-small-size-10 md-xsmall-size-20 md-alignment-center"><h2 class="mobileText">{{item.total|$}}$</h2></div>
                            <div class="md-layout-item md-layout md-size-10 md-small-size-10 md-xsmall-size-20 md-alignment-center"><h2 class="mobileText hoverItem" @click="removeItem(i);">X</h2></div>

                            </div>
                        </div>
                        <div class="md-layout-item md-layout md-size-100">{{ $t("shipping.deliveryDelay") }}</div>
                        </div>
                    </div>

                    <!--Legal mention-->
                    <div class="md-layout-item md-layout md-size-100 md-mt-5">
                        <legal-notice class="md-mt-5"></legal-notice>
                    </div>

                </div>
                <div class="md-layout-item md-layout">
                    <Summary step="order" v-bind:item.sync="order" v-bind:dirty.sync="isDirty" @eCommerceNext="Updated" @eCommerceBack="onBack" />
                </div>

            </div>
        </div>
      <cFooter />
    </md-content>
</template>

<i18n src="./i18n/order.json"></i18n>

<style src="../../../../assets/styles/eCommerce-root.css"></style>
<style scoped src="../../../../assets/styles/eCommerce-main.css"></style>
<style scoped src="../../../../assets/styles/eCommerce-theme.css"></style>
<style scoped>
  .md-discount { color: rgba(var(--secondary),0.3); }
  h2 {font-size:24px;}
  .default-pad {margin: 0px 0px 0px 20px !important; min-height:650px !important;}
  .ItemsBox { position:relative; height: 140px; color: rgba(var(--primary)); background-color: #FFF; box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5); }
  /* margin: 15px 20px 15px 20px !important; */
  .ItemsDescription {color: rgba(var(--secondary)); font-size: small; margin: -30px 0px 0px 10px !important; display: inline-block;}
  .ItemsImg {height:100%; border-radius: 50%;}
  .ItemsImgBox {height: 110px;}
  .hoverItem:hover {color: rgb(var(--secondary)); cursor: pointer;}
  .mobileText {text-align: left; padding:5px;}
    @media handheld, only screen and (max-width: 600px) {
      .ItemsBox { height: 180px;}
    }
    @media handheld, only screen and (max-width: 958px) {
      .mobileText {margin-left:auto; margin-right:auto;}
      .ItemsImgBox {height: 90px !important;}

    }
    @media handheld, only screen and (max-width: 1264px) { 
      .default-pad {padding: 0px 20px 0px 20px !important;}
      .mobileText {}
      h2 {font-size:20px;}
      .ItemsImgBox {height: 100px;}
    }
    
</style>

<script>
import { navigateRouteExtension } from '@/mixins/navigateRouteExtension.js';
import { apiECommerceExtension } from '@/mixins/apiECommerceExtension.js'

import cHeader from '../components/content-header.vue';
import cFooter from '../components/content-footer.vue';

import Summary from '../components/infoPayment.vue'
import legal_notice from'../components/legal-notice.vue'


export default {
  name: 'Order',
  mixins: [navigateRouteExtension, apiECommerceExtension],
  props: {
    //summary: Object,
  },
  data: () => ({
    tenant: null,
    order: null,
    validationObject:[],
    image: 'https://abstracxionstorage.blob.core.windows.net/wx3/products/NoImage-AX.jpg',
    isDirty: false,
    ShippingDate:{min:""},
    }),
  mounted: async function () {

    //if(!$App.onlineStore) { this.$router.replace('/'); }

    this.tenant = this.$route.params.tenant;
    let token = await $App.getAnonymousToken(this.tenant);

    await this.dataRead();

  },
  methods:{
    dataRead: async function () {

        this.order = await this.GetOrder();

        this.validation();

      },
    removeItem: async function (ItemIndex) {
      this.order = await this.DeleteItem(ItemIndex);
    },
    changeQty: async function (ItemIndex,changeId) {
      if(changeId == 1){
        this.isDirty = true;
        this.order.items[ItemIndex].qty = this.order.items[ItemIndex].qty + 1;
        this.order.items[ItemIndex].total = this.order.items[ItemIndex].qty * this.order.items[ItemIndex].price;

      } else if(changeId ==2) {
        this.isDirty = true;
        let qty = this.order.items[ItemIndex].qty - 1;
        if(qty > 0){
          this.order.items[ItemIndex].qty = this.order.items[ItemIndex].qty - 1;
          this.order.items[ItemIndex].total = this.order.items[ItemIndex].qty * this.order.items[ItemIndex].price;
        }else {this.order.items.splice(ItemIndex, 1);}
       
      }

      //this.order = await this.UpdateOrder(this.order);
      //this.isDirty = false;

    },
    validation: async function(){
      let valid = true;
      let validation = this.order.validations;
      if(validation){      
        valid = true; //this can change for testing 
        validation.forEach(e => {
          if(e.severity == 3) {
            if(e.Field == "items"){if(e.Code == "Required"){console.log('Required');valid = false;}}
          }
        });
      }
      return valid;
    },
    Updated: async function() {
      let valid = await this.validation();
      if(valid == true){  this.onNext(); }
      
    },
    onNext: function () {
        this.navigateTo({ 'name': 'ecommerceOrderBilling', 'params': {'tenant': this.tenant } });
    },
    onBack: function() {
        this.navigateBack({ 'name': 'ecommerceProductList', 'params': { 'tenant': this.tenant } });
    },
  },
  components: {
    "cHeader": cHeader,
    "cFooter": cFooter,
    'legal-notice' : legal_notice,
    'Summary': Summary
  }
}

</script>