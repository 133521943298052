import { render, staticRenderFns } from "./content-footer.vue?vue&type=template&id=23e31aa0&scoped=true"
import script from "./content-footer.vue?vue&type=script&lang=js"
export * from "./content-footer.vue?vue&type=script&lang=js"
import style0 from "../../../../assets/styles/eCommerce-root.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "../../../../assets/styles/eCommerce-main.css?vue&type=style&index=1&id=23e31aa0&prod&scoped=true&lang=css&external"
import style2 from "../../../../assets/styles/eCommerce-theme.css?vue&type=style&index=2&id=23e31aa0&prod&scoped=true&lang=css&external"
import style3 from "./content-footer.vue?vue&type=style&index=3&id=23e31aa0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23e31aa0",
  null
  
)

/* custom blocks */
import block0 from "./content-footer.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports