<template>
    <md-toolbar class="one-HeaderToolbar one-HeaderHeight md-large md-dense" :style="{backgroundColor: bgColor }" >
        <div class="md-toolbar-row">
            <div class="md-toolbar-section-start">
                <img @click="navigateToHome" class="one-HeaderHeight logo" v-if="item && item.base64LogoHeader" :src="item.base64LogoHeader" />
                <span v-if="item && item.onePager" class="one-Slogan md-headline">{{ item.onePager.slogan }}</span>
            </div>
            <div class="md-toolbar-section-end">
                <a class="facebook" v-if="item.onePager && item.onePager.facebook" :href="item.onePager.facebook" target="_blank">
                    <img>
                </a>  
                <span v-if="item && item.phone" class="md-title">{{ item.phone | phone }}</span>
                
                <md-button class="md-icon-button">
                    <md-icon>more_vert</md-icon>
                </md-button>
            </div>

        </div>
      </md-toolbar>
</template>
<style>
    @font-face {
      font-family: "Ephesis";
      src:url("../../../public/resources/Fonts/Ephesis-Regular.ttf");
    }    
</style>
<style scoped>
    .one-HeaderHeight { height: 80px; }
    .one-Slogan { font-family: Ephesis; font-size: 36px;  margin-top: 1.2em;   }

    .one-HeaderToolbar img.logo { padding: 10px; cursor: pointer; }
    .one-HeaderToolbar a.facebook img { padding: 10px; max-height: 50px; cursor: pointer; content:url("/resources/icons/facebookBorder_50x50.png"); }
    .one-HeaderToolbar a.facebook:hover img { content:url("/resources/icons/facebookBorder_White50x50.png") }

    @media handheld, only screen and (max-width: 680px) {
        .one-Slogan { font-size: 20px;  margin-top: 1em;   }
    }
    @media handheld, only screen and (max-width: 420px) {
        .one-Slogan { display: none;  }
    }

</style>
<script>
    //import { apiSystemSettingsExtension } from '@/mixins/apiSystemSettingsExtension.js';

    export default {
        name: 'onePager-header',
        mixins: [],
        props: {
            item: Object
        },
        data: () => ({
            // facebook: 'https://www.facebook.com/pages/Comptable%20Drummond%20Info/866259566876741/',
        }),
        mounted: async function () {

        },
            computed: {
                bgColor: function () {
                    let color = '#ffffff';
                    try {
                        if(this.item && this.item.onePager && this.item.onePager.bgColor !== '') {
                            color = this.item.onePager.bgColor;
                        }
                    } catch (error) { color = '#ffffff'; }
                    
                    return color;
                }
        },        
        methods: {
            navigateToHome: function() {
                if(this.$router.history.current.name != 'home') {
                    console.log('ici');
                    this.$router.push({ name: 'home' });
                }
               
            }

        }
    }    
</script>