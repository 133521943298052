import { render, staticRenderFns } from "./order-billing.vue?vue&type=template&id=80f7101e&scoped=true"
import script from "./order-billing.vue?vue&type=script&lang=js"
export * from "./order-billing.vue?vue&type=script&lang=js"
import style0 from "../../../../assets/styles/eCommerce-root.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "../../../../assets/styles/eCommerce-main.css?vue&type=style&index=1&id=80f7101e&prod&scoped=true&lang=css&external"
import style2 from "../../../../assets/styles/eCommerce-theme.css?vue&type=style&index=2&id=80f7101e&prod&scoped=true&lang=css&external"
import style3 from "./order-billing.vue?vue&type=style&index=3&id=80f7101e&prod&scoped=true&lang=css"
import style4 from "./order-billing.vue?vue&type=style&index=4&id=80f7101e&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80f7101e",
  null
  
)

/* custom blocks */
import block0 from "./i18n/order.json?vue&type=custom&index=0&blockType=i18n&issuerPath=D%3A%5CDevelopment%5CVSTS.Git%5CAbstracxion%5CSpec_2.0%5CSolutions%5CUI%5CAbstracxion.Web%5Csrc%5Cwww%5Cviews%5Cecommerce%5Corder%5Corder-billing.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports