<template>
    <!--Footer-->
    <footer class="footer">
      <div class="bgBannerFooter">
        <div class="c-tierce-light-3 grid-pad">

          <div class="md-layout md-alignment-center footerLogoSpacing">
            <img src="resources/images/LogoCompletAbstracxion.png" class="footerLogo">
          </div>

          <div class="md-layout">
           <div class="md-layout-item md-small-size-100 md-layout md-alignment-center-left"></div>
            <div class="md-layout-item md-small-size-100 md-layout md-alignment-center-center">
             <p class="footerText">Un problème ? <a style="" href="mailto:support@abstracxion.com">support@abstracxion.com</a></p>
            </div>
            <div class="md-layout-item md-small-size-100 md-layout md-alignment-center-right">
              <a href="https://www.facebook.com/profile.php?id=100057288773309" target="_Blank"><img @click="" src="resources/images/facebook.png" class="footerFacebook"></a> <!--TODO have to change the logo in the corner-->
            </div>
          </div>
          <div class="md-layout md-small-size-100">
            <div class="md-layout-item"></div>
            <div class="md-layout-item"></div>
            <div class="md-layout-item">
              <div class="footerCookieLink flt-r"><p onclick="$authorizationCookies.navigateToCookie()" class="txt-r">Mes témoins</p></div>
              <div class="footerCookieLink flt-r"><p onclick="$authorizationCookies.navigateToPolicy()" class="txt-r">Politique de confidentialité</p></div>
            </div>
          </div>

        </div>
      </div>
    </footer>
</template>
<style scoped>
    .grid-pad {padding: 0px 40px;}
    .footer {position:relative; width: 100%; padding:auto 0; font-family: serif; overflow: hidden;}
    .footerLink {font-size: 32px; text-transform: uppercase; color: black;  margin-left:15px; margin-bottom:20px !important;}
    .bgBannerFooter {background-image: url("../../../public/resources/images/BannerIndexFooter.jpg"); background-position: center top; background-size: cover; background-repeat: no-repeat;}
    .footerText {font-size: 2em; padding-bottom:10px;}
    .footerLogo {height:150px !important;}
    .footerLogoSpacing {padding: 50px 0px;}
    .footerFacebook {height:50px !important;}
    .footerCookieLink { color: rgb(var(--primary)) !important; font-size: 1.25em; text-decoration: none !important; cursor: pointer; display: inline-block; padding: 0px 10px !important;}
    .footerCookieLink:hover { font-weight: 700; }
    .flt-r {float: right;}
    @media handheld, only screen and (max-width: 420px) {}
    @media handheld, only screen and (max-width: 680px) {}


</style>
<script>


    export default {
        name: 'website-footer',
        mixins: [],
        props: {},
        data: () => ({}),
        mounted: async function () {},
        computed: {},        
        methods: {
          navigateFacebook: function(){

          }
        }
    }    
</script>