<template>
    <html>
        <body id="d6fd09d8-2026-4a67-892e-f81c852431cf">
    <!--Cookie-->
    <div id="cookie" >
      <div class="overlayCookie">
        <div class="cookieBox cookiePad">
          <div class="cookieTextPad">
            <h2 class="txt-c">Nous utilisons des témoins</h2>
            <div class="cookieText">
              <p>Votre vie privée est une priorité pour nous. Nous utilisons des témoins (cookies) et d'autres technologies similaires pour personnaliser nos contenus et améliorer votre expérience en ligne. Certains de ces témoins (cookies) peuvent être collectés avec votre consentement préalable.</p>
              <div class="cookieLink"><p onclick="$authorizationCookies.navigateToPolicy()" class="txt-r">Politique de confidentialité</p></div>
            </div>
            <div class="mt-5">
              <button class="btnCookie flt-r" onclick="$authorizationCookies.cookieChoice(1);">Tout Accepter</button>
              <button class="btnCookie flt-r" onclick="$authorizationCookies.cookieChoice(3);">Personalisé vos témoins</button>
            </div>
          </div>
          
        </div>
        <div class="cookiePersonalise">
          <div class="">
            <div class="">
              <div><h2 class="txt-c">Personalisé vos témoins</h2></div>
              <div class="cookiePersonaliseInfo">     
                <div><!--Essentiel-->
                  <div><h2 class="cookiePersionalisationTitle">Témoins nécessaires</h2></div>
                  <div><p class="cookiePersionalisationTexte">Ces témoins vous permettent d’apprécier toutes les fonctionnalités de notre site et d’y naviguer de manière optimale et sécuritaire. Ils nous servent aussi à vous demander votre opinion et à évaluer votre satisfaction.</p></div>
                  <div><p class="cookiePersionalisationTexte fw-700 txt-r">Ces témoins sont obligatoires.</p></div>
                  <div class=""><hr></div>
                </div>
                <div><!--Performance-->
                  <div><h2 class="cookiePersionalisationTitle">Témoins de performances</h2></div>
                  <div><p class="cookiePersionalisationTexte">Grâce à l'emploi de ces témoins (cookies), nous avons la possibilité d'examiner la manière dont nos sites sont utilisés en vue d'améliorer leur performance et leur fonctionnement.</p></div>
                  <div class="switchContainer"><div id="switchPerformance" class="switch off" onclick="$authorizationCookies.toggleSwitch('Performance')"><div class="slider"></div></div></div>
                  <div><hr></div>
                </div>
                <div> <!--Personnalisation-->
                  <div><h2 class="cookiePersionalisationTitle">Témoins de personnalisation</h2></div>
                  <div><p class="cookiePersionalisationTexte">Grâce à ces témoins (cookies), nous sommes en mesure de retenir vos préférences et d'ajuster les contenus proposés en fonction de votre historique de navigation et de vos choix précédents.</p></div>
                  <div class="switchContainer"><div id="switchPersonalization" class="switch off" onclick="$authorizationCookies.toggleSwitch('Personalization')"><div class="slider"></div></div></div>
                  <div><hr></div>
                </div>
                <div><!--pub-->
                  <div><h2 class="cookiePersionalisationTitle">Témoins de publicité ciblée</h2></div>
                  <div><p class="cookiePersionalisationTexte">Ces témoins (cookies) contribuent à restreindre la fréquence d'affichage des publicités, à adapter nos offres et services à vos centres d'intérêt, à évaluer l'efficacité de campagnes publicitaires, entre autres usages. Il est possible que nous partagions ces données avec nos partenaires.</p></div>
                  <div class="switchContainer"><div id="switchAdvertising" class="switch off" onclick="$authorizationCookies.toggleSwitch('Advertising')" ><div class="slider"></div></div></div>
                  <div><hr></div>
                </div>
                <div class="cookieLink"><p onclick="$authorizationCookies.navigateToPolicy()" class="txt-r">Politique de confidentialité</p></div>
              </div>
            </div>
            <div class="mt-5">
              <button class="btnCookie flt-r" onclick="$authorizationCookies.cookieChoice(1);">Tout Accepter</button>
              <button class="btnCookie flt-r" onclick="$authorizationCookies.cookieChoice(2);">Accepter</button>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- Header-->
    <header class="header md-layout grid-pad">
      <div class="md-layout-item md-layout md-alignment-center-left">
        <div class="md-layout-item md-layout md-size-100">
          <a class="md-layout-item md-size-100" href="/"><img src="resources/images/LogoAbstracxion.png" class="menuLogo"></a>
          <div class="md-layout-item md-layout"  style="max-width: 105px;"><a class="md-layout-item phoneMenu" style="" href="tel:8198175384">819 818-7625</a></div> 
        </div>
      </div>
      <div class="md-layout-item md-layout    md-alignment-center Title">
        <h3 class="menuTitle"><span class="f-secondary">A</span>bstrac<span class="f-primary">x</span>ion</h3>
      </div>
      <div class="md-layout-item md-layout md-alignment-center-right">
        <div id="app"><a class="f-primary menuLink" href="./app.html">Accès Client</a></div>
      </div>
    </header>

    <object data="" type=""></object>
    <!--section2 Img / text top-->
    <section class="abstractBG">
      <div class="md-layout grid-pad" style="height: 100px;">
      </div>
      <div class="md-layout grid-pad">
        <div class="md-layout-item md-large-size-50 md-medium-size-100" style="display: flex; align-items: center;  ">
          <!-- photo-->
          <video style="border: 15px solid rgb(var(--primary)); justify-content: center;" class="bgBannerTop" controls>
            <source src="resources/videos/WX3PubOfficielV2.mp4" type="video/mp4" />
            Votre navigateur ne supporte pas la balise vidéo.
          </video>
        </div>
        <!--text-->
        <div class="md-layout-item md-large-size-50 md-medium-size-100 section2TextSpacing">
          <div class="md-layout-item section2textTitle">
            <h1>
              <span class="f-secondary fw-700">Un outil</span> 
              <br>
              <span class="fw-300">puissant, efficace</span>
              <br>
              <span class="f-primary section2textTitle-64">Et gratuit*</span>
            </h1>
          </div>
           <div class="section2Text">
            <p>
              Découvrez notre outil de gestion, un outil tout-en-un sécurisé, simple, efficace et même gratuit* pour votre entreprise ! 
              Gérez vos clients en toute facilité, suivez vos produits avec précision, et simplifiez votre commerce en ligne. 
              Notre outil de gestion inclut également des fonctionnalités comptables avancées telles que la création de factures, le suivi des paiements et des encaissements. 
              Profitez d'une solution intégrée qui répond à tous vos besoins commerciaux. Essayez-le dès aujourd'hui et transformez la gestion de votre entreprise!
            </p>
            <p>
              AbstracXion vous permet d'ajouter à tout moment, différents <b>modules personnalisés</b> selon les besoins de votre entreprise.
            </p>
            <div class="md-layout-item md-size-100" style="text-align: center; font-family: serif;">
              <a class="btnCookie" href="/www.html#/OffreDemarrageEntreprise#form" >Avoir mon outil gratuit !</a>
            </div>
            <p style="font-size: 16px;">* Conditions s'appliquent</p>
           </div>
        </div>
      </div>
    </section>

    <!--section3 Tools explain  -->
    <section class=" " style="position:relative ; background-color: rgb(var(--primary));">
      <img src="resources/images/square.gif" style="position: absolute; opacity: 0.05; width: 100%; max-height: 100%;">
      <div class="grid-pad section3Style">
        <h3 class="md-layout md-alignment-center-center md-small-size-100 section3Title">Que peut faire l'outil pour vous!</h3>
        <div class="md-layout md-alignment-center section3TextPar section3ParStyle">
          
          <div class="md-layout-item md-small-size-100">
            <div class="md-layout" style="margin-left: auto; margin-right: auto; display: flex;">
              <div class="md-layout-item md-layout md-size-100 md-alignment-center-left section3items">
                <img src="resources/images/carrer.png" class="imgSquare">
                <p>Gestion de client</p>
              </div>
    
              <div class="md-layout-item md-layout md-size-100 md-alignment-center-left section3items">
                <img src="resources/images/carrer.png" class="imgSquare">
                <p>Gestion de la boutique en ligne</p>
              </div>
    
              <div class="md-layout-item md-layout md-size-100 md-alignment-center-left section3items">
                <img src="resources/images/carrer.png" class="imgSquare">
                <p>Gestion de produit</p>
              </div>
            </div>
  
          </div>
  
          <div class="md-layout-item md-small-size-100" >
            <div class="md-layout" style="margin-left: auto; margin-right: auto; display: flex;">
              <div class="md-layout-item md-layout md-size-100 md-alignment-center-left section3items">
                <img src="resources/images/carrer.png" class="imgSquare">
                <p>Gestion de la sécurité</p>
              </div>
    
              <div class="md-layout-item md-layout md-size-100 md-alignment-center-left section3items">
                <img src="resources/images/carrer.png" class="imgSquare">
                <p>Gestion des factures</p>
              </div>
    
              <div class="md-layout-item md-layout md-size-100 md-alignment-center-left section3items">
                <img src="resources/images/carrer.png" class="imgSquare">
                <p>Gestion des notes</p>
              </div>
            </div>
          </div>
      </div>

      </div>
    </section>

    <!--section4 Wx3Data warranted-->
    <section style="position: relative;  overflow: hidden;">
      <div>
        <img src="resources/images/portable.png" class="section4ImgLaptop">
        <h3 class="section4Text">
          WX<span class="section4TextSpan">3</span>data s'assure de vous donner la formation 
          essentielle afin que vous soyez complètement autonome avec tout vos outils informatiques.
        </h3>
        <img src="resources/images/carrer.png" class="section4ImgSquare">
      </div>
    </section>

    <!--section5 Text / Img Bottom-->
    <section>
      <div class="md-layout">
        <!--text-->
        <div class="md-layout-item md-layout">
          <div class="md-layout-item md-layout md-alignment-center-center md-medium-size-100 md-size-75 section5Text">
              <h3 class="md-layout-item md-size-100 f-primary section5TextSlogan1">Un prix qui <br> s'adapte à votre utilisation</h3>
              <h3 class="md-layout-item md-size-100 section5TextSlogan2">Un outil gratuit <br>pour les petites utilisations !</h3>
              <h3 class="md-layout-item md-size-100 f-secondary section5TextSlogan3">Parfait pour démarrer votre entreprise</h3>
              <!--*-->
              <p class="section5TextNotice">*L'outil est gratuit sous certaine condition</p>
              <div class="md-layout-item md-size-100" style="height: 50px; text-align: center; font-family: serif;">
                <a class="btnCookie" href="/www.html#/OffreDemarrageEntreprise#form" >Démarrer maintenant !</a>
              </div>
          </div>
          
        </div>
        <!-- photo-->
        <div class="md-layout-item md-size-25 md-medium-hide" style="position:relative;">
          <div class="photoBottom"></div>
        </div>
      </div>
    </section>

    <!--Footer-->
    <footer class="footer">
      <div class="bgBannerFooter">
        <div class="c-tierce-light-3 grid-pad">

          <div class="md-layout md-alignment-center footerLogoSpacing">
            <img src="resources/images/LogoCompletAbstracxion.png" class="footerLogo">
          </div>

          <div class="md-layout">
           <div class="md-layout-item md-small-size-100 md-layout md-alignment-center-left">
              <div id="app"><a class="footerLink" href="./app.html">Accès Client</a></div>
            </div>
            <div class="md-layout-item md-small-size-100 md-layout md-alignment-center-center">
             <p class="footerText">Un problème ? support@abstracxion.com</p>
            </div>
            <div class="md-layout-item md-small-size-100 md-layout md-alignment-center-right">
              <img src="resources/images/facebook.png" class="footerFacebook"> <!--TODO have to change the logo in the corner-->
            </div>
          </div>
          <div class="md-layout md-small-size-100">
            <div class="md-layout-item"></div>
            <div class="md-layout-item"></div>
            <div class="md-layout-item">
              <div class="footerCookieLink flt-r"><p onclick="$authorizationCookies.navigateToCookie()" class="txt-r">Mes témoins</p></div>
              <div class="footerCookieLink flt-r"><p onclick="$authorizationCookies.navigateToPolicy()" class="txt-r">Politique de confidentialité</p></div>
            </div>
          </div>

        </div>
      </div>
    </footer>
    <!-- built files will be auto injected -->
        </body>

    </html>
</template>
<style scoped>
    .menuLogo {height: 75px !important; max-width: 200px;}
    .menuTitle {font-size: clamp(2.5em, 2vw, 3em); font-weight: 700;}
    .menuLink {font-size: clamp(1.5em, 2vw, 2em);}
    .phoneMenu { text-align: center; font-size: clamp(1em,2vw,1em); color:rgb(var(--primary)) !important; font-family: sans-serif; }
    .phoneMenu:hover{ color:rgb(var(--secondary))!important; text-decoration: none; } 

    .grid-pad {padding: 0px 40px 0px 40px;}
    .header {position:fixed; top: 0px; height: 100px;  width: 100%; box-shadow: 0px 0px 22px 9px rgb(var(--primary)); z-index: 1000; text-transform: uppercase; background-color: white;}
    .footer {position:relative; bottom: 0px ; height: 470px; width: 100%; padding:auto 0 auto 0; line-height: 29.26px;}
    .footerLink {font-size: 32px; text-transform: uppercase; color: black;  margin-left:15px; margin-bottom:20px !important;}
    .footerText {font-size: 32px; padding-bottom:10px;}
    .section2textTitle {font-size:48px; text-align: center; text-transform: uppercase; line-height: 72px; }
    .section2textTitle-64 {font-size:64px; font-weight: 400;}
    .section2Text {font-size:24px; line-height:29.26px; font-family: "Montserrat, sans-serif;"; text-align: justify; margin: 0 0 50px 0}
    .section2TextSpacing {padding: 0px 50px;}
    .section3Style {position: relative; color:white; text-transform:uppercase;  z-index:9; padding-bottom:100px; padding-top:60px;}
    .sectionGif { position: absolute; width: 100%; height: 100%; background-clip: url(/resources/images/square.gif); }
    .section3TextPar {font-size:24px; line-height:29.26px; font-family: "Montserrat, sans-serif;"; text-align: justify;}
    .section3Title {font-size:48px; margin:20px 0px; font-weight: 600; line-height: 58.12px;}
    .section3ParStyle {margin:50px 100px 0px 100px;}
    .section3items{margin: 50px 0px 0px 0px; padding: 0px 0px 0px 100px;}
    .section4Text {font-size: 46px ;line-height: 58.12px; font-weight: 600; text-align: center; padding:50px 154px 110px 224px ; text-transform: uppercase; z-index:1;}
    .section4TextSpan {font-family: "Montserrat, sans-serif;"; font-weight: 400;}
    .section5Text {text-transform: uppercase; text-align: center;}
    .section5TextSlogan1 {font-size: 36px; font-weight: 400; line-height: 43.6px; padding: 80px 0 0 0;}
    .section5TextSlogan2 {font-size: 48px; font-weight: 700; line-height: 58.13px;}
    .section5TextSlogan3 {font-size: 36px; font-weight: 400; line-height: 58.13px;}
    .section5TextNotice {font-size: 16px; font-weight: 400; margin: -20px 0 40px 0;}
  
    .fw-300 {font-weight: 300;}
    .fw-400 {font-weight: 400;}
    .fw-700 {font-weight: 700;}
    
    h1 {font-size:48px;}
    h2 {font-size:52px;}
    p {font-size:24px;}
    a:hover {text-decoration: none !important; color: rgba(var(--secondary)) !important;}

    .bgBannerTop { margin-top: 100px; width: 100%; max-height: 700px; object-fit: contain; position: relative;  top: 0; left: 0;}
    .abstractBG {background-image: url("/resources/images/bgABS.jpg"); background-size:cover; background-position: center center; background-repeat: no-repeat;}

    .bgBannerTop {background-image: url("/resources/images/BannerIndexTop.jpg"); background-size:cover ; background-repeat: no-repeat; background-position-y: -510px;}
    .bgBannerFooter {background-image: url("/resources/images/BannerIndexFooter.jpg"); background-position: center top; background-size: cover; background-repeat: no-repeat;}
    
    .mb-50 {margin-bottom:50px;}
  
    .imgSquare {height:83px !important;}
    .section4ImgLaptop {position:absolute; top:40px; left:-60px; height:400px !important; opacity: 30%; z-index:-1;}
    .section4ImgSquare {position:absolute; top:-100px; right:-90px; height:400px !important ; opacity: 30%; rotate:30deg; z-index:-1;}
    
    .photoTop {background-image: url("/resources/images/photoIndexTop.jpg"); height: 800px; width:620px; top:20px; margin-left:0px; background-position: center center; background-size: cover; position:absolute; z-index:10}
    .photoBottom {background-image: url("/resources/images/photoIndexBottom.jpg"); z-index:100; height: 900px; width:640px; top:-100px; right:0px; background-position:center center; background-size: cover; position:absolute;}
  
    .footerLogo {height:200px !important;}
    .footerLogoSpacing {padding-top:98px; padding-bottom: 84px;}
    .footerFacebook {height:50px !important;}
    .border1 { border: 1px solid red;}
    .border2 { border: 1px solid green;}
    .border3 { border: 1px solid blueviolet;}
  
  
    .font-Montserrat {font-family: "Montserrat, sans-serif;";}
    
    #d6fd09d8-2026-4a67-892e-f81c852431cf { font-family: "CormorantGaramond" !important;}

    .categoryButton { padding: 20px; margin: -20px 0px 0px 0px; background-color: rgb(var(--secondary)); font-weight: 700; color: rgb(var(--primary)) !important; }
    .categoryButton:hover { color:white !important; cursor: pointer; }
    
    /*#region cookie*/
      .cookiePad {padding: 10px !important;}
      .cookieTextPad {padding: 20px 100px !important;}
      .cookieTitle {font-family: Gwendolyn; font-weight: 700; font-size: 64px !important; height: 64px; padding: 15px 10px !important; text-align: center;}
      .cookiePersionalisationTitle { font-size: 24px; padding: 25px !important; font-family: Inter;}
      .cookiePersionalisationTexte{ font-size: 14px; padding: 10px 50px !important; font-family: Inter;}
      .cookieText {padding: 0px 200px !important;}
      .cookiePersonaliseInfo {padding: 0px 50px !important;}
      .cookieLink { color: rgb(var(--primary)) !important; text-decoration: none !important; cursor: pointer;}
      .cookieLink:hover { font-weight: 700; }
      .footerCookieLink { color: rgb(var(--primary)) !important; text-decoration: none !important; cursor: pointer; display: inline-block; padding: 0px 10px !important;}
      .footerCookieLink:hover { font-weight: 700; }
      .btnCookie {padding: 10px !important; text-transform:uppercase; margin: 5px !important; border: 1px solid rgb(var(--primary)); color: rgb(var(--primary)) !important; cursor: pointer; border-radius: 5px;}
      .btnCookie:hover { background-color: rgb(var(--primary)) !important; color: rgb(var(--secondary)) !important; }
      .overlayCookie { display: none; position: absolute; width: 100%; height: 100%; z-index: 10000; background-color: rgba(000,000,000,0.2); }
      .cookieBox { display: none; position: fixed; bottom: 0; width: 100%; height: auto; background-color: white;}
      .cookiePersonalise { display: none; overflow-y:scroll; position: fixed; width: auto; max-width: 75%; left: 50%; transform: translate(-50%, 0); height: auto; max-height:100%; background-color: white;}
      .switchContainer {height: 30px;}
      .switch {display: inline-block; width: 50px; height: 20px; background-color: #ccc; border-radius: 15px; position: relative; float: right; cursor: pointer; }
  
      /* Styles pour la poignée (slider) */
      .slider { width: 20px; height: 20px; background-color: black; border-radius: 50%; position: absolute; transition: 0.2s; }
  
      /* Position de la poignée lorsque le v-switch est désactivé */
      .switch.off .slider { transform: translateX(0); }
      /* Position de la poignée lorsque le v-switch est activé */
      .switch.on {background-color: rgba(var(--primary),0.2);}
      .switch.on .slider { background-color: rgb(var(--primary)); transform: translateX(30px);}
      .flt-r {float: right;}
    /*#endregion */
  
  
    @font-face {
      font-family: "CormorantGaramond";
      src:url("/resources/Fonts/CormorantGaramond-Regular.ttf");
      font-weight: 400;
    }
  
    @font-face {
      font-family: "CormorantGaramond";
      src:url("/resources/Fonts/CormorantGaramond-Light.ttf");
      font-weight: 300;
    }
  
    @font-face {
      font-family: "CormorantGaramond";
      src:url("/resources/Fonts/CormorantGaramond-Bold.ttf");
      font-weight: 700;
    }
  
    @font-face {
      font-family: "CormorantGaramond";
      src:url("/resources/Fonts/CormorantGaramond-Medium.ttf");
      font-weight: 500;
    }
  
    @font-face {
      font-family: "CormorantGaramond";
      src:url("/resources/Fonts/CormorantGaramond-SemiBold.ttf");
      font-weight: 600;
    }
  
    @media handheld, only screen and (max-width: 600px) {
      .menuLogo {height: 50px !important; }
      .phoneMenu { text-align: left; }
    }
    @media handheld, only screen and (max-width: 960px) {
      h1{font-size: 24px;}
      .grid-pad {padding: 0px 10px;}    
      .header {height: auto;}
      #contenu .grid-pad {padding: 0px 10px;}
      .cookieText {padding: 0px 10px !important;}
      .cookieTextPad {padding: 0px 10px !important;}
      .cookiePersionalisationTitle { padding: 0px 5px !important;}
      .cookiePersionalisationTexte{ padding: 0px 5px !important; }
      .cookiePersonalise { max-width: 100%; left: 0%; transform: translate(0%, 0); height: 100%; padding: 0px 10px;}
      .cookiePersonaliseInfo {padding: 0px 10px !important;}
      .bgBannerTop{background-position-y : 0px !important;}
      .section2TextSpacing {padding:0px; }
      .section2textTitle {font-size: 36px; line-height: normal;}
      .section2textTitle-64 {font-size: 36px; line-height: normal;}
      .section3ParStyle { margin: 10px 10px 0px 10px; }
      .section3items { margin: 10px 0px 0px 0px; padding: 0px 10px 0px 10px; }
      .section3items p { font-size: 12px; }
      .section3Title { font-size: 24px; margin: 0px; font-weight: 600; line-height: 58.12px; text-align: center; }
      .imgSquare { height: 25px !important; }
      p { font-size: 18px; }
      .section4Text { font-size: 24px; padding: 0px; }
      .section5TextSlogan2 { font-size: 24px; }
      .section5TextSlogan3 { font-size: 24px; }
      .Title { display: none;}
    }
    @media handheld, only screen and (max-width: 1264px) {
      .section3items{margin: 50px 0px 0px 0px; padding: 0px 10px;}
      .section3items p { font-size: 12px; }
    } /*Need to change the footer*/
    @media handheld, only screen and (max-width: 1424px) { 
      .photoTop {height: 850px; width:620px; top:-172px; margin-left:0px; }
      .section3items p { font-size: 14px !important; }
      .photoBottom {height: 800px; width:540px;}
    } /*title top section*/
    @media handheld, only screen and (max-width: 1525px) {
      .section3items p { font-size: 18px; }
    } /* image bottom*/

</style>
<script>
    import cHeader from '@/www/components/website-header.vue';
    import cFooter from '@/www/components/website-footer.vue';

    export default {
        name: 'Home',
        data: () => ({ 
            item: null
        }),
        computed: { },
        mounted: async function () {
            $authorizationCookies.checkauthorization();
        },

        methods: {
            login: async function () {

                let api = 'https://abstracxion.wx3data.com/security/login/WX3'; // need Tenant BissonService 
                let data = null;
                try {
                    let response = await fetch(api, {
                        method: 'PUT', // *GET, POST, PUT, DELETE, etc.
                        mode: 'cors', // no-cors, *cors, same-origin
                        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                        credentials: 'same-origin', // include, *same-origin, omit
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        redirect: 'follow', // manual, *follow, error
                        referrerPolicy: 'no-referrer', // no-referrer, *client
                        body: JSON.stringify({LoginName: "", password: ""}) // body data type must match "Content-Type" header
                    })
                    if (await response.ok) {
                        if (await response.status !== 204) {
                            let data = await response.text();
                            return data
                        }
                    } else {
                        let msg = await response.text();
                        $App.event.$emit("app_error", msg);
                    };
                        
                } catch (error) {
                    console.log(error);
                }
            }
        },
        components: {
            "cHeader": cHeader,
            "cFooter": cFooter,
        }
    }
  </script>