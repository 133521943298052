<template>
    <div>
        <v-app>
            <v-main >
                <cHeader @sendFormHeader="animateEasing" />
                <!--Title-->
                <div class="md-layout">
                    <div class="md-layout-item titleBGContainerWrap">
                        <div class="md-layout-item titleBGContainer">
                            <img class="titleBG" src="../../../public/resources/images/OfferBusiness/titleBG.jpg" alt="">
                            <h1 class="title">Démarrage d'Entreprise avec Abstracxion</h1>
                        </div>
                    </div>

                </div>
                <!--textAdventure-->
                <div class="md-layout">
                    <div class="md-layout-item grid-pad">
                        <h2 class="titleAdventure">Démarrez votre aventure entrepreneuriale avec confiance et tranquillité d'esprit, avec un partenaire de confiance à vos côtés.</h2>
                        <p class="textAdventure">
                            Vous rêvez de démarrer votre propre entreprise, mais vous ne savez pas par où commencer ? Ne vous inquiétez plus ! Avec 25 ans d'expérience dans l'entrepreneuriat, notre offre de démarrage d'entreprise est spécialement conçue pour vous accompagner à chaque étape de votre aventure entrepreneuriale. Que vous ayez une idée brillante ou que vous soyez prêt à transformer votre passion en une entreprise florissante, nous vous fournissons tous les outils et services nécessaires pour lancer votre projet avec succès.
                            <br><br>
                            Avec notre expertise et notre soutien, vous pouvez aborder chaque défi avec confiance. De la gestion client à la création de votre site web, en passant par le développement de votre identité visuelle, nous vous aidons à poser des bases solides pour votre entreprise. Nous croyons en votre potentiel et nous sommes déterminés à vous voir réussir.
                            <br><br>
                            Notre objectif est de vous permettre de vous concentrer pleinement sur ce que vous aimez faire et sur ce que vous faites le mieux, sans vous soucier des aspects techniques et administratifs. En nous confiant ces tâches, vous pouvez diriger toute votre énergie et votre créativité vers le développement de votre entreprise et la réalisation de vos ambitions.
                            <br><br>
                            Ne laissez pas les obstacles vous freiner. Commencez votre parcours entrepreneurial avec nous et transformez votre vision en réalité. Ensemble, faisons de votre rêve une réussite concrète.
                        </p>
                        <div class="md-layout md-size-100">
                            <cite class="subtitleAdventure">La <i>Federation of Small Businesses</i> indique que 70% des petites entreprises bénéficiant d'un accompagnement structuré, tel que le mentorat ou le coaching d'affaires, survivent pendant cinq ans ou plus. Ce taux de survie est le double de celui des entrepreneurs qui ne bénéficient pas de ce type d'accompagnement.</cite>
                        </div>
                    </div>
                </div>
                <!--Offre-->
                <div class="md-layout" style="overflow: hidden;">
                    <div class="md-layout-item">
                        <div class="arrowOfferDesktop bgOfferWrap" >
                            <div class="bgOffer outer">
                                <div class="bgOfferInner">
                                    <div class="bgOffer">
                                        <div style="position: absolute; top:60%; left: 50%; transform: translate(-50%,-50%); text-align: center; font-family: serif; color: white;">
                                            <h2 class="offerTitle">l’offre comprend :</h2>
                                            <md-button class="categoryButton" @click="animateEasing" >Démarrer maintenant !</md-button>
                                        </div>
                                        
                                        <div>
                                            <div class="positionTool">
                                                <img class="bgPartTool" src="../../../public/resources/images/OfferBusiness/toolBG.jpg" alt="">
                                                <div class="coloredParts"> <h2 class="titleTool">outils&nbsp;de&nbsp;gestion</h2> </div> 
                                            </div>
                                            <div class="positionLogo">
                                                <img class="bgPartLogo" src="../../../public/resources/images/OfferBusiness/logoBG.jpg" alt="">
                                                <div class="coloredParts"> <h2 class="titleLogo">Logo</h2> </div> 
                                            </div>
                                            <div class="positionCard">
                                                <img class="bgPartCard" src="../../../public/resources/images/OfferBusiness/cardBG.jpg" alt="">
                                                <div class="coloredRigthParts"> <h2 class="titleCard">carte&nbsp;d'affaire</h2> </div> 
                                            </div>
                                            <div class="positionWeb">
                                                <img class="bgPartWeb" src="../../../public/resources/images/OfferBusiness/webBG.jpg" alt="">
                                                <div class="coloredRigthParts"> <h2 class="titleWeb">Page&nbsp;web</h2> </div> 
                                            </div>
                                        </div>
                                       
                                    </div>                                    
                                </div>
                            </div>
                            
                        </div>
                        <div class="arrowOfferMobile">
                            <div style="position: relative; text-align: center; font-family: serif;">
                                <h2 class="offerTitle">l’offre comprend :</h2>
                            </div>
                            <div class="md-layout">
                                <div class="md-layout-item md-size-50 ItemOfferWrap colorSecOfferWrap">
                                    <h2 class="titleOfferMobile">outils&nbsp;de&nbsp;gestion</h2>
                                    <img class="imgBgOfferMobile" src="../../../public/resources/images/OfferBusiness/toolBG.jpg" alt="">
                                </div>
                                <div class="md-layout-item md-size-50 ItemOfferWrap colorPriOfferWrap">
                                    <h2 class="titleOfferMobile">Logo</h2>
                                    <img class="imgBgOfferMobile" src="../../../public/resources/images/OfferBusiness/logoBG.jpg" alt="">
                                </div>
                                <div class="md-layout-item md-size-50 ItemOfferWrap colorPriOfferWrap">
                                    <h2 class="titleOfferMobile">carte&nbsp;d'affaire</h2>
                                    <img class="imgBgOfferMobile" src="../../../public/resources/images/OfferBusiness/cardBG.jpg" alt="">
                                </div>
                                <div class="md-layout-item md-size-50 ItemOfferWrap colorSecOfferWrap">
                                    <h2 class="titleOfferMobile">Page&nbsp;web</h2>
                                    <img class="imgBgOfferMobile" src="../../../public/resources/images/OfferBusiness/webBG.jpg" alt="">
                                </div>
                            </div>
                            <div style="position: relative; text-align: center; font-family: serif;">
                                <md-button class="categoryButton" @click="animateEasing" >Démarrer maintenant !</md-button>
                            </div>
                        </div>
                    </div>
                </div>
                <!--descriptionOffre-->
                <div class="md-layout">
                    <div class="md-layout-item md-size-100">
                        <div class="md-layout grid-pad DecriptionOfferFont">
                            <h2 class="md-layout-item md-size-100 txt-c descriptionTitle">Description de l’offre</h2>
                            <div class="md-layout-item md-size-100">
                                <h1 class=""><span class="f-secondary">A</span>BSTRAC<span class="f-primary">X</span>ION</h1>
                                <p class="descriptionOfferSubTitle">
                                    Découvrez notre outil de gestion, un outil tout-en-un sécurisé, simple, efficace et même gratuit* pour votre entreprise ! Gérez vos clients en toute facilité, suivez vos produits avec précision, et simplifiez votre commerce en ligne. Notre outil de gestion inclut également des fonctionnalités comptables simples telles que la création de factures, le suivi des paiements et des encaissements. Profitez d'une solution intégrée qui répond à tous vos besoins commerciaux. Essayez-le dès aujourd'hui et transformez la gestion de votre entreprise! 
                                </p>
                                <p class="descriptionOfferSubTitle">
                                    AbstracXion vous permet d'ajouter à tout moment, différents modules personnalisés selon les besoins de votre entreprise.<br>
                                    <span style="font-size: small;">* Conditions s'appliquent</span>
                                </p>
                            </div>
                            <div class="md-layout-item md-layout md-size-100">
                                <div class="md-layout-item md-small-size-100" style="position: relative;">
                                    <video class="videoAbstracxion bgBannerTop" controls>
                                        <source src="resources/videos/WX3PubOfficielV2.mp4" type="video/mp4" />
                                        Votre navigateur ne supporte pas la balise vidéo.
                                    </video>
                                </div>
                                <div class="md-layout-item md-small-size-100 descriptionOfferText " style="padding: 25px;">
                                    <h3 class="f-secondary">Gestion client</h3>
                                    <p>Notre solution de gestion clients centralise toutes les données sur une plateforme unique, simplifiant la gestion, réduisant les erreurs et accélérant l'accès aux informations essentielles. Cela permet à votre équipe de se concentrer sur des tâches stratégiques et d'améliorer la satisfaction client.</p>
                                    <h3 class="f-primary">Gestion de produit</h3>
                                    <p>Centralisez vos opérations comptables avec notre solution simple. Gérez la facturation, l'encaissement et les paiements en ligne facilement. Réduisez les erreurs et gagnez du temps.</p>
                                    <h3 class="f-secondary">Soutien à la comptabilité</h3>
                                    <p>Centralisez les informations de vos produits pour une gestion simplifiée. Suivez l'inventaire, réduisez les erreurs et accédez rapidement aux données essentielles. Optimisez votre stock et améliorez la disponibilité des produits pour vos clients.</p>
                                    <h3 class="f-primary">Création de page web</h3>
                                    <p>Créez une page web professionnelle que vous pouvez modifier facilement, sans compétences techniques. Mettez à jour le contenu et le design de votre site à tout moment grâce à une interface intuitive, assurant que votre site reste à jour et attrayant pour vos visiteurs.</p>
                                    <h3 class="f-secondary">E-commerce sur Mesure</h3>
                                    <p>Gérez facilement votre boutique en ligne avec notre solution sur mesure, adaptée à vos besoins spécifiques. Profitez d'une interface intuitive pour gérer produits, commandes et paiements, tout en personnalisant votre site pour refléter votre marque.</p>
                                </div>
                            </div>
                            <div class="md-layout-item md-size-100 spaceForCategory">
                                <div class="md-layout md-size-100">
                                    <div class="md-layout-item md-small-size-100 md-medium-size-33 blocCategory">
                                        <div class="md-layout descriptionOfferCategory">
                                            <div class="md-layout-item md-size-100"> <h3>Logo</h3></div>
                                        </div>
                                        <p>Un logo est plus qu'une simple image ; c'est le visage de votre entreprise. Nous nous engageons à concevoir un logo durable, simple et qui imprègne profondément l'identité de votre entreprise. Notre équipe de designers expérimentés collabore étroitement avec vous pour comprendre vos valeurs, votre vision et votre marché cible. Ensemble, nous créons un design qui capture l'essence de votre marque et la rend instantanément reconnaissable. Un bon logo doit être intemporel, facilement identifiable et capable de s'intégrer parfaitement dans tous vos supports marketing.</p>
                                    </div>
                                    <div class="md-layout-item md-small-size-100 md-medium-size-33 blocCategory">
                                        <div class="md-layout descriptionOfferCategory">
                                            <div class="md-layout-item md-size-100"> <h3 >Carte d’affaire</h3></div>
                                        </div>
                                        <p>Présentez-vous avec style et professionnalisme grâce à des cartes d'affaires conçues pour laisser une impression durable. Nos cartes standard, imprimées sur des matériaux de haute qualité, offrent une apparence professionnelle et une longue durée de vie, tandis que nos cartes NFC ajoutent une touche innovante avec une technologie sans contact, permettant à vos contacts d'accéder instantanément à vos informations. Élégantes et fonctionnelles, nos cartes sont conçues pour être des ambassadeurs de votre marque, combinant durabilité et universalité pour toutes les occasions.</p>
                                    </div>
                                    <div class="md-layout-item md-small-size-100 md-medium-size-33 blocCategory">
                                        <div class="md-layout descriptionOfferCategory">
                                            <div class="md-layout-item md-size-100"> <h3>Page internet</h3></div>
                                        </div>
                                        <p>Grâce à l'outil Abstracxion, votre page web reste à jour et pertinente, évoluant avec votre entreprise. En tant que vitrine en ligne, cette page permet de présenter votre entreprise, vos produits ou services, et vos informations de contact de manière claire et attractive. Avec Abstracxion, vous avez la possibilité de personnaliser votre page web selon vos besoins, en ajoutant facilement du contenu, des images et des mises à jour. Une page web bien entretenue et modifiable est essentielle pour renforcer votre visibilité en ligne et offrir une première impression positive aux visiteurs et potentiels clients.</p>
                                    </div>
                                </div>
                                <div class="md-layout md-size-100">
                                    <md-button class="categoryButton" @click="animateEasing">Commencer  maintenant !</md-button>
                                </div>
                                <div class="md-layout md-size-100">
                                    <cite class="descriptionOfferStats">84% des dirigeants accompagnés déclarent avoir évité des erreurs coûteuses grâce aux conseils reçus selon Harvard Business Review</cite>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--whyUs-->
                <div class="md-layout">
                    <div class="md-layout-item md-size-100">
                        <div class="whyUsWrap">
                            <div class="whyUsBG">
                                <div class="md-layout whyUsContent">
                                    <div class="md-layout-item grid-pad">
                                        <img class="bgWhyUs" src="../../../public/resources/images/OfferBusiness/bgWhyUs.jpg" alt="Image d'ambiance pour le démarrage entreprise abstracxion">
                                        <h2>Pourquoi nous choisir ?</h2>
                                        <h3 class="spacingWhyUs">Expertise et Expérience</h3>
                                        <p>Notre équipe possède une vaste expérience dans l'accompagnement des nouvelles entreprises. Spécialiste d’internet et de programmation depuis 26 ans, nous personnalisons vos services informatiques et de graphisme pour répondre précisément à vos besoins. En tant que votre partenaire d’affaires par excellence, nous nous engageons à être la référence pour tous vos projets, qu'il s'agisse de création de sites web, de gestion de données ou d'optimisation de votre présence en ligne. WX3data n'est pas seulement un fournisseur de services, mais le conseiller et le réalisateur de toutes vos idées. Avec une expertise reconnue et  fournir des services  de qualité, nous nous efforçons de donner à votre entreprise la grandeur qu'elle mérite. Ensemble, atteignons des sommets et transformons vos visions en réalité.</p>
                                        <h3>Support Continu</h3>
                                        <p>Notre engagement ne s'arrête pas à la fourniture d'outils performants ; nous vous accompagnons à chaque étape de votre parcours entrepreneurial. Avec 26 ans d'expérience dans le soutien des projets informatiques, nous comprenons les défis uniques auxquels vous pouvez être confronté. C'est pourquoi nous offrons un support continu et personnalisé, adapté à vos besoins spécifiques. Que ce soit pour des questions techniques, des conseils stratégiques ou une assistance en temps réel, notre équipe est toujours disponible pour vous aider. Nous nous assurons que vous ne soyez jamais seul face aux défis technologiques, et nous travaillons à vos côtés pour la réussite de votre entreprise. Notre objectif est de construire une relation de confiance durable, en devenant un véritable partenaire dans votre succès. Avec nous, vous bénéficiez non seulement de solutions de qualité, mais aussi d'un soutien constant qui vous permet de vous concentrer sur ce qui compte vraiment : faire croître votre entreprise.</p>
                                        <h3>Partenariat de Longue Durée</h3>
                                        <p>Nous nous engageons à être un partenaire à long terme pour votre entreprise, en offrant bien plus que des services initiaux. Après avoir commencé avec notre offre, nous sommes là pour vous accompagner dans tous les aspects de votre croissance, y compris le marketing, la publicité (Google et Facebook), le graphisme, la création de sites internet personnalisés, le développement de plateformes e-commerce sur mesure, et l'accompagnement entrepreneurial. Nous proposons également des solutions logicielles entièrement personnalisées pour répondre aux besoins spécifiques de votre entreprise. Notre mission est de vous soutenir à chaque étape, en vous offrant les ressources et l'expertise nécessaires pour assurer la réussite et la croissance continue de votre entreprise.</p>
                                        <div class="mobileBtn"  style="position: relative; text-align: center; font-family: serif;">
                                            <md-button class="categoryButton" @click="animateEasing" >Démarrer maintenant !</md-button>
                                        </div>
                                        <h4>un partenariat d'affaires sincère et durable, rien de moins !</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--testimony-->
                <div class="md-layout testimony">
                    <img class="bgTestimony" src="../../../public/resources/images/OfferBusiness/logoSP360.png" alt="logo de l'entreprise service prestige 360 pour un témoignage d'une offre démarrage d'entreprise.">
                    <div class="md-layout-item md-size-100">
                        <h2>Témoignage</h2>
                        <img class="logoImg" src="../../../public/resources/images/OfferBusiness/logoNoir.png" alt="logo de l'entreprise service prestige 360 pour un témoignage d'une offre démarrage d'entreprise.">
                        <div class="md-layout grid-pad">
                            <div class="md-layout-item md-medium-size-100 md-large-size-50" style="position: relative;" >
                                <ul class="ulTestimony">
                                    <li><h3>Logo et carte d'affaire</h3></li>
                                    <li><h3>Outil de gestion</h3></li>
                                    <li><h3>Présence en ligne</h3></li>
                                    <li>
                                        <h3>Article promotionnel</h3>
                                        <ul>
                                            <li>Chandail, aimant auto, pancarte extérieur</li>
                                        </ul>
                                    </li>
                                    <li><h3>Publicité google</h3></li>
                                    <li><h3>Accomapgnement médias sociaux</h3></li>
                                </ul>
                            </div>
                            <div class="md-layout-item md-medium-size-100 md-large-size-50">
                                <div class="md-layout  testimonyText">
                                    <h3 class="md-layout-item md-size-100">Témoignage Inspirant : Transformez Votre Entreprise avec Notre Accompagnement</h3>
                                    <p class="md-layout-item md-size-100">Lorsque Service Prestige 360 est venu nous voir, ils faisaient face à plusieurs défis : un site internet peu performant, une publicité inefficace, et l'absence d'un logo représentatif. Leur identité visuelle manquait de cohérence, et ils n'avaient aucun outil pour gérer leurs clients et leur facturation.</p>
                                    <p class="md-layout-item md-size-100">Nous avons pris le temps de comprendre leur situation et de collaborer étroitement avec eux pour redéfinir leur image de marque. Ensemble, nous avons trouvé un nom d'entreprise qui reflète mieux leur vision et avons entrepris les actions suivantes pour les aider :</p>
                                    <ul class="md-layout-item md-size-100">
                                        <li>Création d'un <span class="bold">logo</span> polyvalent et professionnel</li>
                                        <li>Conception de <span class="bold">cartes d'affaires</span> élégantes</li>
                                        <li>Mise en place d'un <span class="bold">outil de gestion</span><e>*</e> pour faciliter la gestion des clients et de la facturation</li>
                                        <li>Développement d'une <span class="bold">page internet</span> moderne et attrayante</li>
                                        <li>Création d'articles promotionnels de qualité, comme des chandails et des aimants auto</li>
                                        <li>Lancement de campagnes de publicité Google</li>
                                        <li><span class="bold">Accompagnement</span> en entrepreneuriat et marketing pour renforcer leur stratégie</li>
                                    </ul>
                                    <p class="md-layout-item md-size-100">Grâce à notre soutien personnalisé, Service Prestige 360 peut maintenant se concentrer pleinement sur son cœur de métier, offrant ainsi un service exceptionnel à ses clients. Leur succès est visible : ils ont réussi à engager un employé à temps plein et un autre à temps partiel.</p>
                                    <p class="md-layout-item md-size-100"><span class="bold">Nous sommes fiers de notre franchise et de notre accompagnement personnalisé, adaptés aux besoins uniques de chaque entreprise.</span> Faites-nous confiance pour vous aider à vous concentrer sur l'essentiel : votre entreprise et son succès !</p>
                                    <p class="md-layout-item md-size-100" style="text-align: right;"><e>*</e> offert sous certaines conditions</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--ContactUs-->
                <div id="form" ref="formPosition" class="md-layout contactUs">
                    <div class="md-layout-item md-size-100">
                        <div class="contactUsWrap">
                            <div class="contactUsColorBG">
                                <img class="contactUsBg" src="../../../public/resources/images/OfferBusiness/bgContactUs.jpg" alt="Image d'ambiance pour le démarrage entreprise abstracxion">
                                <div class="md-layout contactUsContent grid-pad">

                                    <h2 class="md-layout-item md-size-100">Contactez-nous dès Aujourd'hui</h2>
                                    <p class="md-layout-item md-size-100">Prêt à transformer votre idée en une entreprise florissante ? Contactez-nous dès aujourd'hui et découvrez comment nous pouvons vous aider à atteindre vos objectifs d'affaires. Cette offre est spécialement conçue pour les entrepreneurs ambitieux et déterminés à réussir. Ensemble, construisons quelque chose de formidable ! Saviez-vous que un rapport de PricewaterhouseCoopers (PwC) a révélé que 80 % des coentreprises et alliances stratégiques (qui impliquent souvent des partenariats) ont atteint ou dépassé leurs objectifs. Rejoignez-nous avec vos amis et commencez votre aventure entrepreneuriale avec assurance et sérénité ! </p>
                                    <h2 class="md-layout-item md-size-100" style="text-align: left;">Formulaire de contact</h2>
                                    <form ref="form" class="md-layout-item md-layout md-mt-5 md-alignment-top-center  md-gutter ">
                                        
                                        <md-field class="md-layout-item md-size-45">
                                            <label for="name">Nom<p v-if="this.error.name" style="font-size: small; color: red;">obligatoire</p></label>
                                            <md-input style="width: 100%;" v-model="item.name" id="name" name="name" type="text" required />
                                            
                                        </md-field>
                                        <md-field class="md-layout-item md-size-45">
                                            <label for="phone">Téléphone<p v-if="this.error.phone" style="font-size: small; color: red;">obligatoire</p></label>
                                            <md-input v-model.number="item.phone"  id="phone" name="phone" type="text" required />
                                        </md-field>
                                        <md-field class="md-layout-item md-size-45">
                                            <label for="Message">Message </label>
                                            <md-input v-model="item.Message" id="Message" name="Message" type="text" />
                                        </md-field>                              
                                        <md-field class="md-layout-item md-size-45">
                                            <label for="Email">Courriel<p v-if="this.error.Email" style="font-size: small; color: red;">obligatoire</p></label>
                                            <md-input v-model="item.Email" id="Email" name="Email" type="text" required />
                                        </md-field>
                                        <div class="md-layout-item md-size-100 md-mt-5"><md-button @click="SendForm" class="contactUsButton">envoyée</md-button></div>
                                        <h2 v-if="this.offerSendForm == 'pending'">Votre demande est en traitement.</h2>
                                        <h2 v-if="this.offerSendForm == 'Sent'">Votre demande est envoyée.</h2>
                                        <h2 v-if="this.offerSendForm == 'error'">Une erreur est arriver, veuillez recommencer s'il vous plaît.</h2>
                                    </form>
                                    <div class="md-layout-item md-layout md-size-100 ">
                                        <h2 class="subtitleContactUs">
                                            Rejoignez-nous avec vos amis et commencez votre aventure entrepreneuriale avec assurance et sérénité ! Ensemble, nous pouvons transformer vos idées en succès durable.
                                        </h2>
                                    </div>  
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <cFooter />
            </v-main>
        </v-app>

    </div>    
</template>
<style scoped>
    .body { overflow: hidden; }
    .grid-pad { padding: 0px 40px; }
    .bold { font-weight: 700;}
    .mobileBtn { display: none; }
    /*#region title*/
        .titleBGContainerWrap { filter: drop-shadow(-1px 10px 10px rgba(var(--secondary), 0.5)); }
        .titleBGContainer { width: 100%;  height: auto; overflow: hidden; position: relative; }
        .titleBG { position: relative; width: 100%; top:-5px; max-height: 500px; object-fit: cover; clip-path: polygon(0 0, 100% 0, 100% 75%, 50% 100%, 50% 100%, 0 75%); z-index: 1; opacity: 0.8;  }
        .titleShaddow { position: absolute; z-index: 0; width: 100%; height: 500px; background-color: rgba(var(--secondary)); clip-path: polygon(0 0, 100% 0, 100% 75%, 50% 100%, 50% 100%, 0 75%);}
        .title { font-size: 4.5vw; position: absolute; z-index: 10; width: 100%; font-family: serif; text-align: center; top:45%; transform: translate(0%,-50%); text-shadow: 0px 4px 4px white;}
    /*#endregion */
    /*#region textAdventure*/
        .titleAdventure {text-align: center; font-family: serif; margin-top: 25px; font-size: clamp(1.5em,1.5vw,3em);}
        .textAdventure { text-align: justify; margin-top: 25px; font-size: clamp(0.75em,1.25vw,1.25em); }
        .subtitleAdventure {text-align: center; font-family: serif; margin: 25px 10%; font-size: clamp(1.5em,2vw,2.5em); line-height: normal; color: rgb(var(--secondary)); text-shadow: 1px 1px 2px black;}
    /*#endregion */

    /*#region OfferBusiness*/
        .bgOffer.outer { position: relative; background: black; }
        .bgOfferInner { /*filter: blur(8px);*/ }
        .bitten.outer:after { position: absolute; top: 0; left: 0; content: ''; }
        .bgOfferWrap { filter: drop-shadow(-1px 6px 3px rgba(0, 0, 0, 0.5));margin: 20px 0px; max-height: 600px;  }
        .bgOffer { box-shadow: inset 0px 0px 100px black; width: 100%; height: 500px; transition: height 0.5s ease;  background-color: rgba(var(--primary)); clip-path: polygon(0 0, 50% 25%, 100% 0%, 100% 75%, 50% 100%, 0 75%);}
        .bgOffer:hover { height: 600px; }
        .bgOffer:hover .bgPartTool, .bgOffer:hover .bgPartLogo, .bgOffer:hover .bgPartCard, .bgOffer:hover .bgPartWeb, .bgOffer:hover .coloredParts, .bgOffer:hover .coloredRigthParts { height: 600px; }
        .offerTitle { font-size: clamp(1.75em, 2vw, 2em); text-transform: uppercase; }
        .bgPartTool { position: absolute; z-index: 10; width: 12%; object-fit: cover; height: 500px; transition: height 0.5s ease;  background-color: rgba(var(--secondary)); clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);}
        .titleTool { position: absolute; top: 40%; left: 0%; transform: translate(-50%,-50%); transform: rotate(-81deg); width: 100%; height: auto; font-family: serif; color: white; font-size: 2em; text-transform: uppercase; }
        .positionTool { position: relative; left:5%; }
        .bgPartLogo { position: absolute;  z-index: 10; width: 12%; object-fit: cover; height: 500px; transition: height 0.5s ease;  background-color: rgba(var(--secondary)); clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);}
        .titleLogo { position: relative; top: 35%; left: 5%; transform: translate(-50%,-50%); transform: rotate(-81deg); width: 100%; height: auto; font-family: serif; color: white; font-size: 2em; text-transform: uppercase; }
        .positionLogo { position: relative; left:25%; }
        .bgPartCard { position: absolute; z-index: 10; width: 12%; object-fit: cover; height: 500px; transition: height 0.5s ease;  background-color: rgba(var(--secondary)); clip-path: polygon(0% 0%, 75% 0%, 100% 100%, 25% 100%);}
        .titleCard { position: absolute; top: 42%; left: 0%; transform: translate(-50%,-50%); transform: rotate(83deg); width: 100%; height: auto; font-family: serif; color: white; font-size: 2em; text-transform: uppercase; }
        .positionCard { position: relative; left:64%; }
        .bgPartWeb { position: absolute; z-index: 10; width: 12%; object-fit: cover; height: 500px; transition: height 0.5s ease;  background-color: rgba(var(--secondary)); clip-path: polygon(0% 0%, 75% 0%, 100% 100%, 25% 100%);}
        .titleWeb { position: absolute; top: 42%; left: 0%; transform: translate(-50%,-50%); transform: rotate(83deg); width: 100%; height: auto; font-family: serif; color: white; font-size: 2em; text-transform: uppercase; }
        .positionWeb { position: relative; left:83%; }
        .coloredParts { position: absolute; z-index: 10; width: 12%; object-fit: cover; height: 500px; transition: height 0.5s ease;  background-color: rgba(var(--secondary), 0.8); clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);}
        .coloredRigthParts { position: absolute; z-index: 10; width: 12%; object-fit: cover; height: 500px; transition: height 0.5s ease;  background-color: rgba(var(--secondary), 0.8); clip-path: polygon(0% 0%, 75% 0%, 100% 100%, 25% 100%);}
        /*#region mobility*/
            .ItemOfferWrap { position: relative; height: 200px; overflow: hidden; }
            .arrowOfferMobile {display: none;}
            .colorSecOfferWrap { position: relative; background-color: rgba(var(--secondary),0.8); }
            .colorPriOfferWrap { position: relative; background-color: rgba(var(--primary),0.8); }
            .titleOfferMobile { position: absolute; top:40%; left: 50%; transition: font-size 0.5s ease; transform: translate(-50%,-50%); color: white; font-family: serif; font-size: clamp(1.75em, 2vw, 2.25em); }
            .ItemOfferWrap:hover .titleOfferMobile { font-size: 2em; }
            .imgBgOfferMobile { position: absolute; z-index: -1; width: 100%; top:50%; left:50%; transform: translate(-50%,-50%);  }
        /*#endregion */
    /*#endregion */

    /*#region DecriptionOffer*/
        .descriptionTitle { font-size: clamp(2.25em, 2vw, 3em); }
        .DecriptionOfferFont { font-family: serif; background-image: url('../../../public/resources/images/OfferBusiness/lumiereIlluminer.png'); background-position: center; background-size: cover; background-repeat: no-repeat; }
        .DecriptionOfferFont p { font-size: clamp(1em, 2vw, 1.25em); }
        .descriptionOfferSubTitle { margin: 30px 0px 0px 0px; text-align: justify; }
        .descriptionOfferText { margin: 0px 0px 0px 0px; }
        .descriptionOfferText h3 { font-size: clamp(1.5em, 2vw, 1.75em); }
        .descriptionOfferText p { padding: 0px 0px 0px 20px; text-align: justify;}
        .videoAbstracxion { position: absolute; border: 15px solid rgb(var(--primary)); justify-content: center;  top:50%; left:50%; transform: translate(-50%,-50%); }
        .spaceForCategory { margin: 50px 0px 0px 0px; }
        .blocCategory { padding: 0px 20px; }
        .blocCategory p { text-align: justify; }
        .blocCategory h3 { position: absolute; width: 100%; text-align: center; top: 50%; left: 50%; transform: translate(-50%,-100%); color:white; font-size: clamp(1em, 2vw, 1.75em); transition: font-size 0.5s ease; text-transform: uppercase; }
        .descriptionOfferCategory { position: relative; box-shadow: inset 0px 0px 100px black; width: 100%; height: 200px;  transition: height 0.3s ease;  background-color: rgba(var(--secondary)); clip-path: polygon(0 0, 50% 25%, 100% 0%, 100% 75%, 50% 100%, 0 75%);}
        .descriptionOfferCategory:hover h3 { font-size: clamp(1.25em, 2vw, 2em);} 
        .categoryButton { margin: 20px auto; background-color: rgb(var(--secondary)); font-weight: 700; color: rgb(var(--primary)) !important; }
        .categoryButton:hover { color:white !important; }
        .descriptionOfferStats {text-align: center; font-family: serif; width: 100%; margin: 25px 20%; font-size: clamp(1.5em, 2vw, 2em); font-weight: 700; line-height: normal; color: rgb(var(--primary));}
    /*#endregion */

    /*#region WhyUs*/
        .whyUsWrap { filter: drop-shadow(-80px -0px 10px rgba(var(--secondary), 0.5));  }
        .whyUsBG { position: relative; box-shadow: inset 0px 0px 100px black; width: 100%; height: 1000px; transition: height 0.5s ease;  background-color: rgba(var(--primary)); clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 90%); }
        .bgWhyUs { position: absolute; object-fit: cover;  z-index: -1; top: 50%; left: 50%; transform: translate(-50%,-50%); opacity: 0.3;}
        .whyUsContent { position: absolute; width: 100%; top:50%; transform: translate(0%,-50%); text-align: right; color: white; font-family: serif;  }
        .whyUsContent h2 { font-size: clamp(1.5em, 2vw, 2.25em); text-transform: uppercase; } 
        .whyUsContent h3 { font-size: clamp(1.25em, 2vw, 1.75em); }
        .whyUsContent h4 { font-size: clamp(1.5em, 2vw, 2.25em); text-align: center; text-transform: uppercase; margin: 40px 0px 0px 0px;} 
        .whyUsContent p { font-size: clamp(0.75em, 2vw, 1em); text-align: justify; font-weight: 100 !important; font-family: sans-serif; }
        .spacingWhyUs { margin: 40px 0px 0px 0px; }
    /*#endregion */

    /*#region testimony*/
        .testimony { position: relative; overflow: hidden; }
        .bgTestimony { position: absolute; top:50%; left:50%; transform: translate(-50%,-50%) rotate(30deg); z-index: -1px; }
        .ulTestimony { position: absolute; top:50%; left:0%; transform: translate(0%,-50%); }
        .logoImg {position: absolute; top: 0; left: 20px; max-width: 400px;}
        .testimony h2 { font-size: clamp(1.5em, 2vw, 2.25em); text-transform: uppercase; font-family: serif; text-align: center; }
        .testimony h3 { font-size: clamp(1.25em, 2vw, 1.75em); font-family: serif; }
        .testimony p { text-align: justify; }
        .testimonyText h3 { text-align: center; }
    /*#endregion */

    /*#region ContactUs*/
        .contactUs { overflow: hidden; color:white;  font-family: serif; height: 100%;}
        .contactUsWrap { filter: drop-shadow(80px -0px -10px rgba(var(--secondary), 0.5));  }
        .contactUsColorBG { position: relative; box-shadow: inset 0px 0px 100px black; width: 100%; height: 800px; background-color: rgba(var(--primary)); clip-path: polygon(0 0, 100% 20%, 100% 100%, 0% 100%); }
        .contactUsBg { position: absolute; z-index: -1; top: 50%; left: 50%; transform: translate(-50%,-50%); opacity: 0.3;}
        .contactUsContent { position: absolute; top:55%; left: 50%; transform: translate(-50%,-50%); color: white; width: 100%; }
        .contactUsContent h2 { font-size: clamp(1.5em, 2vw, 2em); text-align: center; width: 100%; }
        .contactUsContent p { font-size: clamp(1em, 2vw, 1.5em); text-align: justify; width: 100%; font-family: sans-serif; font-weight: 100; line-height: normal; }
        .contactUsButton { display: block; margin: 20px auto; background-color: rgb(var(--secondary)); font-weight: 700; color: rgb(var(--primary)) !important; }
        .contactUsButton:hover { color:white !important; }
        .subtitleContactUs {text-align: center; font-family: serif; margin: 25px 20%; font-size: clamp(1.5em, 2vw, 2em); line-height: normal; text-shadow: 1px 1px 2px black;}

        .md-field { margin: 0px 10px; }
        .md-field.md-theme-default::after { background-color: rgba(var(--secondary),0.90); }
        .md-field.md-theme-default .md-helper-text, .md-field.md-theme-default .md-count, .md-field.md-theme-default label { color: rgba(255,255,255,0.90); }
        .md-field.md-theme-default.md-focused .md-input, .md-field.md-theme-default.md-focused .md-textarea, .md-field.md-theme-default.md-has-value .md-input, .md-field.md-theme-default.md-has-value .md-textarea { -webkit-text-fill-color: rgba(255,255,255,0.9); }

    /*#endregion */
    
    @media handheld, only screen and (max-width: 600px) {
        .grid-pad { padding: 0px 10px; }
        .whyUsBG { clip-path: unset; height: 100%;}
        .whyUsContent { position: relative; top:0; transform: translate(0%,0%); text-align: right; padding: 50px 10px; }
        .md-field { margin: 0px ; }
        
    }
    @media handheld, only screen and (max-width: 960px) {
        .videoAbstracxion { position: relative;}
        .arrowOfferDesktop { display: none; }
        .arrowOfferMobile {display: block;}
        .bgWhyUs { height: 120%; object-fit: cover;}
        .whyUsContent h4  {line-height: normal; margin: 20px 0px;}
        .whyUsBG { clip-path: polygon(0 5%, 100% 0, 100% 100%, 0 95%); }
        .spacingWhyUs { margin: 20px 0px; }
        .ulTestimony { position: relative; top:50%; left:0%; transform: translate(0%,-50%); }
        .logoImg {position: relative; top: 0; left: 20px; max-width: 200px;}
        .testimonyText { line-height: normal; }
        .contactUsContent { position: relative; }
        .contactUsColorBG { clip-path: polygon(0 0, 100% 5%, 100% 100%, 0% 100%); }
        .subtitleContactUs { margin: 25px 5%; }
        .contactUsBg { height: 100%; object-fit: cover;}
        .mobileBtn { display: block; }
    }
    @media handheld, only screen and (max-width: 1279px) {
        .logoImg {position: relative; top: 0; left: 20px; max-width: 200px;}
        .ulTestimony { position: relative; top:50%; left:0%; transform: translate(0%,-50%); }
        .contactUsBg { height: 150%;}
    }
    @media handheld, only screen and (max-width: 1378px) {
        .bgWhyUs { height: 200%; object-fit: cover;}
    }
    @media handheld, only screen and (max-width: 1920px) {
        .bgWhyUs { display: block;}
        .bgWhyUs { display: block;}
    }
    @media screen and (min-width: 1960px) {

        .bgWhyUs { display: none;}
        .contactUsBg { display: none;}
    }
</style>

<script>
    import { required, numeric, email } from 'vuelidate/lib/validators'
    import cHeader from '@/www/components/website-header.vue';
    import cFooter from '@/www/components/website-footer.vue';

    export default {
        name: 'Offer Starting Business',
        data: () => ({ 
            item:{
                name:'',
                phone:'',
                Message:'',
                Email:'',
            },
            error:{name: false, phone: false, Email:false},
            offerSendForm: 'notSent', // notSent, pending, Sent, error
        }),
        computed: { },
        mounted: async function () {
            let toForm = window.location.hash.includes('#form');
            if( toForm  ) { this.animateEasing();}

        },

        methods: {
            animateEasing: function(){
                let element = this.$refs.formPosition;
                element.scrollIntoView({ behavior: 'smooth' }) 
            },
            validation: async function(){
                let valid = true;
                let errorFlag = {name: false, phone: false, email:false};
                if(this.item.name == null || this.item.name.length == 0){ valid = false; errorFlag.name = true; }
                if(this.item.phone == null || this.item.phone.length < 12){valid = false; errorFlag.phone = true; }
                if(this.item.Email == null || !this.item.Email.includes('@')){valid = false; errorFlag.Email = true; }
                this.error = errorFlag;
                return valid;
            },
            login: async function () {

                let api = 'https://abstracxion.wx3data.com/security/login/WX3'; // need Tenant BissonService 
                let data = null;
                try {
                    let response = await fetch(api, {
                        method: 'PUT', // *GET, POST, PUT, DELETE, etc.
                        mode: 'cors', // no-cors, *cors, same-origin
                        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                        credentials: 'same-origin', // include, *same-origin, omit
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        redirect: 'follow', // manual, *follow, error
                        referrerPolicy: 'no-referrer', // no-referrer, *client
                        body: JSON.stringify({LoginName: "", password: ""}) // body data type must match "Content-Type" header
                    })
                    if (await response.ok) {
                        if (await response.status !== 204) {
                            let data = await response.text();
                            return data
                        }
                    } else {
                        let msg = await response.text();
                        $App.event.$emit("app_error", msg);
                    };
                        
                } catch (error) {
                    console.log(error);
                }
            },
            SendForm: async function (){
                this.offerSendForm = "pending";
                let valid = await this.validation();
                if(valid == true){
                let formData = new FormData(this.$refs.form);
                console.log(formData);
                let api = 'https://abstracxion.wx3data.com/eCommerce/Forms/ContactUs/sendByEmail';
                try {
                    let token = await this.login();

                    // ** ATTENTION need to add bearer token...
                    let response = await fetch(api, {
                        method: 'POST',
                        mode: 'cors', // no-cors, *cors, same-origin
                        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                        credentials: 'same-origin', // include, *same-origin, omit
                        headers: {
                            'Authorization': 'Bearer ' + token
                        },
                        redirect: 'follow', // manual, *follow, error
                        referrerPolicy: 'no-referrer', // no-referrer, *client
                        body: formData
                    });

                    if (await response.ok) {
                        if (await response.status !== 204) {
                            this.offerSendForm = "sent";
                        }
                    } else {
                        this.offerSendForm = "error";
                    };

                } catch (error) {

                }
                }else { this.offerSendForm = 'error'; }

            },
        },
        components: {
            "cHeader": cHeader,
            "cFooter": cFooter,
        }
    }
  </script>
  