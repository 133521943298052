<template>
    <md-content>
        <cHeader />
        <div class="default-pad">
          <p>{{ $t("Thank") }}</p>
        </div>
        <cFooter />
    </md-content>
</template>
<i18n>
{
  "fr": {
    "Thank": "Votre commande a été transmis, nous vous remercions de votre confiance.",
    "general": {
      "items": "Items",
      "name": "Nom",
      "ref": "Numéro de référence",
      "description": "Desc.",
      "phone": "Téléphone",
      "email": "Courriel",
      "price": "Prix",
      "discount": "Esc.",
      "qty": "Qté",
      "total": "Total",
      "undo": "Retirer",
      "noItems": "Aucun Item dans votre panier",
      "info": "Informations",
      "invoice": "Facture",
      "delivery": "Livraison",
      "legal": "Mention Légale",
      "legalText": "..."
    },
    "invoice": {
      "title": "Paiement",
      "slogan": "Voici l'étape finale pour terminer votre achat.",
      "paidText": "Votre facture ",
      "paidText2": " à été payée. <br>Un courriel vous a été transmis renfermant les détails complets. <br>Nous vous remercions vivement de nous avoir choisi. ",
      "info": "Information de la facture",
      "Items": "Items",
      "noItems": "Aucun Item dans votre panier",
      "summary": "Résumé",
      "choice": "Choix du paiement",
      "subtotal": "Sous-Total",
      "shipping": "Livraison",
      "GST": "TPS/TVH",
      "PST": "TVQ",
      "total": "Total",
      "treatment": "En traitement",
      "paid": "Payer",
      "resetPayment": "Recommencer le paiement"
    },
    "canceled": {
      "title": "Annulation Paiement",
      "treatment": "En traitement",
      "cancel": "La transaction PayPal a été annulée.",
      "home": "Accueil",
      "invoice": "Facture",
      "text": "Nous vous prions de nous excuser pour l'erreur survenue lors de votre annulation transaction PayPal.<br/><br/>Si le problème persiste veuillez communique avec un administrateur.",
      "tryAgain": "Réessayer"
    },
    "paid": {
      "title": "Paiement paypal",
      "treatment": "En traitement",
      "finalized": "La transaction PayPal a été finalisée. Nous vous remercions de votre paiement.",
      "problem": "Nous vous prions de nous excuser pour l'erreur survenue lors de votre transaction PayPal.<br/><br/>Si le problème persiste veuillez communique avec un administrateur.",
      "tryAgain": "Réessayer"
    }
  },
  "en": {
    "Thank": "Your order has been sent, we thank you for your trust.",
    "general": {
      "items": "Items",
      "name": "Name",
      "ref": "Reference Number",
      "description": "Desc.",
      "phone": "Phone",
      "email": "Email",
      "price": "Price",
      "discount": "Dis.",
      "qty": "Qty",
      "total": "Total",
      "undo": "Remove",
      "noItems": "No Item in your cart",
      "info": "Informations",
      "invoice": "Billing",
      "delivery": "Delivery",
      "legal": "Legal Notice",
      "legalText": "..."
    },
    "invoice": {
      "title": "Payment",
      "slogan": "Here is the final step to complete your purchase.",
      "paidText": "Your invoice ",
      "paidText2": " has been paid. <br>An email has been sent to you containing the complete details.<br>We sincerely thank you for choosing Helite Canada.",
      "info": "Invoice Information",
      "Items": "Items",
      "noItems": "No items in your cart.",
      "summary": "Summary",
      "choice": "Payment type",
      "subtotal": "Subtotal",
      "shipping": "Shipping",
      "GST": "GST/HST",
      "PST": "PST",
      "total": "Total",
      "treatment": "In processing",
      "paid": "Pay",
      "resetPayment": "Restart payment"
    },
    "canceled": {
      "title": "Cancellation Payment",
      "treatment": "In traitment",
      "cancel": "The PayPal transaction has been cancelled.",
      "home": "Home",
      "invoice": "Invoice",
      "text": "We apologize for the error that occurred during your PayPal transaction cancellation.<br/><br/>If the problem persists please contact an administrator.",
      "tryAgain": "Try again"
    },
    "paid": {
      "title": "Payment paypal",
      "treatment": "In traitment",
      "finalized": "The PayPal transaction has been finalized. Thank you for your payment.",
      "problem": "We apologize for the error that occurred during your PayPal transaction.<br/><br/>If the problem persists please contact an administrator.",
      "tryAgain": "Try again"
    }
  }
}    
</i18n>

<style src="../../../assets/styles/eCommerce-root.css"></style>
<style scoped src="../../../assets/styles/eCommerce-main.css"></style>
<style scoped src="../../../assets/styles/eCommerce-theme.css"></style>

<style scoped>
    p {
        color: rgba(0, 0, 0, 0.54);
        font-size: 20px; text-align: center; margin-top: 100px;
    }
    .default-pad {margin: 0px 0px 0px 20px !important; min-height:650px !important;}

</style>

<script>

import { navigateRouteExtension } from '@/mixins/navigateRouteExtension.js';
import { apiECommerceExtension } from '@/mixins/apiECommerceExtension';

import cHeader from './components/content-header.vue';
import cFooter from './components/content-footer.vue';

export default {
  name: 'Invoice', 
  mixins: [navigateRouteExtension, apiECommerceExtension],
  props: {
    //summary: Object,
  },
  data: () => ({
    tenant: null,
    invoice: null,
    invoiceToPaid: true,
    loading: false,
    }),
  mounted: async function () {

    this.tenant = this.$route.params.tenant;
    let token = await $App.getAnonymousToken(this.tenant);

    // if(!$App.onlineStore) { this.$router.replace('/'); }
    // else {

    //   let param = this.$route.params.id;
    //   if (!param) {
    //     param = this.GetInvoiceRef();
    //     if (param) { param = param.replace(/"/g, ''); } else { this.$router.push('/Home'); }
    //   }
    //   await this.readData(param);

    //   if(this.invoice) { 
    //     if (this.invoice.balance == 0 && this.invoice.paidOn) {
    //       await this.ClearInvoice();
    //       let paramReload = this.GetInvoiceRef();
    //       paramReload = this.$route.params.id;
    //       if (!paramReload) { this.$router.push('/Home'); }
    //     }
    //   }
    //   else{ this.$router.push('/Home'); }

    // }

  },
  methods:{
    readData: async function(param){

      let data = await this.GetInvoice(param);
      if(data) {
        if(data.paymentTypeId != 0){
          this.loading = true;
        }
      }
      this.invoice = data;

    },
    payInvoice: async function(){
      this.loading = true;
      let urlToPay = '';

      if (this.invoice.uId) { urlToPay = await this.PaypalInit(this.invoice.uId); }
      if (urlToPay != "") {
        window.location.href = urlToPay;
      }

    },
    cancelInvoice: async function(){
      if(this.invoice && this.invoice.uId ) { 
        if(this.invoice.paymentTypeId == 7){
          this.$router.push('/invoices/'+ this.invoice.uId + '/paypal/canceled');
        } else { window.location.reload(); }
      }

    }
  },
  
  components: {
    "cHeader": cHeader,
    "cFooter": cFooter,
  },
}

</script>