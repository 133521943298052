<template>
    <md-content>
        <div v-if="ready">
            <div class="summaryBG summary">
                <div class="default-pad-order">
                    <div class="md-layout "> 
                        <div class="md-layout-item">
                            <div class="md-layout md-layout-item md-size-100">
                                <h2 class="mobileText">{{ $t("summary.title") }}</h2>
                            </div>
                            <div v-if="Step != 'order'" class="md-layout md-layout-item md-size-100" >
                                <p class="backText" @click="onBack()">{{ $t("link.back") }}</p>
                            </div>
                            <div v-if="current" class="md-layout md-size-100" style="margin-top: 30px;">
                                <div class="md-layout-item md-layout md-size-100">
                                    <div class="md-layout-item md-layout md-size-66" >
                                        <h4 class="md-layout-item priceTitle">{{ $t("summary.subtotal") }}</h4>
                                    </div>
                                    <div class="md-layout-item ">
                                        <h4 v-if="!dirty" class="price">{{ current.subTotal | $ }}$</h4>
                                        <h4 v-else>----</h4>
                                    </div>
                                </div>
                                <div class=" md-layout-item md-layout md-size-100">
                                    <div class="md-layout-item md-size-66" >
                                        <h4 class="priceTitle">{{ $t("summary.delivery") }}</h4>
                                    </div>
                                    <div class="md-layout-item ">
                                        <h4 v-if="!dirty" class="price">{{ current.shipping | $}}$</h4>
                                        <h4 v-else>----</h4>
                                    </div>
                                </div>
                                <div class="md-layout-item md-layout  md-size-100">
                                    <div class="md-layout-item md-size-66" >
                                        <h4 class="priceTitle">{{ $t("summary.gst") }}</h4>
                                    </div>
                                    <div class="md-layout-item ">
                                        <h4 v-if="!dirty" class="price">{{ current.gst | $ }}$</h4>
                                        <h4 v-else>----</h4>
                                    </div>
                                </div>
                                <div class="md-layout-item md-layout md-size-100">
                                    <div class="md-layout-item md-size-66" >
                                        <h4 class="priceTitle">{{ $t("summary.pst") }}</h4>
                                    </div>
                                    <div  class="md-layout-item ">
                                        <h4 v-if="!dirty" class="price">{{ current.pst | $ }}$</h4>
                                        <h4 v-else>----</h4>
                                    </div>
                                </div>
                                <hr class="hrOrder">
                                <div class="md-layout md-layout-item md-size-100 pt-10">
                                    <div class="md-layout-item md-size-65" >
                                        <h4 class="priceTitle">{{ $t("summary.total") }}</h4>
                                    </div>
                                    <div class="md-layout-item ">
                                        <h4 v-if="!dirty" class="price">{{ current.total }}$</h4>
                                        <h4 v-else>----</h4>
                                    </div>
                                </div>
                                <div class="md-layout md-layout-item md-size-100 pt-10">
                                    <md-button v-if="current && current.items.length == 0 && dirty == false" class="orderBtn">
                                        <div @click="onShopContinue">        
                                            <h2 class="orderBtnTitle">{{ $t("btn.shop") }}</h2>
                                        </div>
                                    </md-button>
                                    <md-button v-if="current && dirty == true"  class="mt-5 orderBtn">
                                        <div @click="reEvaluate">   
                                            <h2 class="orderBtnTitle">{{ $t("btn.refresh") }}</h2>
                                        </div>
                                    </md-button>
                                    <md-button v-if="current && Step != 'paiement' && current.items.length > 0 && dirty == false" :class="{'gray': loading == true }" class="mt-5 orderBtn" :readonly="loading == true">
                                        <div @click="NextStep">
                                            <h2 :class="{'gray': loading == true }" v-if="loading == true" class="orderBtnTitle">{{ $t("btn.processing") }}</h2>
                                            <h2 v-if="loading == false" class="orderBtnTitle">{{ $t("btn.continue") }}</h2>                    
                                        </div>
                                    </md-button>
                                    <md-button v-if="current && Step == 'paiement' && current.items.length > 0 && dirty == false" :class="{'gray': loading == true }" class="mt-5 orderBtn" :readonly="loading == true">
                                        <div @click="CompletedStep">
                                            <h2 :class="{'gray': loading == true }" v-if="loading == true" class="orderBtnTitle">{{ $t("btn.processing") }}</h2>
                                            <h2 v-if="loading == false" class="orderBtnTitle">{{ $t("btn.pay") }}</h2>
                                        </div>               
                                    </md-button>
                                </div>
                                <div class="md-layout md-layout-item md-size-100 md-alignment-center-center">
                                    <div class="md-layout md-layout-item md-size-100 md-alignment-center-center">
                                        <p class="storeText" @click="onShopContinue">{{ $t("link.continueShopping") }}</p>
                                    </div>
                                    <div class="md-layout md-layout-item md-size-100 md-alignment-center-center pt-5" v-if="loading == true">
                                        <md-progress-bar md-mode="indeterminate"></md-progress-bar>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--Mobile version-->
        <div v-if="ready" class="mobile">
            <div class="default-pad-order ">
                <div class="md-layout"> 
                    <div class="md-layout-item">
                            <div class="md-layout md-layout-item md-size-100">
                                <h2 class="mobileText">{{ $t("summary.title") }}</h2>
                            </div>
                            <div v-if="Step != 'order'" class="md-layout md-layout-item md-size-100" >
                                <p class="backText" @click="onBack()">Retour</p>
                            </div>
                            <div v-if="current" class="md-layout md-layout-item md-size-100" style="margin-top: 30px;">
                                <div class="md-layout md-layout-item md-size-100">
                                    <div class="md-layout-item md-size-66" >
                                        <h4 class="priceTitle">Sous-Total</h4>
                                    </div>
                                    <div class="md-layout-item ">
                                        <h4 v-if="!dirty" class="price">{{ current.subTotal }}$</h4>
                                        <h4 v-else>----</h4>
                                    </div>
                                </div>
                                <div class="md-layout md-layout-item md-size-100">
                                    <div class="md-layout-item md-size-66" >
                                        <h4 class="priceTitle">Livraison</h4>
                                    </div>
                                    <div class="md-layout-item ">
                                        <h4 v-if="!dirty" class="price">{{ current.shipping}}$</h4>
                                        <h4 v-else>----</h4>
                                    </div>
                                </div>
                                <div class="md-layout md-layout-item md-size-100">
                                    <div class="md-layout-item md-size-66" >
                                        <h4 class="priceTitle">TVQ</h4>
                                    </div>
                                    <div class="md-layout-item ">
                                        <h4 v-if="!dirty" class="price">{{ current.gst }}$</h4>
                                        <h4 v-else>----</h4>
                                    </div>
                                </div>
                                <div class="md-layout md-layout-item md-size-100">
                                    <div class="md-layout-item md-size-66" >
                                        <h4 class="priceTitle">TPS</h4>
                                    </div>
                                    <div class="md-layout-item ">
                                        <h4 v-if="!dirty" class="price">{{ current.pst }}$</h4>
                                        <h4 v-else>----</h4>
                                    </div>
                                </div>
                                <hr class="hrOrder">
                                <div class="md-layout md-layout-item md-size-100 pt-10">
                                    <div class="md-layout-item md-size-66" >
                                        <h4 class="priceTitle">Total</h4>
                                    </div>
                                    <div class="md-layout-item ">
                                        <h4 v-if="!dirty" class="price">{{ current.total }}$</h4>
                                        <h4 v-else>----</h4>
                                    </div>
                                </div>
                                <div class="md-layout md-layout-item md-size-100 pt-10">
                                    <md-button v-if="current && current.items.length == 0 && dirty == false" class="orderBtn">
                                        <div @click="onShopContinue">        
                                            <h2 class="orderBtnTitle">Boutique</h2>
                                        </div>
                                    </md-button>
                                    <md-button v-if="current && dirty == true"  class="mt-5 orderBtn">
                                        <div @click="reEvaluate">   
                                            <h2 class="orderBtnTitle">Rafraichir</h2>
                                        </div>
                                    </md-button>
                                    <md-button v-if="current && Step != 'paiement' && current.items.length > 0 && dirty == false" :class="{'gray': loading == true }" class="mt-5 orderBtn" :readonly="loading == true">
                                        <div @click="NextStep">
                                            <h2 :class="{'gray': loading == true }" v-if="loading == true" class="orderBtnTitle">En traitement</h2>
                                            <h2 v-if="loading == false" class="orderBtnTitle">Continuer</h2>                    
                                        </div>
                                    </md-button>
                                    <md-button v-if="current && Step == 'paiement' && current.items.length > 0 && dirty == false" :class="{'gray': loading == true }" class="mt-5 orderBtn" :readonly="loading == true">
                                        <div @click="CompletedStep">
                                            <h2 :class="{'gray': loading == true }" v-if="loading == true" class="orderBtnTitle">En traitement</h2>
                                            <h2 v-if="loading == false" class="orderBtnTitle">Commander</h2>
                                        </div>               
                                    </md-button>
                                </div>
                                <div class="md-layout md-layout-item md-size-100 md-alignment-center">
                                    <div class="md-layout md-layout-item md-size-100 md-alignment-center-center">
                                        <p class="storeText" @click="onShopContinue">Continuer son magasinage</p>
                                    </div>
                                    <div class="md-layout md-layout-item md-size-100 md-alignment-center-center pt-5" v-if="loading == true">
                                        <md-progress-bar md-mode="indeterminate"></md-progress-bar>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </md-content>
    
</template>
<i18n>
{
    "fr": {
      "summary": {
        "title": "Résumé",
        "subtotal": "Sous-total",
        "delivery": "Livraison",
        "deliveryDate": "La date minimale pour la livraison est",
        "gst": "TPS/TVH",
        "pst": "TVQ",
        "total": "Total"

      },
      "btn": {
        "shop":"Boutique",
        "refresh": "Rafraîchir",
        "processing": "En traitement",
        "continue": "Continuer",
        "pay": "Commander",
        "validation": "validation"
      },
      "link": {
        "continueShopping":"Continuer son magasinage",
        "back":"Retour"
      }


    },
    "en": {
      "summary": {
        "title": "Summary",
        "subtotal": "Subtotal",
        "delivery": "Delivery",
        "deliveryDate": "The minimum delivery date is",
        "gst": "GST/HST",
        "pst": "PST",
        "total": "Total"
      },
      "btn": {
        "shop": "Shop",
        "refresh": "Refresh",
        "processing": "Processing",
        "continue": "Continue",
        "pay": "Command",
        "validation": "validation"
      },
      "link": {
        "continueShopping": "Continue Shopping",
        "back": "Back"
      }
    

    }
}    
</i18n>
<i18n>
  {
    "en": {
      "title": "Shopping cart"
    },
    "fr": {
      "title": "Panier d'achat"
    }
  }
</i18n>

<style src="../../../../assets/styles/eCommerce-root.css"></style>
<style scoped src="../../../../assets/styles/eCommerce-main.css"></style>
<style scoped src="../../../../assets/styles/eCommerce-theme.css"></style>
<style scoped>
    h4 {font-size:16px; font-style: normal; padding: 0 !important; margin: 0 !important;}
    .md-progress-bar {width:400px !important; height:10px;}
    .default-pad-order {padding: 0px 15px 0px 15px;}
    .hrOrder {color: rgb(var(--tierce)); width:75%; display: block; margin: 30px auto 30px auto;}
    .mobile{display: none;}
    .summary {display: block;}
    .summaryBG { position:fixed; top: 200px; height: auto; width:24%; padding: 20px 0px 20px 0px !important;  background-color: rgba(var(--primary),0.8) !important; z-index: 100;}
    .priceTitle {text-transform: uppercase;  color: rgb(var(--tierce)); text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3); height: 40px;}
    .price {text-align: right; color: rgb(var(--secondary)); text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2); font-weight: 700;}
    .mobileText {color:rgb(var(--tierce)); margin: 0px 0px 20px 0px;}
    .orderBtn{display: block; margin: 10px auto 10px auto !important; width: auto; background-color: transparent; border: 1px solid rgb(var(--tierce)); border-radius: 4px; box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.1);}
    .orderBtn:hover {background-color: rgb(var(--primary3));}
    .orderBtnTitle {font-weight: lighter; color: rgb(var(--tierce)); padding: 7px 20px 7px 20px; text-transform: uppercase;}
    .gray {color: dimgray; background-color: gray; border-color: black;}
    .gray:hover {background-color: gray !important; }
    .backText {color: rgb(var(--secondary)); font-weight: 700; font-size: 18px; text-decoration: underline; cursor: pointer;}
    .backText:hover {text-decoration: none;}
    .storeText {color: rgb(var(--secondary)); text-align: center; text-decoration: underline; cursor: pointer; margin-top: 5px !important;  }
    .storeText:hover {text-decoration: none;}
    .h2Mobile{font-family: Ephesis; font-size: 36px; color: rgb(var(--secondary));}
    .detailedText { font-size: 11px; margin-top: -10px !important; text-align: center; text-transform: uppercase;  color: rgb(var(--tierce)); text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);}
    .storeText {color: rgb(var(--secondary)); font-size:16px;}
    .storeText:hover {color: rgb(var(--tierce));}
    @media handheld, only screen and (max-width: 600px) {}
    @media handheld, only screen and (max-width: 958px) {
        .default-pad-order {padding: 0px;}
        .default-pad {padding: 0px;}
        .summary {display: none !important;}
        .mobile{display: block; background-color: #FAFAFA; overflow-x: hidden; padding: 10px;}
        .backText {color: rgb(var(--primary));}
        .priceTitle {color: rgb(var(--primary));}
        .orderBtn {border: 1px solid rgb(var(--primary)); background-color: rgba(var(--primary),0.2);}
        .orderBtnTitle {color: rgb(var(--primary)); }
        .orderBtnTitle:hover {color: rgb(var(--tierce));}
        .mobileText {color:rgb(var(--primary3));  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); padding-top:20px; margin: 0px;}
        .storeText {color: rgb(var(--primary));}
        .storeText:hover {color: rgb(var(--secondary));}
        .detailedText {color: rgb(var(--primary)); text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);}
    }
    @media handheld, only screen and (max-width: 1264px) {

    }

</style>

<script>
    import { navigateRouteExtension } from '@/mixins/navigateRouteExtension.js';
    import { apiECommerceExtension } from '@/mixins/apiECommerceExtension.js';

  export default {   
    name:'EcommerceSummary',
    mixins: [navigateRouteExtension, apiECommerceExtension],
    props: {
        item: Object,
        step: String,
        dirty: Boolean,
    },
    data: () => ({
        current: {items: {}},
        Step: null,
        order: null,
        loading: false,
        shippingRestrictiondate: '',
        
    }),
    computed:{
        ready: async function () {
            let show = false;
            if(this.item) {
                this.current = this.item;
                show = true;
            }
            if(this.step){this.Step = this.step;}
            this.shippingDateValidation();  
            return show;
        },
        tenant: function() {
            return this.$route.params.tenant;
        }

    },
    methods:{
        mounted: async function () {
            //console.log(this.tenant);

            //let token = await $App.getAnonymousToken(this.tenant);
        },
        NextStep: async function(){      
            this.loading = true;
            let r = await this.UpdateOrder(this.item);
            this.loading = false;
            if(r) {
                this.$emit("update:item", r);
                this.$emit("eCommerceNext", r);
            }

            // if(this.current.items.length == 0){ this.loading = false; this.onShopContinue();}
            // else{ this.loading = false; this.$emit("update:item", orderUpdated); this.$emit("eCommerceNext", orderUpdated);}

        },
        CompletedStep: async function(){  
            this.loading = true;
            let r = await this.CompleteOrder(this.item);
            this.loading = false;
            this.$emit("eCommerceCompleted", r);
        },
        reEvaluate: async function(){
            this.loading = true;
            let r = await this.UpdateOrder(this.item);
            this.loading = false;
            if(r) {
                this.current = r;
                this.$emit("update:item", r);
                this.$emit("update:dirty", false);
            } else {alert('error');}
            
        },
        onShopContinue: function () {
            //console.log(this.tenant);
            this.navigateTo({ 'name': 'ecommerceProductList', 'params': {'tenant': this.tenant } });
        },
        onBack: function(){
            this.$emit("eCommerceBack");
        },
        shippingDateValidation: function(){
            let validation = this.current.validations;
            if(validation){
                validation.forEach(e => {
                    if(e.severity == 1){if(e.field == "DeliveryOn") { if(e.code == "Valid"){ this.shippingRestrictiondate = e.message;}}}
                    if(e.severity == 3){if(e.field == "DeliveryOn") { if(e.code == "Underflow"){ this.shippingRestrictiondate = 'dans le passé.'; }}}
                });
            }
        }

    },
    component: {

    }
  }
</script>