<template>
    <div v-if="item" class="md-content">
        <cHeader v-bind:item.sync="item" />
    
        <!--section2 Img / text top-->
        <section class="one-Policy">

            <div class="title">
                <span class="md-display-1">Politique de confidentialité</span>
            </div>
            <div>
                <p class="md-body-2">{{item.name}} est soucieux de la protection de votre vie privée et des renseignements personnels
                    qu'elle
                    recueille sur son site Web ou tout autre moyen technologique, utilise ou communique à des tiers à
                    l'occasion de ses activités.</p>
                <p class="md-body-2">La présente Politique de confidentialité fait état de la manière dont {{item.name}} protège les
                    renseignements personnels qu'elle détient, et ce, afin de tenir compte des exigences des lois
                    applicables en matière de protection des renseignements personnels auxquelles elle est assujettie,
                    mais
                    aussi de toutes autres lois et règlements applicables en ce domaine.</p>
                <p class="md-body-2">La présente Politique ne s'applique pas aux renseignements qui ont un caractère public en vertu de
                    ces
                    législations.</p>
            </div>
            <div>
                <span class="md-title">Responsabilité</span>
                <p class="md-body-2">{{item.name}} est responsable de la protection des renseignements personnels qu'elle détient ou qu'elle
                    confie, le cas échéant, à un tiers.</p>
                <p class="md-body-2">{{item.name}} s'assure que ses employés, mais aussi les tiers avec qui elle fait affaire protègent la
                    confidentialité des renseignements personnels auxquels ils ont accès, et ce, quel que soit la nature
                    de
                    leur support ou quelle que soit la forme sous laquelle ils sont accessibles. </p>
            </div>
            <div> <!-- class="txt-c"-->
                <span class="md-title">Renseignements personnels</span>
                <p class="md-body-2">{{item.name}} ne collecte aucun renseignements personnels</p>
            </div>
            <div class=" protection-personal-information md-layout">
                <div class="md-layout-item md-small-size-100 txt-c">
                    <span class="md-title" >Responsable de la protection <br />des renseignements personnels</span>
                    </div>
                <div class="md-layout-item md-small-size-100">
                    <div v-if="item">
                        <span v-if="item.address && item.address.street" class="md-body-2">{{item.address.street}}</span><br />
                        <span v-if="item.address && item.address.city && item.address.city.name " class="md-body-2">{{item.address.city.name}}</span><br />
                        <span v-if="item.address && item.address.city && item.address.city.state && item.address.city.state.name " class="md-body-2">{{item.address.city.state.name}}</span>, &nbsp;
                        <span v-if="item.address && item.address.city && item.address.city.state && item.address.city.state.country && item.address.city.state.country.name  " class="md-body-2">{{item.address.city.state.country.name}}</span><br />
                        <span v-if="item.address && item.address.zipCode" class="md-body-2">{{item.address.zipCode}}</span><br /><br />
                        <span v-if="item && item.phone" class="md-body-2">Tel: {{ item.phone | phone }}</span>
                    </div>
                </div>
            </div>
            <div>
                <p class="md-body-2">Le responsable s'engage à garantir la protection des données personnelles collectées, à ne pas les
                    divulguer à des tiers sans votre consentement préalable, et à respecter les objectifs pour lesquels
                    elles ont été collectées. De plus, il vous informera dans les plus brefs délais en cas de
                    compromission
                    de la confidentialité de vos données personnelles.</p>
            </div>
            <div>
                <span class="md-title">Durée de conservation</span>
                <p class="md-body-2">Nous conservons vos renseignements personnels pour une durée de sept années suivant leur dernière
                    utilisation sauf lorsque la loi prévoit une durée de conservation différente </p>
            </div>
            <div>
                <span class="md-title">Mesures de sécurité</span>
                <p class="md-body-2">Nous mettons en place des mesures de sécurité appropriées, incluant des mesures physiques, techniques
                    et
                    administratives, pour garantir la protection de vos renseignements personnels. Ces mesures visent à
                    prévenir tout accès non autorisé, utilisation, divulgation à des tiers, ainsi que toute perte ou
                    atteinte à la sécurité de ces renseignements.</p>
            </div>
            <div>
                <div class="title">
                    <span class="md-display-1">Utilisation de témoins (cookies)</span>
                </div>
                

                <p class="md-body-2">Des témoins, également connus sous le nom de cookies, peuvent être utilisés pour simplifier la
                    navigation
                    sur les pages de ce site. Il est important de noter que ces témoins peut collecter de données
                    personnelles vous concernant. Vous avez la possibilité, à tout moment, de configurer votre
                    navigateur
                    pour qu'il bloque l'utilisation de ces fichiers témoins. Cependant, veuillez noter que cette action
                    pourrait vous priver de certaines des fonctionnalités offertes sur le site.</p>
                <p class="md-body-2">En outre, Google Analytics peut recourir à des fichiers témoins pour recueillir des informations sur
                    votre navigation dans le but d'améliorer votre expérience à des fins statistiques. Ces données sont
                    généralement stockées aux États-Unis, et Google peut les partager avec des tiers en cas d'obligation
                    légale ou lors de leur traitement en son nom. Ces informations sont également susceptibles d'être
                    partagées sur les comptes Google Ads et Google Search Console de la compagnie. Parmi les
                    informations
                    recueillies, on trouve notamment :</p>
                <ul>
                    <li>
                        <p class="md-body-2">L'adresse IP de votre ordinateur, qui est anonymisée pour empêcher toute identification
                            directe.
                        </p>
                    </li>
                    <li>
                        <p class="md-body-2">Le système d'exploitation de votre appareil.</p>
                    </li>
                    <li>
                        <p class="md-body-2">Les pages que vous consultez, incluant la date, l'heure, la durée et la fréquence de vos
                            visites.
                        </p>
                    </li>
                </ul>
            </div>
            <div>
                <span class="md-title">Nous contacter</span>
                <p class="md-body-2">Pour toute question concernant notre Politique de confidentialité, pour partager vos commentaires,
                    exercer vos droits, ou déposer une plainte, veuillez nous contacter au numéro suivant : {{item.phone | phone}}.</p>
            </div>
            <div>
                <span class="md-title">Mise à jour de la politique</span>
                <p class="md-body-2">Les informations de cette politique ont été révisées et actualisées le 20 mars 2024 pour maintenir leur pertinence et leur exactitude. Si vous avez des questions, des préoccupations ou des commentaires relatifs à notre Politique de confidentialité, veuillez contacter notre Responsable de la Protection
                    des Données Personnelles. Vous pouvez le joindre en utilisant les coordonnées fournies dans la politique ou en utilisant les canaux de communication spécifiés à cet effet. Votre satisfaction et la protection de vos données personnelles sont au cœur de nos préoccupations.
                </p>
            </div>
            <br /><br />

    
        </section>

        <cFooter v-bind:item.sync="item" @navigateToCookie="navigateToCookie" @navigateToPolicy="navigateToPolicy" />
    </div>
</template>
<style scoped>

    .one-Policy { margin: 3% 8% 0 8%; }
    .one-Policy .md-body-2 {
        color: var(--md-theme-default-text-accent-on-background-variant, rgba(0,0,0,0.6));
    }
    .one-Policy .title {
        margin: 80px 0 auto; text-align: center; text-transform: uppercase
    }
    .one-Policy .protection-personal-information {
        margin-top: 5%;margin-bottom: 3%;
    }

</style>
<script>
  import { apiSystemSettingsExtension } from '@/mixins/apiSystemSettingsExtension.js';
  
  import cHeader from '@/www/components/onePager-header.vue'
  import cFooter from '@/www/components/onePager-footer.vue'

  export default {
      name: 'onepagerpolicy',
      mixins: [apiSystemSettingsExtension],
      data: () => ({
          name:"OnePagerPolicy",
          item: null,
      }),
      computed: { },
      mounted: async function () {

          await this.dataRead();

          if(this.item && !this.item.onePager) { window.location = "./"; }

      },
      methods: {
          dataRead: async function () {

              let tenant = this.$route.params.tenant; 

              let data = await this.SystemSettingsAnonymousGetTenant(tenant);
              if ( data ) {
                this.item = data;
              }
          },
          navigateToPolicy: function () {
          },
          navigateToCookie: function () {

          }

      },
      components: {
        "cHeader": cHeader,
        "cFooter": cFooter,
      }
  }

</script>