<template>
    <div v-if="isReady">

        <div v-if="!isEdit" class="md-layout">
            <label v-if="val">{{val}}</label>
            <label v-else>{{emptyValue}}</label>
            <md-button @click="onEdit" class="md-icon-button md-dense md-mt--2 ">
                <md-icon>edit</md-icon>
            </md-button>
        </div>
        <md-field v-else>
            <md-textarea v-model="val" type="textarea" :readonly="isReadOnly" :placeholder="emptyValue" />
        </md-field>
    </div>
</template>
<style scoped>
    .md-textarea {
        min-height: 50px !important;
    }
</style>
<script>
    export default {
        name: 'field-textarea-c',
        mixins: [],
        props: {
            title: String,
            item: Object,
            property: String,
            readonly: Boolean
        },

        data: () => ({
            val: null,
            isEdit: false,
        }),
        watch: {
            val: {
                handler(e) {
                    this.item[this.property] = e;
                }
            }
        },
        computed: {
            isReady: async function () {
                let ready = false;
                if (this.item) {
                    if (this.item[this.property]) {
                        this.val = this.item[this.property];
                    } else {
                        this.val = null;
                    }
                    ready = true;
                }
                return ready;
            },
            emptyValue: function () {
                if (this.property) {
                    return '[' + this.title + ']';
                } else { return null };
            },
            isReadOnly: function () {
                return this.readonly || !this.isEdit;
            }
        },
        mounted: async function () {
        },
        methods: {
            onEdit: function () {
                this.isEdit = true;
            },
        }
    }
</script>