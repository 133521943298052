<template>
    <!--Legal mention-->
    <div class="md-content">
        <h1 class="md-mt-2">{{ $t("general.legal") }}</h1>
        <div class="md-layout-item md-layout md-size-100 t-l md-p-4">
            <span v-html="$options.filters.textWithBR($t('general.legalText'))"></span>
        </div>
    </div>
</template>

<style src="../../../../assets/styles/eCommerce-root.css"></style>
<style scoped src="../../../../assets/styles/eCommerce-main.css"></style>
<style scoped src="../../../../assets/styles/eCommerce-theme.css"></style>
<style scoped>
</style>

<i18n>
{
    "fr": {
        "general": {
            "legal":"Mention Légale",
            "legalText":"<br />Tous les produits proposés sur ce site sont vendus sans garantie. En passant commande, le client reconnaît et accepte que :<br /><br />Vente Finale: Tous les articles achetés sur notre site sont considérés comme des ventes finales. Aucun retour ou échange ne sera accepté, sauf en cas de produit défectueux ou non conforme à la description.<br /><br />Produits défectueux : Si un produit est reçu endommagé ou ne correspond pas à la commande, le client doit nous contacter dans les 3 jours suivant la réception pour signaler le problème. Des preuves, telles que des photos du produit, peuvent être requises.<br /><br />Exclusions : Les produits personnalisés, périssables ou ouverts ne sont pas éligibles pour un retour, sauf en cas de défaut de fabrication.<br /><br />Service Client : Pour toute question ou réclamation, le client peut contacter notre service client.  <br /><br />En validant sa commande, le client confirme avoir lu et compris ces conditions."
          }
    },
    "en": {
        "general": {
            "legal":"Legal Notice",
            "legalText":"<br />All products offered on this site are sold without warranty. By placing an order, the customer acknowledges and accepts that:<br /><br />Final Sale: All items purchased on our site are considered final sales. No returns or exchanges will be accepted, except in the case of defective products or those not conforming to the description.<br /><br />Defective Products: If a product is received damaged or does not match the order, the customer must contact us within 3 days of receipt to report the issue. Evidence, such as photos of the product, may be required.<br /><br />Exclusions: Customized, perishable, or opened products are not eligible for return, except in the case of manufacturing defects.<br /><br />Customer Service: For any questions or complaints, the customer may contact our customer service.<br /><br />By confirming their order, the customer acknowledges having read and understood these terms."
          }
     }
}    
</i18n>

<script>
    export default {
        name: 'legal-notice',
        mixins: [],
        data: () => ({
        }),
        computed:{
        },
        mounted: async function () {

        },
        methods: {
        },        
        component: {
        }
    }
</script>