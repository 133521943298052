export const apiSystemSettingsExtension = {
    created: function () {
    },
    methods: {
        SystemSettingsGet: async function () {
            try {

                let data = null;
                let api = $App.url.api + 'SystemSetting';

                // Show progress bar
                $App.event.$emit("app_running", true)

                try {
                    let token = $App.getToken();

                    // ** ATTENTION need to add bearer token...
                    let response = await fetch(api, {
                        method: 'GET',
                        headers: {
                            'Authorization': 'Bearer ' + token
                        },
                    });

                    if (await response.ok) {
                        if (await response.status !== 204) {
                            data = await response.json();
                        }
                    } else {
                        let msg = await response.text();
                        $App.event.$emit("app_error", msg);
                    };

                } catch (error) {
                    $App.event.$emit("app_error", error);
                }

                // Hide progress bar
                $App.event.$emit("app_running", false)
                return data;

            } catch (error) {
                if ($App && $App.event) { $App.event.$emit("app_error", error); }
                else { console.log(error); }
                return null;
            }
        },
        SystemSettingsAnonymousGetTenant: async function (tenant) {
            try {

                let data = null;
                let api = $App.url.api + 'SystemSetting/Tenant';
                if(!tenant && $App.tenant){ tenant = $App.tenant; }

                // Show progress bar
                $App.event.$emit("app_running", true)

                try {
                    let token = await $App.getAnonymousToken(tenant);

                    // ** ATTENTION need to add bearer token...
                    let response = await fetch(api, {
                        method: 'GET',
                        headers: {
                            'Authorization': 'Bearer ' + token
                        },
                    });

                    if (await response.ok) {
                        if (await response.status !== 204) {
                            data = await response.json();
                        }
                    } else {
                        let msg = await response.text();
                        $App.event.$emit("app_error", msg);
                    };

                } catch (error) {
                    $App.event.$emit("app_error", error);
                }

                // Hide progress bar
                $App.event.$emit("app_running", false)
                return data;

            } catch (error) {
                if ($App && $App.event) { $App.event.$emit("app_error", error); }
                else { console.log(error); }
                return null;
            }
        },
        SystemSettingsSave: async function(input, callbackfct) {

            try {

                let data = null;
                let api = $App.url.api + 'SystemSettings';
                console.log(api);

                // Show progress bar
                $App.event.$emit("app_running", true)

                try {
                    let token = $App.getToken();

                    // ** ATTENTION need to add bearer token...
                    let response = await fetch(api, {
                        method: 'PUT',
                        mode: 'cors', // no-cors, *cors, same-origin
                        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                        credentials: 'same-origin', // include, *same-origin, omit
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token,
                        },
                        redirect: 'follow', // manual, *follow, error
                        referrerPolicy: 'no-referrer', // no-referrer, *client
                        body: JSON.stringify(input)
                    });

                    if (await response.ok) {
                        if (await response.status !== 204) {
                            
                            data = await response.json();
                            if(callbackfct) { callbackfct(response); }
                        }
                    } else {
                        let msg = await response.text();
                        $App.event.$emit("app_error", msg);
                    };

                } catch (error) {
                    $App.event.$emit("app_error", error);
                }

                // Hide progress bar
                $App.event.$emit("app_running", false);

                return data;

            } catch (error) {
                if ($App && $App.event) { $App.event.$emit("app_error", error); }
                else { console.log(error); }
                return null;
            }

        },

    }
}